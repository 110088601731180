import { take, race, all, call, put, throttle } from 'redux-saga/effects';
import { CANCEL_TASKS } from '../common/types';
import * as workplaceApi from '../../api/modules/workplace';
import * as employeeApi from './../../api/modules/employee';
import generalErrorHandling from '../../utilities/general-error-handling';
import { toast } from 'react-toastify';
import {
  setUserWorkplaceRotationTable,
  setAdminWorkplaceRotationTable,
  setEmployeeWorkplaceTable,
} from './actions';
import {
  GET_USER_WORKPLACE_ROTATION_TABLE,
  GET_ADMIN_WORKPLACE_ROTATION_TABLE,
  CREATE_USER_WORKPLACE_ROTATION_CELL,
  UPDATE_USER_WORKPLACE_ROTATION_CELL,
  DELETE_USER_WORKPLACE_ROTATION_CELL,
  GET_EMPLOYEE_WORKPLACES_TABLE,
  MARK_EMPLOYEE_WORKPLACE,
} from './types';

function* getUserWorkplaceRotationTableRequestGenerator({ payload }: any) {
  try {
    const result = yield call(workplaceApi.getUserWorkRotationCell, payload);
    yield put(setUserWorkplaceRotationTable(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}
function* getAdminWorkplaceRotationTableRequestGenerator({ payload }: any) {
  try {
    const result = yield call(workplaceApi.getAdminWorkRotationCell, payload);
    yield put(setAdminWorkplaceRotationTable(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}
function* createUserWorkplaceRotationTableRequestGenerator({ payload }: any) {
  try {
    const result = yield call(workplaceApi.createUserWorkRotationCell, payload);
    yield put(setUserWorkplaceRotationTable(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}
function* updateUserWorkplaceRotationTableRequestGenerator({ payload }: any) {
  try {
    const result = yield call(workplaceApi.updateUserWorkRotationCell, payload);
    yield put(setUserWorkplaceRotationTable(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}
function* deleteUserWorkplaceRotationTableRequestGenerator({
  payload,
  meta,
}: any) {
  try {
    yield call(workplaceApi.deleteUserWorkRotationCell, payload);
    yield call(meta);
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getEmployeeWorkplaceTableGenerator({ payload }: any) {
  try {
    const result = yield call(employeeApi.getEmployeeWorkplacesTable, payload);
    yield put(setEmployeeWorkplaceTable(result));
  } catch (err) {
    yield call(toast.error, err.title);
  }
}
function* markEmployeeWorkplaceTableGenerator({ payload }: any) {
  try {
    const result = yield call(employeeApi.markEmployeeWorkplace, payload);
    yield put(setEmployeeWorkplaceTable(result));
  } catch (err) {
    yield call(toast.error, err.title);
  }
}

export function* planningRootSagas() {
  return yield race([
    take(CANCEL_TASKS),
    all([
      throttle(
        100,
        GET_USER_WORKPLACE_ROTATION_TABLE,
        getUserWorkplaceRotationTableRequestGenerator
      ),
      throttle(
        100,
        GET_ADMIN_WORKPLACE_ROTATION_TABLE,
        getAdminWorkplaceRotationTableRequestGenerator
      ),
      throttle(
        100,
        CREATE_USER_WORKPLACE_ROTATION_CELL,
        createUserWorkplaceRotationTableRequestGenerator
      ),
      throttle(
        100,
        UPDATE_USER_WORKPLACE_ROTATION_CELL,
        updateUserWorkplaceRotationTableRequestGenerator
      ),
      throttle(
        100,
        DELETE_USER_WORKPLACE_ROTATION_CELL,
        deleteUserWorkplaceRotationTableRequestGenerator
      ),
      throttle(
        100,
        GET_EMPLOYEE_WORKPLACES_TABLE,
        getEmployeeWorkplaceTableGenerator
      ),
      throttle(
        100,
        MARK_EMPLOYEE_WORKPLACE,
        markEmployeeWorkplaceTableGenerator
      ),
    ]),
  ]);
}
