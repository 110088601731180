import api from '../index';

const SKILLS_BASE_URL = 'api/v1/register/skill';

export async function reorderSkills(payload: ReorderSkillsRequest) {
  const result = await api.put(
    `${SKILLS_BASE_URL}/Position/${payload.id}`,
    payload
  );
  return result.data;
}

export async function getSkills(): Promise<SkillResponse> {
  const result = await api.get<SkillResponse>(SKILLS_BASE_URL);
  return result.data;
}

export async function toggleSkillSelection(id: number, selected: boolean) {
  const result = await api.put(`${SKILLS_BASE_URL}/Select/${id}`, {
    id,
    selected,
  });
  return result.data;
}
