import api from '../index';

const ROSTER_BASE_URL = '/api/v1/Roster';
const CUSTOM_REPORTS_BASE_URL = '/api/v1/CustomReport';

export async function getGroupedRosterVersions(
  payload: VersionRequest
): Promise<GroupedRosterVersionResponse> {
  const result = await api.get<GroupedRosterVersionResponse>(
    `${ROSTER_BASE_URL}/GroupedVersions/${payload.templateId}/${payload.planId}`
  );
  return result.data;
}

export async function getReport(payload: ReportRequest): Promise<string> {
  const url = `${ROSTER_BASE_URL}/ReportVersion/${payload.rosterVersion}/${payload.startDate}/${payload.days}`;
  const result = await api.get<any>(url, { params: payload });
  return result.data;
}

export async function getPasswordProtectedReport(
  payload: ReportFileRequest
): Promise<Blob> {
  const url = `${ROSTER_BASE_URL}/ReportVersionFile`;
  const result = await api.post<Blob>(url, payload, { responseType: 'blob' });
  return result.data;
}

export async function getVersionNotesReport(
  payload: VersionNotesReportRequest
): Promise<string> {
  const url = `${ROSTER_BASE_URL}/VersionNotesReport/${payload.templateId}/${payload.planId}`;
  const result = await api.get<any>(url, { params: payload });
  return result.data;
}

export async function getPasswordProtectedVersionNotesReport(
  payload: PasswordProtectedVersionNotesReportRequest
): Promise<Blob> {
  const url = `${ROSTER_BASE_URL}/VersionNotesReportFile`;
  const result = await api.post<Blob>(url, payload, { responseType: 'blob' });
  return result.data;
}

// custom reports
export async function getCustomReportList(): Promise<CustomReportResponse[]> {
  const url = `${CUSTOM_REPORTS_BASE_URL}/List`;
  const result = await api.get<CustomReportResponse[]>(url);
  return result.data;
}

export async function getCustomReportsByTemplate(
  templateId: number
): Promise<CustomReportResponse[]> {
  const url = `${CUSTOM_REPORTS_BASE_URL}/ByTemplate/${templateId}`;
  const result = await api.get<CustomReportResponse[]>(url);
  return result.data;
}

export async function getCustomReportVersions(
  payload: CustomReportVersionsRequest
): Promise<CustomReportVersionResponse[]> {
  const url = `${CUSTOM_REPORTS_BASE_URL}/Versions/${payload.customReportId}/${payload.startDate}/${payload.endDate}`;
  const result = await api.get<CustomReportVersionResponse[]>(url);
  return result.data;
}

export async function getCustomReportVersion(
  payload: CustomReportVersionRequest
): Promise<string> {
  const url = `${CUSTOM_REPORTS_BASE_URL}/Version/${payload.customReportVersionId}`;
  const result = await api.get<string>(url, { params: payload });
  return result.data;
}

export async function getPasswordProtectedCustomReportVersion(payload: {
  customReportVersionId: string;
  fileName: string;
  password: string;
  startDate: string;
  endDate: string;
  customReportId: number;
}): Promise<Blob> {
  const url = `${CUSTOM_REPORTS_BASE_URL}/VersionFile`;
  const result = await api.post<Blob>(url, payload, { responseType: 'blob' });
  return result.data;
}
