import { call, all, put, throttle, race, take } from 'redux-saga/effects';
import * as overtimeApi from '../../api/modules/overtime';
import * as employeeApi from '../../api/modules/employee';
import { toast } from 'react-toastify';
import {
  setOvertimeTeams,
  setOvertimeOperationByCaseId,
  setOvertimePatient,
  setNZVEmployee,
} from './actions';
import {
  GET_OVERTIME_TEAMS,
  CREATE_OVERTIME_PATIENT,
  UPDATE_OVERTIME_PATIENT,
  GET_OVERTIME_PATIENT_BY_ID,
  UPDATE_OVERTIME_NZV,
  UPDATE_OVERTIME_SOAP,
  ADD_DIAGNOSE_TO_OVERTIME_SURGERY,
  UPDATE_DIAGNOSE_FROM_OVERTIME_SURGERY,
  REMOVE_DIAGNOSE_FROM_OVERTIME_SURGERY,
  ADD_PROCEDURE_TO_OVERTIME_SURGERY,
  UPDATE_PROCEDURE_FROM_OVERTIME_SURGERY,
  REMOVE_PROCEDURE_FROM_OVERTIME_SURGERY,
  GET_OVERTIME_OPERATION_BY_CASE_ID,
  GET_NZV_EMPLOYEE,
} from './types';
import mapValidationErrors from '../../utilities/map-validation-errors';
import operationMock from '../../api/mockups/operations-mock.json';
import { CANCEL_TASKS } from '../common/types';
import generalErrorHandling from '../../utilities/general-error-handling';

function* getOvertimeTeamsGenerator({ payload }: any) {
  try {
    const result = yield call(overtimeApi.getOvertimeTeams, payload);
    yield put(setOvertimeTeams(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getOvertimePatientGenerator({ payload }: any) {
  try {
    const result = yield call(overtimeApi.getPatient, payload);
    if (result) yield put(setOvertimePatient(result));
    else {
      const data = {
        ...operationMock.patient,
        patientId: payload,
      } as PatientResponse;
      yield put(setOvertimePatient(data));
    }
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getOvertimeOperationByCaseIdGenerator({ payload }: any) {
  try {
    const result = yield call(overtimeApi.getOvertime, payload);
    yield put(setOvertimeOperationByCaseId(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* createOvertimePatientGenerator({ payload, meta }: any) {
  const { setErrors, setSubmitting } = meta;
  try {
    const result = yield call(overtimeApi.createPatient, payload);
    yield put(setOvertimeOperationByCaseId(result));
  } catch (err) {
    if (err && err.length) {
      const errors: any = mapValidationErrors(err, 'patient.');
      yield call(setErrors, errors);
    } else {
      yield call(toast.error, generalErrorHandling(err));
    }
  }
  yield call(setSubmitting, false);
}

function* updateOvertimePatientGenerator({ payload, meta }: any) {
  const { setErrors, setSubmitting } = meta;
  try {
    const result = yield call(overtimeApi.updatePatient, payload);
    yield put(setOvertimeOperationByCaseId(result));
  } catch (err) {
    if (err && err.length) {
      const errors: any = mapValidationErrors(err, 'patient.');
      yield call(setErrors, errors);
    } else {
      yield call(toast.error, generalErrorHandling(err));
    }
  }
  yield call(setSubmitting, false);
}

function* updateNZVGenerator({ payload, meta }: any) {
  const { setErrors, setSubmitting } = meta;
  try {
    const result = yield call(overtimeApi.updateNZV, payload);
    yield put(setOvertimeOperationByCaseId(result));
  } catch (err) {
    if (err && err.length) {
      const errors: any = mapValidationErrors(err, 'case.');
      yield call(setErrors, errors);
    } else {
      yield call(toast.error, generalErrorHandling(err));
    }
  }
  yield call(setSubmitting, false);
}

function* updateSOAPGenerator({ payload, meta }: any) {
  const { setErrors, setSubmitting } = meta;
  try {
    const result = yield call(overtimeApi.updateSOAP, payload);
    yield put(setOvertimeOperationByCaseId(result));
  } catch (err) {
    if (err && err.length) {
      const errors: any = mapValidationErrors(err, 'soap.');
      yield call(setErrors, errors);
    } else {
      yield call(toast.error, generalErrorHandling(err));
    }
  }
  yield call(setSubmitting, false);
}

function* addDiagnoseToOvertimeSurgeryGenerator({ payload }: any) {
  try {
    const result = yield call(overtimeApi.addDiagnoseToOperations, payload);
    yield put(setOvertimeOperationByCaseId(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}
function* updateDiagnoseToOvertimeSurgeryGenerator({ payload }: any) {
  try {
    const result = yield call(overtimeApi.updateDiagnoseToOperations, payload);
    yield put(setOvertimeOperationByCaseId(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}
function* removeDiagnoseToOvertimeSurgeryGenerator({ payload }: any) {
  try {
    const result = yield call(overtimeApi.removeDiagnoseToOperations, payload);
    yield put(setOvertimeOperationByCaseId(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}
function* addProcedureToOvertimeSurgeryGenerator({ payload }: any) {
  try {
    const result = yield call(overtimeApi.addProcedureToOperations, payload);
    yield put(setOvertimeOperationByCaseId(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}
function* updateProcedureToOvertimeSurgeryGenerator({ payload }: any) {
  try {
    const result = yield call(overtimeApi.updateProcedureToOperations, payload);
    yield put(setOvertimeOperationByCaseId(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}
function* removeProcedureToOvertimeSurgeryGenerator({ payload }: any) {
  try {
    const result = yield call(overtimeApi.removeProcedureToOperations, payload);
    yield put(setOvertimeOperationByCaseId(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}
function* getNZVEmployeeGenerator({ payload }: any) {
  try {
    const result: EmployeeShortResponse = yield call(
      employeeApi.searchEmployee,
      payload
    );
    yield put(setNZVEmployee(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

export function* overtimeRootSagas() {
  return yield race([
    take(CANCEL_TASKS),
    all([
      throttle(100, GET_OVERTIME_TEAMS, getOvertimeTeamsGenerator),
      throttle(100, GET_OVERTIME_PATIENT_BY_ID, getOvertimePatientGenerator),
      throttle(
        100,
        GET_OVERTIME_OPERATION_BY_CASE_ID,
        getOvertimeOperationByCaseIdGenerator
      ),
      throttle(100, CREATE_OVERTIME_PATIENT, createOvertimePatientGenerator),
      throttle(100, UPDATE_OVERTIME_PATIENT, updateOvertimePatientGenerator),
      throttle(100, UPDATE_OVERTIME_NZV, updateNZVGenerator),
      throttle(100, UPDATE_OVERTIME_SOAP, updateSOAPGenerator),
      throttle(
        100,
        ADD_DIAGNOSE_TO_OVERTIME_SURGERY,
        addDiagnoseToOvertimeSurgeryGenerator
      ),
      throttle(
        100,
        UPDATE_DIAGNOSE_FROM_OVERTIME_SURGERY,
        updateDiagnoseToOvertimeSurgeryGenerator
      ),
      throttle(
        100,
        REMOVE_DIAGNOSE_FROM_OVERTIME_SURGERY,
        removeDiagnoseToOvertimeSurgeryGenerator
      ),
      throttle(
        100,
        ADD_PROCEDURE_TO_OVERTIME_SURGERY,
        addProcedureToOvertimeSurgeryGenerator
      ),
      throttle(
        100,
        UPDATE_PROCEDURE_FROM_OVERTIME_SURGERY,
        updateProcedureToOvertimeSurgeryGenerator
      ),
      throttle(
        100,
        REMOVE_PROCEDURE_FROM_OVERTIME_SURGERY,
        removeProcedureToOvertimeSurgeryGenerator
      ),
      throttle(100, GET_NZV_EMPLOYEE, getNZVEmployeeGenerator),
    ]),
  ]);
}
