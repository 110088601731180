import {
  SET_PATHOLOGY_GROUP_LIST,
  PathologyGroupActionTypes,
  PathologyGroupState,
  SET_PATHOLOGY_GROUP,
} from './types';

const initialState: PathologyGroupState = {
  pathologyGroup: null,
  pathologyGroupList: [],
};

export function pathologyGroupReducer(
  state = initialState,
  action: PathologyGroupActionTypes
): PathologyGroupState {
  switch (action.type) {
    case SET_PATHOLOGY_GROUP_LIST:
      return {
        ...state,
        pathologyGroupList: action.payload as PathologyGroupResponse[],
      };
    case SET_PATHOLOGY_GROUP:
      return {
        ...state,
        pathologyGroup: action.payload as PathologyGroupDetailResponse,
      };
    default:
      return state;
  }
}
