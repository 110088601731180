import api from '../index';

const DOCTOR_SKILLS_BASE_URL = 'api/v1/Employee';

export async function getDoctorSkills(): Promise<DoctorSkillsReponse> {
  const result = await api.get<DoctorSkillsReponse>(
    `${DOCTOR_SKILLS_BASE_URL}/EmployeesSkills`
  );

  return result.data;
}

export async function updateDoctorSkills(
  payload: UpdateDoctorSkillsRequest
): Promise<DoctorSkillsReponse> {
  const result = await api.put<DoctorSkillsReponse>(
    `${DOCTOR_SKILLS_BASE_URL}/${payload.userName}/SetSkill/${payload.id}`,
    payload
  );

  return result.data;
}
