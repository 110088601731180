import api from '../index';

const ROSTER_TEMPLATE_BASE_URL = '/api/v1/Roster/Template';

// GET
export async function getRosterTemplates(): Promise<
  BaseRosterTemplateResponse[]
> {
  const result = await api.get<BaseRosterTemplateResponse[]>(
    `${ROSTER_TEMPLATE_BASE_URL}`
  );
  return result.data;
}

export async function getRosterTemplateDetail(
  id: number
): Promise<RosterTemplateResponse> {
  const result = await api.get<RosterTemplateResponse>(
    `${ROSTER_TEMPLATE_BASE_URL}/${id}`
  );
  return result.data;
}

export async function getTemplateTable(
  payload: RosterTemplateRequest
): Promise<TemplateTableResponse> {
  const url = `${ROSTER_TEMPLATE_BASE_URL}/TemplateTable/${payload.templateId}`;
  const result = await api.get<TemplateTableResponse>(url, { params: payload });
  return result.data;
}
export async function getRosterTemplateSummaryInfo(
  payload: RosterTemplateSummaryInfoRequest
): Promise<SummaryInfoTabResponse[]> {
  const result = await api.get<SummaryInfoTabResponse[]>(
    `${ROSTER_TEMPLATE_BASE_URL}/${payload.templateId}/SummaryInfo`
  );
  return result.data;
}
// POST
export async function createRosterTemplate(
  payload: CreateUpdateRosterTemplate
): Promise<RosterTemplateResponse> {
  const result = await api.post<RosterTemplateResponse>(
    `${ROSTER_TEMPLATE_BASE_URL}`,
    payload
  );
  return result.data;
}
export async function addRosterTemplateWorkplace(
  payload: AddUpdateWorkplaceToRosterTemplateRequest
): Promise<RosterWorkplacesResponse> {
  const result = await api.post<RosterWorkplacesResponse>(
    `${ROSTER_TEMPLATE_BASE_URL}/${payload.templateId}/Workplace`,
    payload
  );
  return result.data;
}
export async function addRosterTemplateWorkplaces(
  payload: AddUpdateWorkplacesToRosterTemplateRequest
): Promise<RosterWorkplacesResponse> {
  const result = await api.post<RosterWorkplacesResponse>(
    `${ROSTER_TEMPLATE_BASE_URL}/${payload.templateId}/WorkplaceBulk`,
    payload
  );
  return result.data;
}
export async function duplicateRosterTemplate(
  templateId: number
): Promise<RosterTemplateResponse> {
  const result = await api.post<RosterTemplateResponse>(
    `${ROSTER_TEMPLATE_BASE_URL}/Copy/${templateId}`,
    {
      templateId,
    }
  );
  return result.data;
}
// PUT
export async function updateRosterTemplate(
  payload: CreateUpdateRosterTemplate
): Promise<RosterTemplateResponse> {
  const result = await api.put<RosterTemplateResponse>(
    `${ROSTER_TEMPLATE_BASE_URL}/${payload.templateId}`,
    payload
  );
  return result.data;
}
export async function updateRosterTemplateWorkplacePositions(
  payload: ReorderRosterTemplateWorkplacePositionsRequest
): Promise<RosterTemplateWorkplacesResponse> {
  const result = await api.put<RosterTemplateWorkplacesResponse>(
    `${ROSTER_TEMPLATE_BASE_URL}/Positions/Workplaces/${payload.templateId}`,
    payload
  );
  return result.data;
}

export async function updateRosterTemplatePositions(
  payload: ReorderRosterTemplatePositionsRequest
): Promise<RosterTemplateResponse> {
  const result = await api.put<RosterTemplateResponse>(
    `${ROSTER_TEMPLATE_BASE_URL}/Positions`,
    payload
  );
  return result.data;
}

export async function updateRosterTemplateWorkplace(
  payload: AddUpdateWorkplaceToRosterTemplateRequest
): Promise<WorkplaceResponse> {
  const result = await api.put<WorkplaceResponse>(
    `${ROSTER_TEMPLATE_BASE_URL}/${payload.templateId}/Workplace/${payload.id}`,
    payload
  );
  return result.data;
}
export async function updateTemplateTableCell(
  payload: UpdateTemplateTableRequest
): Promise<RosterTemplateResponse> {
  const result = await api.put<RosterTemplateResponse>(
    `${ROSTER_TEMPLATE_BASE_URL}/${payload.templateId}/TemplateCell`,
    payload
  );
  return result.data;
}
// DELETE
export async function removeRosterTemplate(templateId: number) {
  const result = await api.delete(`${ROSTER_TEMPLATE_BASE_URL}/${templateId}`);
  return result.data;
}
export async function removeRosterTemplateWorkplace(
  templateId: number,
  rosterWorkplaceId: number
) {
  const result = await api.delete(
    `${ROSTER_TEMPLATE_BASE_URL}/${templateId}/Workplace/${rosterWorkplaceId}`
  );
  return result.data;
}
export async function removeRosterTemplateWorkplaces(
  templateId: number,
  rosterWorkplaceIds: number[]
) {
  const result = await api.post(
    `${ROSTER_TEMPLATE_BASE_URL}/${templateId}/WorkplaceDeleteBulk`,
    {
      templateId: templateId,
      workplaces: rosterWorkplaceIds,
    }
  );
  return result.data;
}
