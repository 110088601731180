import {
  PlanningState,
  PlanningActionTypes,
  SET_USER_WORKPLACE_ROTATION_TABLE,
  SET_ADMIN_WORKPLACE_ROTATION_TABLE,
  SET_EMPLOYEE_WORKPLACES_TABLE,
} from './types';
import userWorkplaceRotationTableMock from '../../api/mockups/user-workplace-rotation-table-mock.json';
import adminWorkplaceRotationTableMock from '../../api/mockups/admin-workplace-rotation-table-mock.json';

const initialState: PlanningState = {
  userWorkplaceRotationTable: userWorkplaceRotationTableMock,
  adminWorkplaceRotationTable: adminWorkplaceRotationTableMock,
  employeeWorkplaceTable: null,
};

export function planningReducer(
  state = initialState,
  action: PlanningActionTypes
): PlanningState {
  switch (action.type) {
    case SET_USER_WORKPLACE_ROTATION_TABLE:
      return {
        ...state,
        userWorkplaceRotationTable:
          action.payload as UserWorkplaceRotationResponse,
      };
    case SET_ADMIN_WORKPLACE_ROTATION_TABLE:
      return {
        ...state,
        adminWorkplaceRotationTable:
          action.payload as AdminWorkplaceRotationResponse,
      };
    case SET_EMPLOYEE_WORKPLACES_TABLE:
      return {
        ...state,
        employeeWorkplaceTable:
          action.payload as EmployeeWorkplaceTableResponse,
      };
    default:
      return state;
  }
}
