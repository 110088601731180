const ROSTER_TEMPLATE_PREFIX = 'ROSTER_TEMPLATE';

export const GET_ROSTER_TEMPLATE_LIST = `${ROSTER_TEMPLATE_PREFIX}/GET_ROSTER_TEMPLATE_LIST`;
export const SET_ROSTER_TEMPLATE_LIST = `${ROSTER_TEMPLATE_PREFIX}/SET_ROSTER_TEMPLATE_LIST`;
export const SET_ROSTER_TEMPLATE = `${ROSTER_TEMPLATE_PREFIX}/SET_ROSTER_TEMPLATE`;
export const GET_ROSTER_TEMPLATE = `${ROSTER_TEMPLATE_PREFIX}/GET_ROSTER_TEMPLATE`;
export const CREATE_ROSTER_TEMPLATE = `${ROSTER_TEMPLATE_PREFIX}/CREATE_ROSTER_TEMPLATE`;
export const DUPLICATE_ROSTER_TEMPLATE = `${ROSTER_TEMPLATE_PREFIX}/DUPLICATE_ROSTER_TEMPLATE`;
export const UPDATE_ROSTER_TEMPLATE = `${ROSTER_TEMPLATE_PREFIX}/UPDATE_ROSTER_TEMPLATE`;
export const REMOVE_ROSTER_TEMPLATE = `${ROSTER_TEMPLATE_PREFIX}/REMOVE_ROSTER_TEMPLATE`;
export const ADD_ROSTER_WORKPLACE = `${ROSTER_TEMPLATE_PREFIX}/ADD_ROSTER_WORKPLACE`;
export const ADD_ROSTER_WORKPLACES = `${ROSTER_TEMPLATE_PREFIX}/ADD_ROSTER_WORKPLACES`;
export const UPDATE_ROSTER_WORKPLACE = `${ROSTER_TEMPLATE_PREFIX}/UPDATE_ROSTER_WORKPLACE`;
export const REMOVE_ROSTER_WORKPLACE = `${ROSTER_TEMPLATE_PREFIX}/REMOVE_ROSTER_WORKPLACE`;
export const REMOVE_ROSTER_WORKPLACES = `${ROSTER_TEMPLATE_PREFIX}/REMOVE_ROSTER_WORKPLACES`;
export const REORDER_ROSTER_TEMPLATE_WORKPLACE_POSITIONS = `${ROSTER_TEMPLATE_PREFIX}/REORDER_ROSTER_TEMPLATE_WORKPLACE_POSITIONS`;
export const REORDER_ROSTER_TEMPLATE_POSITIONS = `${ROSTER_TEMPLATE_PREFIX}/REORDER_ROSTER_TEMPLATE_POSITIONS`;
export const GET_TEMPLATE_TABLE = `${ROSTER_TEMPLATE_PREFIX}/GET_TEMPLATE_TABLE`;
export const SET_TEMPLATE_TABLE = `${ROSTER_TEMPLATE_PREFIX}/SET_TEMPLATE_TABLE`;
export const UPDATE_TEMPLATE_TABLE = `${ROSTER_TEMPLATE_PREFIX}/UPDATE_TEMPLATE_TABLE`;
export const GET_ROSTER_SUMMARY_INFO = `${ROSTER_TEMPLATE_PREFIX}/GET_ROSTER_SUMMARY_INFO`;
export const SET_ROSTER_SUMMARY_INFO = `${ROSTER_TEMPLATE_PREFIX}/SET_ROSTER_SUMMARY_INFO`;
export const GET_ROSTER_TEMPLATE_SUMMARY_INFO = `${ROSTER_TEMPLATE_PREFIX}/GET_ROSTER_TEMPLATE_SUMMARY_INFO`;
export const SET_ROSTER_TEMPLATE_SUMMARY_INFO = `${ROSTER_TEMPLATE_PREFIX}/SET_ROSTER_TEMPLATE_SUMMARY_INFO`;

export interface RosterTemplateState {
  templates: BaseRosterTemplateResponse[];
  template: CreateUpdateRosterTemplate | null;
  reports: string[];
  templateTable: TemplateTableResponse | null;
  rosterSummaryInfoTabs: SummaryInfoTabResponse[];
  rosterTableSummaryInfoTabs: SummaryInfoTabResponse[];
}
interface SetRosterTemplates {
  type: typeof SET_ROSTER_TEMPLATE;
  payload: BaseRosterTemplateResponse[];
}
interface SetRosterTemplate {
  type: typeof SET_ROSTER_TEMPLATE;
  payload: CreateUpdateRosterTemplate;
}
interface GetRosterTemplate {
  type: typeof GET_ROSTER_TEMPLATE;
  payload: number;
}
interface CreateRosterTemplate {
  type: typeof CREATE_ROSTER_TEMPLATE;
  payload: CreateUpdateRosterTemplate;
  meta: any;
}
interface DuplicateRosterTemplate {
  type: typeof DUPLICATE_ROSTER_TEMPLATE;
  payload: number;
}
interface UpdateRosterTemplate {
  type: typeof UPDATE_ROSTER_TEMPLATE;
  payload: CreateUpdateRosterTemplate;
  meta: any;
}
interface RemoveRosterTemplate {
  type: typeof REMOVE_ROSTER_TEMPLATE;
  payload: number;
}
interface AddRosterTemplateWorkplace {
  type: typeof ADD_ROSTER_WORKPLACE;
  payload: AddUpdateWorkplaceToRosterTemplateRequest;
}
interface UpdateRosterTemplateWorkplace {
  type: typeof UPDATE_ROSTER_WORKPLACE;
  payload: AddUpdateWorkplaceToRosterTemplateRequest;
  meta: any;
}
interface RemoveRosterTemplateWorkplace {
  type: typeof REMOVE_ROSTER_WORKPLACE;
  payload: RemoveRosterTemplateWorkplacePositionsRequest;
}
interface ReorderRosterTemplateWorkplacePositions {
  type: typeof REORDER_ROSTER_TEMPLATE_WORKPLACE_POSITIONS;
  payload: ReorderRosterTemplateWorkplacePositionsRequest;
}
interface ReorderRosterTemplatePositions {
  type: typeof REORDER_ROSTER_TEMPLATE_WORKPLACE_POSITIONS;
  payload: ReorderRosterTemplatePositionsRequest;
}

interface GetTemplateTable {
  type: typeof GET_TEMPLATE_TABLE;
  payload: RosterTemplateRequest;
}
interface SetTemplateTable {
  type: typeof GET_TEMPLATE_TABLE;
  payload: TemplateTableResponse | null;
}
interface UpdateTemplateTable {
  type: typeof UPDATE_TEMPLATE_TABLE;
  payload: UpdateTemplateTableRequest;
}
interface GetRosterSummaryInfo {
  type: typeof GET_ROSTER_SUMMARY_INFO;
  payload: RosterSummaryInfoRequest;
}
interface SetRosterSummaryInfo {
  type: typeof SET_ROSTER_SUMMARY_INFO;
  payload: SummaryInfoTabResponse[];
}
interface GetRosterTemplateSummaryInfo {
  type: typeof GET_ROSTER_TEMPLATE_SUMMARY_INFO;
  payload: RosterTemplateSummaryInfoRequest;
}
interface SetRosterTemplateSummaryInfo {
  type: typeof SET_ROSTER_TEMPLATE_SUMMARY_INFO;
  payload: SummaryInfoTabResponse[];
}

export type RosterTemplateActionTypes =
  | SetRosterTemplates
  | SetRosterTemplate
  | GetRosterTemplate
  | CreateRosterTemplate
  | DuplicateRosterTemplate
  | UpdateRosterTemplate
  | RemoveRosterTemplate
  | AddRosterTemplateWorkplace
  | UpdateRosterTemplateWorkplace
  | RemoveRosterTemplateWorkplace
  | ReorderRosterTemplateWorkplacePositions
  | ReorderRosterTemplatePositions
  | GetTemplateTable
  | SetTemplateTable
  | UpdateTemplateTable
  | GetRosterSummaryInfo
  | SetRosterSummaryInfo
  | GetRosterTemplateSummaryInfo
  | SetRosterTemplateSummaryInfo;
