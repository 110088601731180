import {
  CREATE_USER,
  GET_LANGUAGES,
  GET_USER,
  GET_USER_EMPLOYEES,
  GET_USER_BY_DOCTOR_ID,
  GET_USER_BY_EMPLOYEE_ID,
  REMOVE_USER,
  REMOVE_USER_PASSWORD,
  REORDER_EMPLOYEE,
  SET_LANGUAGES,
  SET_USER,
  SET_USER_EMPLOYEES,
  UPDATE_USER,
  UserManagerActionTypes,
  GET_USER_EMPLOYEES_INDICATORS,
  SET_USER_EMPLOYEES_INDICATORS,
  GET_USER_EMPLOYEES_OPERATIONS_INDICATORS,
  GET_AVAILABLE_EMPLOYEES,
  SET_AVAILABLE_EMPLOYEES,
} from './types';

export function setUserEmployees(
  users: UserEmployeeResponse[]
): UserManagerActionTypes {
  return {
    type: SET_USER_EMPLOYEES,
    payload: users,
  };
}

export function getUserEmployees(
  payload: EmployeeRequest
): UserManagerActionTypes {
  return {
    type: GET_USER_EMPLOYEES,
    payload: payload,
  };
}

export function createUser(
  payload: CreateUpdateUserEmployeeRequest,
  meta: any
): UserManagerActionTypes {
  return {
    type: CREATE_USER,
    payload: payload,
    meta: meta,
  };
}

export function updateUser(
  payload: CreateUpdateUserEmployeeRequest,
  meta: any
): UserManagerActionTypes {
  return {
    type: UPDATE_USER,
    payload: payload,
    meta: meta,
  };
}

export function removeUser(payload: string): UserManagerActionTypes {
  return {
    type: REMOVE_USER,
    payload: payload,
  };
}

export function getUser(payload: string): UserManagerActionTypes {
  return {
    type: GET_USER,
    payload: payload,
  };
}

export function getUserByEmployeeId(payload: string): UserManagerActionTypes {
  return {
    type: GET_USER_BY_EMPLOYEE_ID,
    payload: payload,
  };
}

export function getUserByDoctorId(payload: string): UserManagerActionTypes {
  return {
    type: GET_USER_BY_DOCTOR_ID,
    payload: payload,
  };
}

export function setUser(
  payload: UserEmployeeResponse | null
): UserManagerActionTypes {
  return {
    type: SET_USER,
    payload: payload,
  };
}

export function getLanguages(): UserManagerActionTypes {
  return {
    type: GET_LANGUAGES,
    payload: null,
  };
}

export function setLanguages(languages: string[]): UserManagerActionTypes {
  return {
    type: SET_LANGUAGES,
    payload: languages,
  };
}

export function removeUserPassword(userId: string): UserManagerActionTypes {
  return {
    type: REMOVE_USER_PASSWORD,
    payload: userId,
  };
}

export function reorderEmployee(
  payload: ReorderEmployeeRequest,
  meta: { onSuccess: () => void }
): UserManagerActionTypes {
  return {
    type: REORDER_EMPLOYEE,
    payload: payload,
    meta: meta,
  };
}

export function getUserEmployeesIndicators(
  payload: EmployeeIndicatorRequest
): UserManagerActionTypes {
  return {
    type: GET_USER_EMPLOYEES_INDICATORS,
    payload: payload,
  };
}

export function setUserEmployeesIndicators(
  payload: EmployeeWithIndicatorResponse[]
): UserManagerActionTypes {
  return {
    type: SET_USER_EMPLOYEES_INDICATORS,
    payload: payload,
  };
}

export function getUserEmployeesOperationsIndicators(
  payload: EmployeeIndicatorOperationsRequest
): UserManagerActionTypes {
  return {
    type: GET_USER_EMPLOYEES_OPERATIONS_INDICATORS,
    payload: payload,
  };
}

export function getAvailableEmployees(
  payload: AvailableEmployeesRequest
): UserManagerActionTypes {
  return {
    type: GET_AVAILABLE_EMPLOYEES,
    payload: payload,
  };
}

export function setAvailableEmployees(
  payload: AvailableEmployeesResponse[]
): UserManagerActionTypes {
  return {
    type: SET_AVAILABLE_EMPLOYEES,
    payload: payload,
  };
}
