const QUALIFICATIONS_PREFIX = 'QUALIFICATIONS';

export const REORDER_QUALIFICATIONS = `${QUALIFICATIONS_PREFIX}/REORDER_QUALIFICATIONS`;
export const GET_QUALIFICATION_LIST = `${QUALIFICATIONS_PREFIX}/GET_QUALIFICATION_LIST`;
export const SET_QUALIFICATION_LIST = `${QUALIFICATIONS_PREFIX}/SET_QUALIFICATION_LIST`;
export const TOGGLE_QUALIFICATION_STATE = `${QUALIFICATIONS_PREFIX}/TOGGLE_QUALIFICATION_STATE`;

export interface QualificationState {
  qualificationList: QualificationResponse[];
}

interface ReorderQualifications {
  type: typeof REORDER_QUALIFICATIONS;
  payload: ReorderQualificationsRequest;
  meta: { onSuccess: () => void };
}

interface GetQualifications {
  type: typeof GET_QUALIFICATION_LIST;
  payload: null;
}
interface SetQualifications {
  type: typeof GET_QUALIFICATION_LIST;
  payload: QualificationResponse[];
}
interface ToggleQualificationState {
  type: typeof TOGGLE_QUALIFICATION_STATE;
  payload: ToggleQualificationStateRequest;
  meta(): void;
}

export type QualificationsActionTypes =
  | ReorderQualifications
  | GetQualifications
  | SetQualifications
  | ToggleQualificationState;
