import {
  PlanningActionTypes,
  GET_USER_WORKPLACE_ROTATION_TABLE,
  SET_USER_WORKPLACE_ROTATION_TABLE,
  CREATE_USER_WORKPLACE_ROTATION_CELL,
  UPDATE_USER_WORKPLACE_ROTATION_CELL,
  GET_ADMIN_WORKPLACE_ROTATION_TABLE,
  SET_ADMIN_WORKPLACE_ROTATION_TABLE,
  DELETE_USER_WORKPLACE_ROTATION_CELL,
  GET_EMPLOYEE_WORKPLACES_TABLE,
  SET_EMPLOYEE_WORKPLACES_TABLE,
  MARK_EMPLOYEE_WORKPLACE,
} from './types';

export function getUserWorkplaceRotationTable(
  payload: GetUserWorkplaceRotationTableRequest
): PlanningActionTypes {
  return {
    type: GET_USER_WORKPLACE_ROTATION_TABLE,
    payload: payload,
  };
}
export function setUserWorkplaceRotationTable(
  payload: UserWorkplaceRotationResponse
): PlanningActionTypes {
  return {
    type: SET_USER_WORKPLACE_ROTATION_TABLE,
    payload: payload,
  };
}
export function createUserWorkplaceRotationCell(
  payload: CreateUserWorkplaceRotationCellRequest
): PlanningActionTypes {
  return {
    type: CREATE_USER_WORKPLACE_ROTATION_CELL,
    payload: payload,
  };
}
export function updateUserWorkplaceRotationCell(
  payload: UpdateUserWorkplaceRotationCellRequest
): PlanningActionTypes {
  return {
    type: UPDATE_USER_WORKPLACE_ROTATION_CELL,
    payload: payload,
  };
}
export function deleteUserWorkplaceRotationCell(
  payload: number,
  meta: any
): PlanningActionTypes {
  return {
    type: DELETE_USER_WORKPLACE_ROTATION_CELL,
    payload: payload,
    meta: meta,
  };
}
export function getAdminWorkplaceRotationTable(
  payload: GetAdminWorkplaceRotationTableRequest
): PlanningActionTypes {
  return {
    type: GET_ADMIN_WORKPLACE_ROTATION_TABLE,
    payload: payload,
  };
}
export function setAdminWorkplaceRotationTable(
  payload: AdminWorkplaceRotationResponse
): PlanningActionTypes {
  return {
    type: SET_ADMIN_WORKPLACE_ROTATION_TABLE,
    payload: payload,
  };
}
export function getEmployeeWorkplacesTable(
  payload: string
): PlanningActionTypes {
  return {
    type: GET_EMPLOYEE_WORKPLACES_TABLE,
    payload: payload,
  };
}
export function setEmployeeWorkplaceTable(
  payload: EmployeeWorkplaceTableResponse
): PlanningActionTypes {
  return {
    type: SET_EMPLOYEE_WORKPLACES_TABLE,
    payload,
  };
}
export function markEmployeeWorkplace(
  payload: EmployeeWorkplaceMarkRequest
): PlanningActionTypes {
  return {
    type: MARK_EMPLOYEE_WORKPLACE,
    payload,
  };
}
