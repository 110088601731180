import {
  AttachmentActionTypes,
  GET_ATTACHMENT_FILES,
  SET_ATTACHMENT_FILES,
  UPLOAD_ATTACHMENT,
  SET_ATTACHMENT_FILE,
  REMOVE_ATTACHMENT,
} from './types';

export function getAttachmentFiles(
  payload: AttachmentFileRequest
): AttachmentActionTypes {
  return {
    type: GET_ATTACHMENT_FILES,
    payload: payload,
  };
}

export function setAttachmentFiles(
  payload: AttachmentFileResponse[]
): AttachmentActionTypes {
  return {
    type: SET_ATTACHMENT_FILES,
    payload: payload,
  };
}

export function setAttachmentFile(
  payload: AttachmentFileResponse
): AttachmentActionTypes {
  return {
    type: SET_ATTACHMENT_FILE,
    payload: payload,
  };
}

export function uploadAttachment(
  payload: UploadAttachmentRequest
): AttachmentActionTypes {
  return {
    type: UPLOAD_ATTACHMENT,
    payload: payload,
  };
}

export function removeAttachment(payload: string): AttachmentActionTypes {
  return {
    type: REMOVE_ATTACHMENT,
    payload: payload,
  };
}
