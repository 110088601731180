import {
  SET_WORKPLACE_ACTIVITY,
  WorkplaceActivityState,
  WorkplaceActivityTypes,
  SET_CANCEL_REASONS,
  SET_SUMMARY_INFO_TAB,
} from './types';

const initialState: WorkplaceActivityState = {
  workplacesActivity: [],
  cancelReasons: [],
  summaryInfoTabs: [],
};

export function workplaceActivityReducer(
  state = initialState,
  action: WorkplaceActivityTypes
): WorkplaceActivityState {
  switch (action.type) {
    case SET_WORKPLACE_ACTIVITY:
      return {
        ...state,
        workplacesActivity: action.payload as WorkplaceActivityResponse[],
      };
    case SET_CANCEL_REASONS:
      return {
        ...state,
        cancelReasons: action.payload as string[],
      };
    case SET_SUMMARY_INFO_TAB:
      return {
        ...state,
        summaryInfoTabs: action.payload as SummaryInfoTabResponse[],
      };
    default:
      return state;
  }
}
