import {
  QualificationState,
  QualificationsActionTypes,
  SET_QUALIFICATION_LIST,
} from './types';

const initalState: QualificationState = {
  qualificationList: [],
};

export function qualificationReducer(
  state = initalState,
  action: QualificationsActionTypes
): QualificationState {
  switch (action.type) {
    case SET_QUALIFICATION_LIST:
      return {
        ...state,
        qualificationList: action.payload as QualificationResponse[],
      };
    default:
      return state;
  }
}
