import axios, { AxiosError } from 'axios';
import { setLoading } from '../store/common/actions';
import { throttle } from 'lodash';

let store: any;

export const injectStore = (_store: any) => {
  store = _store;
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_API,
});

const dispatchLoading = throttle((state: boolean) => {
  store.dispatch(setLoading(state));
}, 500);

instance.interceptors.request.use(
  function (request) {
    dispatchLoading(true);
    return request;
  },
  function (error) {
    dispatchLoading(false);
    const err = error as AxiosError;
    return Promise.reject(err.response?.data as ErrorResponse);
  }
);
instance.interceptors.response.use(
  function (response) {
    dispatchLoading(false);
    return response;
  },
  function (error) {
    dispatchLoading(false);
    const err = error as AxiosError;
    return Promise.reject(err.response?.data as ErrorResponse);
  }
);

// get tenant from local storage
instance.defaults.headers.common['Tenant'] =
  localStorage.getItem('department') ?? '';

export function setAccessToken(token: string) {
  instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export function setLanguageHeader(language: string) {
  instance.defaults.headers.common['language'] = language;
}

export function setTenantHeader(tenant: string) {
  instance.defaults.headers.common['Tenant'] = tenant;
}

export default instance;
