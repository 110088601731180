const WORKPLACE_ACTIVITY = 'WORKPLACE_ACTIVITY';
export const GET_WORKPLACE_ACTIVITY = `${WORKPLACE_ACTIVITY}/GET_WORKPLACE_ACTIVITY`;
export const SET_WORKPLACE_ACTIVITY = `${WORKPLACE_ACTIVITY}/SET_WORKPLACE_ACTIVITY`;
export const CREATE_WORKPLACE_ACTIVITY = `${WORKPLACE_ACTIVITY}/CREATE_WORKPLACE_ACTIVITY`;
export const UPDATE_WORKPLACE_ACTIVITY = `${WORKPLACE_ACTIVITY}/UPDATE_WORKPLACE_ACTIVITY`;
export const REMOVE_WORKPLACE_ACTIVITY = `${WORKPLACE_ACTIVITY}/REMOVE_WORKPLACE_ACTIVITY`;
export const CANCEL_WORKPLACE_ACTIVITY = `${WORKPLACE_ACTIVITY}/CANCEL_WORKPLACE_ACTIVITY`;
export const CANCEL_OCCURRENCE_WORKPLACE_ACTIVITY = `${WORKPLACE_ACTIVITY}/CANCEL_OCCURRENCE_WORKPLACE_ACTIVITY`;
export const GET_CANCEL_REASONS = `${WORKPLACE_ACTIVITY}/GET_CANCEL_REASONS`;
export const SET_CANCEL_REASONS = `${WORKPLACE_ACTIVITY}/SET_CANCEL_REASONS`;
export const GET_SUMMARY_INFO_TAB = `${WORKPLACE_ACTIVITY}/GET_SUMMARY_INFO_TAB`;
export const SET_SUMMARY_INFO_TAB = `${WORKPLACE_ACTIVITY}/SET_SUMMARY_INFO_TAB`;

export interface WorkplaceActivityState {
  workplacesActivity: WorkplaceActivityResponse[];
  cancelReasons: string[];
  summaryInfoTabs: SummaryInfoTabResponse[];
}
interface GetWorkplaceActivity {
  type: typeof GET_WORKPLACE_ACTIVITY;
  payload: null;
}
interface SetWorkplaceActivity {
  type: typeof SET_WORKPLACE_ACTIVITY;
  payload: WorkplaceActivityResponse[];
}
interface CreateWorkplaceActivity {
  type: typeof CREATE_WORKPLACE_ACTIVITY;
  payload: CreateUpdateWorkplaceActivityRequest;
}
interface UpdateWorkplaceActivity {
  type: typeof UPDATE_WORKPLACE_ACTIVITY;
  payload: CreateUpdateWorkplaceActivityRequest;
}
interface RemoveWorkplaceActivity {
  type: typeof UPDATE_WORKPLACE_ACTIVITY;
  payload: string;
}
interface CancelWorkplaceActivity {
  type: typeof CANCEL_WORKPLACE_ACTIVITY;
  payload: CancelWorkplaceActivityRequest;
  meta: any;
}
interface CancelOccurrenceWorkplaceActivity {
  type: typeof CANCEL_OCCURRENCE_WORKPLACE_ACTIVITY;
  payload: CancelOccurrenceWorkplaceActivityRequest;
  meta: any;
}
interface GetCancelReasons {
  type: typeof GET_CANCEL_REASONS;
  payload: any;
}
interface SetCancelReasons {
  type: typeof GET_CANCEL_REASONS;
  payload: string[];
}
interface GetSummaryInfoTab {
  type: typeof GET_SUMMARY_INFO_TAB;
  payload: WorkplaceActivitySummaryInfoRequest;
}
interface SetSummaryInfoTab {
  type: typeof SET_SUMMARY_INFO_TAB;
  payload: SummaryInfoTabResponse[];
}

export type WorkplaceActivityTypes =
  | GetWorkplaceActivity
  | SetWorkplaceActivity
  | CreateWorkplaceActivity
  | UpdateWorkplaceActivity
  | RemoveWorkplaceActivity
  | CancelWorkplaceActivity
  | CancelOccurrenceWorkplaceActivity
  | GetCancelReasons
  | SetCancelReasons
  | GetSummaryInfoTab
  | SetSummaryInfoTab;
