import {
  SET_WORKPLACE_LIST,
  WorkplaceActionTypes,
  WorkplaceState,
  SET_WORKPLACE_DETAIL,
  SET_WORKPLACE_OPERATING_THEATRES,
  SET_WORKPLACE_ROTATION_WORKPLACES,
} from './types';

const initialState: WorkplaceState = {
  workplaceList: [],
  workplace: null,
  workplaceOperatingTheatres: [],
  workplaceRotationWorkplaces: [],
};

export function workplaceReducer(
  state = initialState,
  action: WorkplaceActionTypes
): WorkplaceState {
  switch (action.type) {
    case SET_WORKPLACE_LIST:
      return {
        ...state,
        workplaceList: action.payload as WorkplaceResponse[],
      };
    case SET_WORKPLACE_DETAIL:
      return {
        ...state,
        workplace: action.payload as WorkplaceDetailResponse,
      };
    case SET_WORKPLACE_OPERATING_THEATRES:
      return {
        ...state,
        workplaceOperatingTheatres: action.payload as WorkplaceResponse[],
      };
    case SET_WORKPLACE_ROTATION_WORKPLACES:
      return {
        ...state,
        workplaceRotationWorkplaces: action.payload as WorkplaceResponse[],
      };
    default:
      return state;
  }
}
