import customReportMock from '../../api/mockups/custom-report-mock.json';
import customReportVersionsMock from '../../api/mockups/custom-report-versions-mock.json';
import {
  ReportsState,
  ReportsActionTypes,
  SET_REPORT_VERSIONS,
  SET_REPORT,
  SET_CUSTOM_REPORT_LIST,
  SET_CUSTOM_REPORTS_BY_TEMPLATE,
  SET_CUSTOM_REPORT_VERSIONS,
  SET_CUSTOM_REPORT_VERSION,
} from './types';

const initialState: ReportsState = {
  reportVersions: [],
  report: '',
  versionNotesReport: '',
  customReport: '',
  customReports: customReportMock,
  customReportsByTemplate: customReportMock,
  customReportVersions: customReportVersionsMock,
};

export function reportsReducer(
  state = initialState,
  action: ReportsActionTypes
): ReportsState {
  switch (action.type) {
    case SET_REPORT_VERSIONS:
      return {
        ...state,
        reportVersions: (action.payload as GroupedRosterVersionResponse)
          .rosterVersions,
      };
    case SET_REPORT:
      return {
        ...state,
        report: action.payload as string,
      };
    case SET_CUSTOM_REPORT_LIST:
      return {
        ...state,
        customReports: action.payload as CustomReportResponse[],
      };
    case SET_CUSTOM_REPORTS_BY_TEMPLATE:
      return {
        ...state,
        customReportsByTemplate: action.payload as CustomReportResponse[],
      };
    case SET_CUSTOM_REPORT_VERSIONS:
      return {
        ...state,
        customReportVersions: action.payload as CustomReportVersionResponse[],
      };
    case SET_CUSTOM_REPORT_VERSION:
      return {
        ...state,
        customReport: action.payload as string,
      };
    default:
      return state;
  }
}
