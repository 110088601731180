import {
  CREATE_WORKPLACE,
  REMOVE_WORKPLACE,
  REORDER_WORKPLACE_LIST,
  SET_WORKPLACE_LIST,
  UPDATE_WORKPLACE,
  WorkplaceActionTypes,
  TOGGLE_WORKPLACE_ACTIVITY_WORKPLACE,
  GET_WORKPLACE_DETAIL,
  SET_WORKPLACE_DETAIL,
  GET_WORKPLACE_OPERATING_THEATRES,
  SET_WORKPLACE_OPERATING_THEATRES,
  GET_WORKPLACE_LIST,
  GET_WORKPLACE_ROTATION_WORKPLACES,
  SET_WORKPLACE_ROTATION_WORKPLACES,
} from './types';

export function getWorkplaceList(): WorkplaceActionTypes {
  return {
    type: GET_WORKPLACE_LIST,
    payload: null,
  };
}
export function setWorkplaceList(
  payload: WorkplaceResponse[]
): WorkplaceActionTypes {
  return {
    type: SET_WORKPLACE_LIST,
    payload: payload,
  };
}
export function getWorkplace(payload: number): WorkplaceActionTypes {
  return {
    type: GET_WORKPLACE_DETAIL,
    payload: payload,
  };
}
export function setWorkplace(
  payload: WorkplaceDetailResponse | null
): WorkplaceActionTypes {
  return {
    type: SET_WORKPLACE_DETAIL,
    payload: payload,
  };
}
export function createWorkplace(
  payload: CreateUpdateWorkplaceRequest,
  meta: any
): WorkplaceActionTypes {
  return {
    type: CREATE_WORKPLACE,
    payload: payload,
    meta: meta,
  };
}
export function updateWorkplace(
  payload: CreateUpdateWorkplaceRequest,
  meta: any
): WorkplaceActionTypes {
  return {
    type: UPDATE_WORKPLACE,
    payload: payload,
    meta: meta,
  };
}
export function removeWorkplace(payload: number): WorkplaceActionTypes {
  return {
    type: REMOVE_WORKPLACE,
    payload: payload,
  };
}
export function reorderWorkplaces(
  payload: UpdateWorkplacesParentPositionRequest
): WorkplaceActionTypes {
  return {
    type: REORDER_WORKPLACE_LIST,
    payload: payload,
  };
}
export function toggleWorkplaceActivityWorkplace(
  payload: CheckWorkplaceRequest,
  meta: any
): WorkplaceActionTypes {
  return {
    type: TOGGLE_WORKPLACE_ACTIVITY_WORKPLACE,
    payload: payload,
    meta: meta,
  };
}
export function getWorkplaceOperatingTheatres(): WorkplaceActionTypes {
  return {
    type: GET_WORKPLACE_OPERATING_THEATRES,
    payload: null,
  };
}
export function setWorkplaceOperatingTheatres(
  payload: WorkplaceResponse[]
): WorkplaceActionTypes {
  return {
    type: SET_WORKPLACE_OPERATING_THEATRES,
    payload,
  };
}
export function getWorkplaceRotationWorkplaces(): WorkplaceActionTypes {
  return {
    type: GET_WORKPLACE_ROTATION_WORKPLACES,
    payload: null,
  };
}
export function SetWorkplaceRotationWorkplaces(
  payload: WorkplaceResponse[]
): WorkplaceActionTypes {
  return {
    type: SET_WORKPLACE_ROTATION_WORKPLACES,
    payload,
  };
}
