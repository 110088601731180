import { all, call, put, throttle, race, take } from 'redux-saga/effects';
import * as availabilityApi from '../../../api/modules/availability';
import {
  setAvailability,
  setPlanParams,
  setAvailabilitySummaryInfo,
} from './actions';
import { toast } from 'react-toastify';
import mapValidationErrors from '../../../utilities/map-validation-errors';
import {
  GET_AVAILABILITY_LIST,
  GET_PLAN_PARAMS,
  UPDATE_PLAN_PARAMS,
  GET_AVAILABILITY_SUMMARY_INFO,
} from './types';
import { CANCEL_TASKS } from '../../common/types';
import generalErrorHandling from '../../../utilities/general-error-handling';

function* getAvailabilityGenerator() {
  try {
    const result = yield call(availabilityApi.getAvailabilityList);
    yield put(setAvailability(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}
function* getPlanParamsGenerator({ payload }: any) {
  try {
    const result = yield call(availabilityApi.getPlanParameters, payload);
    yield put(setPlanParams(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}
function* updatePlanParamsGenerator({ payload, meta }: any) {
  const { setErrors, setSubmitting } = meta;
  try {
    const result = yield call(availabilityApi.updatePlanParameters, payload);
    yield put(setPlanParams(result));
  } catch (err) {
    if (err && err.length) {
      const errors: any = mapValidationErrors(err);
      yield call(setErrors, errors);
    } else {
      yield call(toast.error, generalErrorHandling(err));
    }
  }
  yield call(setSubmitting, false);
}
function* getAvailabilitySummaryInfoGenerator({ payload }: any) {
  try {
    const result = yield call(
      availabilityApi.getAvailabilitySummaryInfo,
      payload
    );
    yield put(setAvailabilitySummaryInfo(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

export function* availabilityRootSagas() {
  return yield race([
    take(CANCEL_TASKS),
    all([
      throttle(100, GET_AVAILABILITY_LIST, getAvailabilityGenerator),
      throttle(100, GET_PLAN_PARAMS, getPlanParamsGenerator),
      throttle(100, UPDATE_PLAN_PARAMS, updatePlanParamsGenerator),
      throttle(
        100,
        GET_AVAILABILITY_SUMMARY_INFO,
        getAvailabilitySummaryInfoGenerator
      ),
    ]),
  ]);
}
