import {
  AvailabilityState,
  AvailabilityActionTypes,
  SET_AVAILABILITY_LIST,
  SET_PLAN_PARAMS,
  SET_AVAILABILITY_SUMMARY_INFO,
} from './types';

const initialState: AvailabilityState = {
  availability: [],
  parameters: null,
  summaryInfoTabs: [],
};

export function availabilityReducer(
  state = initialState,
  action: AvailabilityActionTypes
): AvailabilityState {
  switch (action.type) {
    case SET_AVAILABILITY_LIST:
      return {
        ...state,
        availability: action.payload as AvailabilityResponse[],
      };
    case SET_PLAN_PARAMS:
      return {
        ...state,
        parameters: action.payload as PlanParametersResponse,
      };
    case SET_AVAILABILITY_SUMMARY_INFO:
      return {
        ...state,
        summaryInfoTabs: action.payload as SummaryInfoTabResponse[],
      };
    default:
      return state;
  }
}
