import {
  DoctorQualificationState,
  DoctorQualificationActionTypes,
  SET_DOCTOR_QUALIFICATIONS,
} from './types';

const initialState: DoctorQualificationState = {
  doctorQualifications: null,
};

export function doctorQualificationsReducer(
  state = initialState,
  action: DoctorQualificationActionTypes
): DoctorQualificationState {
  switch (action.type) {
    case SET_DOCTOR_QUALIFICATIONS:
      return {
        ...state,
        doctorQualifications: action.payload as DoctorQualificationsResponse,
      };
    default:
      return state;
  }
}
