import { toast } from 'react-toastify';
import { all, call, put, throttle, take, race } from 'redux-saga/effects';
import { setTenantHeader, setLanguageHeader } from '../../api';
import * as departmentsApi from '../../api/modules/departments';
import { setDepartments } from './actions';
import { setCulture, loadCldr } from '@syncfusion/ej2-base';
import i18n from '../../localization/i18n';

import numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import gregorian from 'cldr-data/main/sl/ca-gregorian.json';
import numbers from 'cldr-data/main/sl/numbers.json';
import timeZoneNames from 'cldr-data/main/sl/timeZoneNames.json';
import {
  GET_DEPARTMENTS,
  SET_USER_DEPARTMENT,
  SET_USER_LANGUAGE,
} from './types';
import { CANCEL_TASKS } from '../common/types';
import generalErrorHandling from '../../utilities/general-error-handling';

function* getDepartmentsGenerator() {
  try {
    const departments = yield call(departmentsApi.getDepartments);
    yield put(setDepartments(departments));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* setDepartmentGenerator({ payload }: any) {
  yield call(setTenantHeader, payload);
  localStorage.setItem('department', payload);
}

function* setUserLanguageGenerator({ payload }: any) {
  i18n.changeLanguage(payload);
  yield call(setCulture, payload);
  yield call(setLanguageHeader, payload);
  if (payload.includes('sl')) {
    yield call(loadCldr, numberingSystems, gregorian, numbers, timeZoneNames);
  }
}

export function* userRootSagas() {
  return yield race([
    take(CANCEL_TASKS),
    all([
      throttle(100, GET_DEPARTMENTS, getDepartmentsGenerator),
      throttle(100, SET_USER_DEPARTMENT, setDepartmentGenerator),
      throttle(100, SET_USER_LANGUAGE, setUserLanguageGenerator),
    ]),
  ]);
}
