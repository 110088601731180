import api from '../index';

const ATTACHMENT_BASE_URL = '/api/v1/Attachments';

export async function getAttachmentFiles(
  payload: AttachmentFileRequest
): Promise<AttachmentFileResponse[]> {
  const url = `${ATTACHMENT_BASE_URL}/AttachmentFiles`;
  const result = await api.get(url, {
    params: payload,
  });
  return result.data;
}

export async function getAttachmentFile(payload: string): Promise<Blob> {
  const url = `${ATTACHMENT_BASE_URL}/AttachmentFile/${payload}`;
  const result = await api.get<Blob>(url, { responseType: 'blob' });
  return result.data;
}

export async function uploadAttachment(
  payload: UploadAttachmentRequest
): Promise<AttachmentFileRequest> {
  const url = `${ATTACHMENT_BASE_URL}/UploadAttachment`;
  const result = await api.post<AttachmentFileRequest>(url, payload);
  return result.data;
}

export async function deleteAttachment(payload: string): Promise<any> {
  const url = `${ATTACHMENT_BASE_URL}/RemoveAttachment/${payload}`;
  const result = await api.delete(url);
  return result.data;
}
