import {
  ReportsActionTypes,
  GET_REPORT_VERSIONS,
  SET_REPORT_VERSIONS,
  GET_REPORT,
  SET_REPORT,
  GET_CUSTOM_REPORT_LIST,
  SET_CUSTOM_REPORT_LIST,
  GET_CUSTOM_REPORTS_BY_TEMPLATE,
  SET_CUSTOM_REPORTS_BY_TEMPLATE,
  GET_CUSTOM_REPORT_VERSIONS,
  SET_CUSTOM_REPORT_VERSIONS,
  GET_CUSTOM_REPORT_VERSION,
  SET_CUSTOM_REPORT_VERSION,
} from './types';

export function getReportVersions(payload: VersionRequest): ReportsActionTypes {
  return {
    type: GET_REPORT_VERSIONS,
    payload: payload,
  };
}

export function setReportVersions(
  payload: GroupedRosterVersionResponse
): ReportsActionTypes {
  return {
    type: SET_REPORT_VERSIONS,
    payload: payload,
  };
}

export function getReport(payload: ReportRequest): ReportsActionTypes {
  return {
    type: GET_REPORT,
    payload: payload,
  };
}

export function setReport(payload: string): ReportsActionTypes {
  return {
    type: SET_REPORT,
    payload: payload,
  };
}

export function getCustomReportList(): ReportsActionTypes {
  return {
    type: GET_CUSTOM_REPORT_LIST,
    payload: null,
  };
}

export function setCustomReportList(
  payload: CustomReportResponse[]
): ReportsActionTypes {
  return {
    type: SET_CUSTOM_REPORT_LIST,
    payload: payload,
  };
}

export function getCustomReportsByTemplate(
  payload: number
): ReportsActionTypes {
  return {
    type: GET_CUSTOM_REPORTS_BY_TEMPLATE,
    payload: payload,
  };
}

export function setCustomReportsByTemplate(
  payload: CustomReportResponse[]
): ReportsActionTypes {
  return {
    type: SET_CUSTOM_REPORTS_BY_TEMPLATE,
    payload: payload,
  };
}

export function getCustomReportVersions(
  payload: CustomReportVersionsRequest,
  meta: any
): ReportsActionTypes {
  return {
    type: GET_CUSTOM_REPORT_VERSIONS,
    payload: payload,
    meta: meta,
  };
}

export function setCustomReportVersions(
  payload: CustomReportVersionResponse[]
): ReportsActionTypes {
  return {
    type: SET_CUSTOM_REPORT_VERSIONS,
    payload: payload,
  };
}

export function getCustomReportVersion(
  payload: CustomReportVersionRequest
): ReportsActionTypes {
  return {
    type: GET_CUSTOM_REPORT_VERSION,
    payload: payload,
  };
}

export function setCustomReportVersion(payload: string): ReportsActionTypes {
  return {
    type: SET_CUSTOM_REPORT_VERSION,
    payload: payload,
  };
}
