import {
  GET_DOCTOR_SKILLS,
  SET_DOCTOR_SKILLS,
  UPDATE_DOCTOR_SKILLS,
  DoctorSkillActionTypes,
} from './types';

export function getDoctorSkills(): DoctorSkillActionTypes {
  return {
    type: GET_DOCTOR_SKILLS,
    payload: null,
  };
}

export function updateDoctorSkills(
  payload: UpdateDoctorSkillsRequest
): DoctorSkillActionTypes {
  return {
    type: UPDATE_DOCTOR_SKILLS,
    payload: payload,
  };
}

export function setDoctorSkills(
  payload: DoctorSkillsReponse
): DoctorSkillActionTypes {
  return {
    type: SET_DOCTOR_SKILLS,
    payload: payload,
  };
}
