import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import { reducer as oidcReducer } from 'redux-oidc';
import { userReducer } from './user/reducers';
import { adminReducer } from './admin';
import { commonReducer } from './common/reducers';
import { schedulerReducer } from './schedulers/reducers';
import { operationsReducer } from './operations/reducers';
import { reportsReducer } from './reports/reducers';
import { overtimeReducer } from './overtime/reducers';
import { planningReducer } from './planning/reducers';
import { attachmentReducer } from './attachments/reducers';

// sagas
import { oidcRootSagas } from './oidc/sagas';
import { adminRootSagas } from './admin';
import { userRootSagas } from './user/sagas';
import { commonRootSagas } from './common/sagas';
import { schedulersRootSagas } from './schedulers/sagas';
import { reportsRootSagas } from './reports/sagas';
import { operationsRootSagas } from './operations/sagas';
import { overtimeRootSagas } from './overtime/sagas';
import { planningRootSagas } from './planning/sagas';
import { attachmentRootSagas } from './attachments/sagas';

export const rootReducer = combineReducers({
  user: userReducer,
  oidc: oidcReducer,
  admin: adminReducer,
  common: commonReducer,
  schedulers: schedulerReducer,
  reports: reportsReducer,
  operations: operationsReducer,
  overtime: overtimeReducer,
  planning: planningReducer,
  attachment: attachmentReducer,
});

export function* rootSagas() {
  yield all([
    oidcRootSagas(),
    adminRootSagas(),
    userRootSagas(),
    commonRootSagas(),
    schedulersRootSagas(),
    reportsRootSagas(),
    operationsRootSagas(),
    overtimeRootSagas(),
    planningRootSagas(),
    attachmentRootSagas(),
  ]);
}

export type RootState = ReturnType<typeof rootReducer>;
