import {
  all,
  call,
  delay,
  put,
  throttle,
  race,
  take,
} from 'redux-saga/effects';
import * as workplaceApi from '../../../api/modules/workplace';
import { toast } from 'react-toastify';
import mapValidationErrors from '../../../utilities/map-validation-errors';
import {
  setWorkplaceList,
  setWorkplace,
  setWorkplaceOperatingTheatres,
  SetWorkplaceRotationWorkplaces,
} from './actions';
import {
  GET_WORKPLACE_LIST,
  CREATE_WORKPLACE,
  UPDATE_WORKPLACE,
  REMOVE_WORKPLACE,
  REORDER_WORKPLACE_LIST,
  TOGGLE_WORKPLACE_ACTIVITY_WORKPLACE,
  GET_WORKPLACE_DETAIL,
  GET_WORKPLACE_OPERATING_THEATRES,
  GET_WORKPLACE_ROTATION_WORKPLACES,
} from './types';
import { CANCEL_TASKS } from '../../common/types';
import generalErrorHandling from '../../../utilities/general-error-handling';

function* getWorkplaceListGenerator() {
  try {
    const list = yield call(workplaceApi.getWorkplaceList);
    yield put(setWorkplaceList(list));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* createWorkplaceGenerator({ payload, meta }: any) {
  yield delay(300);
  const { resetForm, setErrors, setSubmitting } = meta;
  try {
    yield call(workplaceApi.createWorkplace, payload);
    yield getWorkplaceListGenerator();
    yield call(resetForm);
  } catch (err) {
    if (err && err.length) {
      const errors: any = mapValidationErrors(err);
      yield call(setErrors, errors);
    } else {
      yield call(toast.error, generalErrorHandling(err));
    }
  }
  yield call(setSubmitting, false);
}

function* updateWorkplaceGenerator({ payload, meta }: any) {
  const { setErrors, setSubmitting } = meta;
  try {
    yield call(workplaceApi.updateWorkplace, payload);
    yield getWorkplaceListGenerator();
  } catch (err) {
    if (err && err.length) {
      const errors: any = mapValidationErrors(err);
      yield call(setErrors, errors);
    } else {
      yield call(toast.error, generalErrorHandling(err));
    }
  }
  yield call(setSubmitting, false);
}

function* removeWorkplaceGenerator({ payload }: any) {
  try {
    yield call(workplaceApi.removeWorkplace, payload);
    yield getWorkplaceListGenerator();
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* reorderWorkplaceGenerator({ payload }: any) {
  try {
    const response = yield call(workplaceApi.reorderWorkplaces, payload);
    yield put(setWorkplaceList(response));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* toggleWorkplaceActivityWorkplaceGenerator({ payload, meta }: any) {
  try {
    yield call(workplaceApi.checkWorkplace, payload);
    yield getWorkplaceListGenerator();
    yield call(meta);
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getWorkplaceGenerator({ payload }: any) {
  try {
    yield put(setWorkplace(null));
    const result = yield call(workplaceApi.getWorkplaceDetail, payload);
    yield put(setWorkplace(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getWorkplaceOperatingTheatresGenerator() {
  try {
    const result = yield call(workplaceApi.getWorkplaceOperatingTheatres);
    yield put(setWorkplaceOperatingTheatres(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getWorkplaceRotationWorkplacesGenerator() {
  try {
    const result = yield call(workplaceApi.getRotationWorkplaces);
    yield put(SetWorkplaceRotationWorkplaces(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

export function* workplaceRootSagas() {
  return yield race([
    take(CANCEL_TASKS),
    all([
      throttle(100, GET_WORKPLACE_LIST, getWorkplaceListGenerator),
      throttle(100, CREATE_WORKPLACE, createWorkplaceGenerator),
      throttle(100, UPDATE_WORKPLACE, updateWorkplaceGenerator),
      throttle(100, REMOVE_WORKPLACE, removeWorkplaceGenerator),
      throttle(100, REORDER_WORKPLACE_LIST, reorderWorkplaceGenerator),
      throttle(
        500,
        TOGGLE_WORKPLACE_ACTIVITY_WORKPLACE,
        toggleWorkplaceActivityWorkplaceGenerator
      ),
      throttle(100, GET_WORKPLACE_DETAIL, getWorkplaceGenerator),
      throttle(
        100,
        GET_WORKPLACE_OPERATING_THEATRES,
        getWorkplaceOperatingTheatresGenerator
      ),
      throttle(
        100,
        GET_WORKPLACE_ROTATION_WORKPLACES,
        getWorkplaceRotationWorkplacesGenerator
      ),
    ]),
  ]);
}
