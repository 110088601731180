const AVAILABILITY_PREFIX = 'AVAILABILITY';

export const GET_AVAILABILITY_LIST = `${AVAILABILITY_PREFIX}/GET_AVAILABILITY_LIST`;
export const SET_AVAILABILITY_LIST = `${AVAILABILITY_PREFIX}/SET_AVAILABILITY_LIST`;
export const GET_PLAN_PARAMS = `${AVAILABILITY_PREFIX}/GET_PLAN_PARAMS`;
export const SET_PLAN_PARAMS = `${AVAILABILITY_PREFIX}/SET_PLAN_PARAMS`;
export const UPDATE_PLAN_PARAMS = `${AVAILABILITY_PREFIX}/UPDATE_PLAN_PARAMS`;
export const GET_AVAILABILITY_SUMMARY_INFO = `${AVAILABILITY_PREFIX}/GET_AVAILABILITY_SUMMARY_INFO`;
export const SET_AVAILABILITY_SUMMARY_INFO = `${AVAILABILITY_PREFIX}/SET_AVAILABILITY_SUMMARY_INFO`;

export interface AvailabilityState {
  availability: AvailabilityResponse[];
  parameters: PlanParametersResponse | null;
  summaryInfoTabs: SummaryInfoTabResponse[];
}

interface GetAvailabilityList {
  type: typeof GET_AVAILABILITY_LIST;
  payload: null;
}
interface SetAvailabilityList {
  type: typeof SET_AVAILABILITY_LIST;
  payload: AvailabilityResponse[];
}
interface GetPlanParamsRequest {
  type: typeof GET_PLAN_PARAMS;
  payload: number;
}
interface SetPlanParamsRequest {
  type: typeof SET_PLAN_PARAMS;
  payload: PlanParametersResponse;
}
interface UpdatePlanParamsRequest {
  type: typeof UPDATE_PLAN_PARAMS;
  payload: PlanParametersRequest;
  meta: any;
}
interface GetAvailabilitySummaryInfo {
  type: typeof GET_AVAILABILITY_SUMMARY_INFO;
  payload: AvailabilitySummaryInfoRequest;
}
interface SetAvailabilitySummaryInfo {
  type: typeof SET_AVAILABILITY_SUMMARY_INFO;
  payload: SummaryInfoTabResponse[];
}

export type AvailabilityActionTypes =
  | GetAvailabilityList
  | SetAvailabilityList
  | GetPlanParamsRequest
  | SetPlanParamsRequest
  | UpdatePlanParamsRequest
  | GetAvailabilitySummaryInfo
  | SetAvailabilitySummaryInfo;
