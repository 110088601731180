import {
  RosterTemplateState,
  RosterTemplateActionTypes,
  SET_ROSTER_TEMPLATE_LIST,
  SET_ROSTER_TEMPLATE,
  SET_TEMPLATE_TABLE,
  SET_ROSTER_SUMMARY_INFO,
  SET_ROSTER_TEMPLATE_SUMMARY_INFO,
} from './types';

const initialState: RosterTemplateState = {
  templates: [],
  template: null,
  reports: [],
  templateTable: null,
  rosterSummaryInfoTabs: [],
  rosterTableSummaryInfoTabs: [],
};

export function rosterTemplateReducer(
  state = initialState,
  action: RosterTemplateActionTypes
): RosterTemplateState {
  switch (action.type) {
    case SET_ROSTER_TEMPLATE_LIST:
      return {
        ...state,
        templates: action.payload as BaseRosterTemplateResponse[],
      };
    case SET_ROSTER_TEMPLATE:
      return { ...state, template: action.payload as RosterTemplateResponse };
    case SET_TEMPLATE_TABLE:
      return {
        ...state,
        templateTable: action.payload as TemplateTableResponse,
      };
    case SET_ROSTER_SUMMARY_INFO:
      return {
        ...state,
        rosterSummaryInfoTabs: action.payload as SummaryInfoTabResponse[],
      };
    case SET_ROSTER_TEMPLATE_SUMMARY_INFO:
      return {
        ...state,
        rosterTableSummaryInfoTabs: action.payload as SummaryInfoTabResponse[],
      };
    default:
      return state;
  }
}
