import {
  REORDER_QUALIFICATIONS,
  QualificationsActionTypes,
  GET_QUALIFICATION_LIST,
  SET_QUALIFICATION_LIST,
  TOGGLE_QUALIFICATION_STATE,
} from './types';

export function reorderQualifications(
  payload: ReorderQualificationsRequest,
  meta: { onSuccess: () => void }
): QualificationsActionTypes {
  return {
    type: REORDER_QUALIFICATIONS,
    payload: payload,
    meta: meta,
  };
}

export function getQualifications(): QualificationsActionTypes {
  return {
    type: GET_QUALIFICATION_LIST,
    payload: null,
  };
}

export function setQualifications(
  payload: QualificationResponse[]
): QualificationsActionTypes {
  return {
    type: SET_QUALIFICATION_LIST,
    payload: payload,
  };
}

export function toggleQualificationState(
  payload: ToggleQualificationStateRequest,
  meta: () => void
): QualificationsActionTypes {
  return {
    type: TOGGLE_QUALIFICATION_STATE,
    payload: payload,
    meta: meta,
  };
}
