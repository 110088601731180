import {
  CREATE_PATHOLOGY_GROUP,
  REMOVE_PATHOLOGY_GROUP,
  REORDER_PATHOLOGY_GROUP_LIST,
  SET_PATHOLOGY_GROUP_LIST,
  UPDATE_PATHOLOGY_GROUP,
  PathologyGroupActionTypes,
  TOGGLE_PATHOLOGY_GROUP,
  GET_PATHOLOGY_GROUP,
  SET_PATHOLOGY_GROUP,
} from './types';

export function setPathologyGroupList(
  payload: PathologyGroupResponse[]
): PathologyGroupActionTypes {
  return {
    type: SET_PATHOLOGY_GROUP_LIST,
    payload: payload,
  };
}

export function getPathologyGroup(payload: number): PathologyGroupActionTypes {
  return {
    type: GET_PATHOLOGY_GROUP,
    payload: payload,
  };
}

export function setPathologyGroup(
  payload: PathologyGroupDetailResponse | null
): PathologyGroupActionTypes {
  return {
    type: SET_PATHOLOGY_GROUP,
    payload: payload,
  };
}

export function createPathologyGroup(
  payload: CreateUpdatePathologyGroupRequest,
  meta: any
): PathologyGroupActionTypes {
  return {
    type: CREATE_PATHOLOGY_GROUP,
    payload: payload,
    meta: meta,
  };
}

export function updatePathologyGroup(
  payload: CreateUpdatePathologyGroupRequest,
  meta: any
): PathologyGroupActionTypes {
  return {
    type: UPDATE_PATHOLOGY_GROUP,
    payload: payload,
    meta: meta,
  };
}

export function removePathologyGroup(
  payload: number
): PathologyGroupActionTypes {
  return {
    type: REMOVE_PATHOLOGY_GROUP,
    payload: payload,
  };
}

export function reorderPathologyGroups(
  payload: UpdatePathologyGroupsParentPositionRequest
): PathologyGroupActionTypes {
  return {
    type: REORDER_PATHOLOGY_GROUP_LIST,
    payload: payload,
  };
}

export function togglePathologyGroup(
  payload: CheckPathologyGroupRequest,
  meta: any
): PathologyGroupActionTypes {
  return {
    type: TOGGLE_PATHOLOGY_GROUP,
    payload: payload,
    meta: meta,
  };
}
