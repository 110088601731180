const AVAILABILITIES_PREFIX = 'AVAILABILITIES';

export const REORDER_AVAILABILITIES = `${AVAILABILITIES_PREFIX}/REORDER_AVAILABILITIES`;

interface ReorderAvailabilities {
  type: typeof REORDER_AVAILABILITIES;
  payload: ReorderAvailabilitiesRequest;
  meta: { onSuccess: () => void };
}

export type AvailabilitiesActionTypes = ReorderAvailabilities;
