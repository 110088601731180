import {
  all,
  call,
  put,
  select,
  throttle,
  race,
  take,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  GET_ROSTER_TEMPLATE_LIST,
  CREATE_ROSTER_TEMPLATE,
  UPDATE_ROSTER_TEMPLATE,
  REMOVE_ROSTER_TEMPLATE,
  GET_ROSTER_TEMPLATE,
  ADD_ROSTER_WORKPLACE,
  UPDATE_ROSTER_WORKPLACE,
  REMOVE_ROSTER_WORKPLACE,
  DUPLICATE_ROSTER_TEMPLATE,
  REORDER_ROSTER_TEMPLATE_WORKPLACE_POSITIONS,
  REORDER_ROSTER_TEMPLATE_POSITIONS,
  GET_TEMPLATE_TABLE,
  UPDATE_TEMPLATE_TABLE,
  GET_ROSTER_SUMMARY_INFO,
  GET_ROSTER_TEMPLATE_SUMMARY_INFO,
  REMOVE_ROSTER_WORKPLACES,
  ADD_ROSTER_WORKPLACES,
} from './types';

import * as rosterTemplateApi from '../../../api/modules/roster-template';
import * as rosterApi from '../../../api/modules/roster';
import {
  setRosterTemplate,
  setRosterTemplates,
  setTemplateTable,
  setRosterSummaryInfo,
  setRosterTemplateSummaryInfo,
} from './actions';
import mapValidationErrors from '../../../utilities/map-validation-errors';
import { RootState } from '../../index';
import { CANCEL_TASKS } from '../../common/types';
import generalErrorHandling from '../../../utilities/general-error-handling';

function* setRosterTemplatesGenerator() {
  try {
    const rosters = yield call(rosterTemplateApi.getRosterTemplates);
    yield put(setRosterTemplates(rosters));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* createRosterTemplateGenerator({ payload, meta }: any) {
  const { setErrors, setSubmitting } = meta;
  try {
    payload.days = payload.days ? payload.days : null;
    const roster = yield call(rosterTemplateApi.createRosterTemplate, payload);
    yield put(setRosterTemplate(roster));
    yield setRosterTemplatesGenerator();
  } catch (err) {
    if (err && err.length) {
      const errors: any = mapValidationErrors(err);
      yield call(setErrors, errors);
    } else {
      yield call(toast.error, generalErrorHandling(err));
    }
  }
  yield call(setSubmitting, false);
}

function* duplicateRosterTemplate({ payload }: any) {
  const templateId = payload as number;
  try {
    const roster = yield call(
      rosterTemplateApi.duplicateRosterTemplate,
      templateId
    );
    yield put(setRosterTemplate(roster));
    yield setRosterTemplatesGenerator();
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* updateRosterTemplateGenerator({ payload, meta }: any) {
  const { setErrors, setSubmitting } = meta;
  try {
    payload.days = payload.days ? payload.days : null;
    const roster = yield call(rosterTemplateApi.updateRosterTemplate, payload);
    yield put(setRosterTemplate(roster));
    yield setRosterTemplatesGenerator();
  } catch (err) {
    if (err && err.length) {
      const errors: any = mapValidationErrors(err);
      yield call(setErrors, errors);
    } else {
      yield call(toast.error, generalErrorHandling(err));
    }
  }
  yield call(setSubmitting, false);
}

function* removeRosterTemplateGenerator({ payload }: any) {
  try {
    yield call(rosterTemplateApi.removeRosterTemplate, payload);
    yield setRosterTemplatesGenerator();
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getRosterTemplateGenerator({ payload }: any) {
  try {
    const roster = yield call(
      rosterTemplateApi.getRosterTemplateDetail,
      payload
    );
    yield put(setRosterTemplate({ ...roster, days: roster.days ?? '' }));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* addWorkplaceToRoster({ payload }: any) {
  try {
    yield call(rosterTemplateApi.addRosterTemplateWorkplace, payload);
    yield getRosterTemplateGenerator({ payload: payload.templateId });
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* addWorkplacesToRoster({ payload }: any) {
  try {
    yield call(rosterTemplateApi.addRosterTemplateWorkplaces, payload);
    yield getRosterTemplateGenerator({ payload: payload.templateId });
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* updateRosterWorkplaceGenerator({ payload, meta }: any) {
  const { setErrors, setSubmitting, closeModal } = meta;
  try {
    yield call(rosterTemplateApi.updateRosterTemplateWorkplace, payload);
    yield getRosterTemplateGenerator({ payload: payload.templateId });
    yield call(closeModal);
  } catch (err) {
    if (err && err.length) {
      const errors: any = mapValidationErrors(err);
      yield call(setErrors, errors);
    } else {
      yield call(toast.error, generalErrorHandling(err));
    }
  }
  yield call(setSubmitting, false);
}

function* removeRosterWorkplaceGenerator({ payload }: any) {
  try {
    yield call(
      rosterTemplateApi.removeRosterTemplateWorkplace,
      payload.templateId,
      payload.id
    );
    yield getRosterTemplateGenerator({ payload: payload.templateId });
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}
function* removeRosterWorkplacesGenerator({ payload }: any) {
  try {
    yield call(
      rosterTemplateApi.removeRosterTemplateWorkplaces,
      payload.templateId,
      payload.workplaces
    );
    yield getRosterTemplateGenerator({ payload: payload.templateId });
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}
function* reorderRosterTemplateWorkplacePositionGenerator({ payload }: any) {
  const getRosterTemplateGetter = (state: RootState) =>
    state.admin.rosterTemplate.template;
  try {
    const response = yield call(
      rosterTemplateApi.updateRosterTemplateWorkplacePositions,
      payload
    );
    const rosterTemplate = yield select(getRosterTemplateGetter);
    yield put(
      setRosterTemplate({
        ...rosterTemplate,
        workplaces: response.workplaces,
      })
    );
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* reorderRosterTemplatePositionGenerator({ payload }: any) {
  try {
    yield call(rosterTemplateApi.updateRosterTemplatePositions, payload);
    yield setRosterTemplatesGenerator();
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getTemplateTableGenerator({ payload }: any) {
  try {
    const result = yield call(rosterTemplateApi.getTemplateTable, payload);
    yield put(setTemplateTable(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* updateTemplateTableCellGenerator({ payload }: any) {
  try {
    const result = yield call(
      rosterTemplateApi.updateTemplateTableCell,
      payload
    );
    yield put(setTemplateTable(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getRosterSummaryInfoGenerator({ payload }: any) {
  try {
    const result = yield call(rosterApi.getRosterSummaryInfo, payload);
    yield put(setRosterSummaryInfo(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}
function* getRosterTemplateSummaryInfoGenerator({ payload }: any) {
  try {
    const result = yield call(
      rosterTemplateApi.getRosterTemplateSummaryInfo,
      payload
    );
    yield put(setRosterTemplateSummaryInfo(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

export function* rosterTemplateRootSagas() {
  return yield race([
    take(CANCEL_TASKS),
    all([
      throttle(100, GET_ROSTER_TEMPLATE_LIST, setRosterTemplatesGenerator),
      throttle(100, CREATE_ROSTER_TEMPLATE, createRosterTemplateGenerator),
      throttle(100, DUPLICATE_ROSTER_TEMPLATE, duplicateRosterTemplate),
      throttle(100, UPDATE_ROSTER_TEMPLATE, updateRosterTemplateGenerator),
      throttle(100, REMOVE_ROSTER_TEMPLATE, removeRosterTemplateGenerator),
      throttle(100, GET_ROSTER_TEMPLATE, getRosterTemplateGenerator),
      throttle(100, ADD_ROSTER_WORKPLACE, addWorkplaceToRoster),
      throttle(100, ADD_ROSTER_WORKPLACES, addWorkplacesToRoster),
      throttle(100, UPDATE_ROSTER_WORKPLACE, updateRosterWorkplaceGenerator),
      throttle(100, REMOVE_ROSTER_WORKPLACE, removeRosterWorkplaceGenerator),
      throttle(100, REMOVE_ROSTER_WORKPLACES, removeRosterWorkplacesGenerator),
      throttle(
        100,
        REORDER_ROSTER_TEMPLATE_WORKPLACE_POSITIONS,
        reorderRosterTemplateWorkplacePositionGenerator
      ),
      throttle(
        100,
        REORDER_ROSTER_TEMPLATE_POSITIONS,
        reorderRosterTemplatePositionGenerator
      ),
      throttle(100, GET_TEMPLATE_TABLE, getTemplateTableGenerator),
      throttle(100, UPDATE_TEMPLATE_TABLE, updateTemplateTableCellGenerator),
      throttle(100, GET_ROSTER_SUMMARY_INFO, getRosterSummaryInfoGenerator),
      throttle(
        100,
        GET_ROSTER_TEMPLATE_SUMMARY_INFO,
        getRosterTemplateSummaryInfoGenerator
      ),
    ]),
  ]);
}
