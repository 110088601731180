import api from '../index';

const EMPLOYEE_BASE_URL = 'api/v1/Employee';

export async function getEmployees(
  payload: EmployeeRequest
): Promise<UserEmployeeResponse> {
  const result = await api.get<UserEmployeeResponse>(EMPLOYEE_BASE_URL, {
    params: payload,
  });
  return result.data;
}
export async function getEmployeesWithIndicators(
  payload: EmployeeIndicatorRequest
): Promise<EmployeeWithIndicatorResponse> {
  const result = await api.get<EmployeeWithIndicatorResponse>(
    `${EMPLOYEE_BASE_URL}/Indicator/${payload.templateId}/${payload.start}/${payload.days}`,
    { params: payload }
  );
  return result.data;
}

export async function getEmployeesOperationsWithIndicators(
  payload: EmployeeIndicatorOperationsRequest
): Promise<EmployeeWithIndicatorResponse> {
  const result = await api.get<EmployeeWithIndicatorResponse>(
    `${EMPLOYEE_BASE_URL}/Indicator/${payload.start}/${payload.days}`,
    { params: payload }
  );
  return result.data;
}

export async function createUserEmployee(
  payload: CreateUpdateUserEmployeeRequest
): Promise<UserEmployeeResponse> {
  const result = await api.post<UserEmployeeResponse>(
    `${EMPLOYEE_BASE_URL}`,
    payload
  );
  return result.data;
}

export async function updateUserEmployee(
  payload: CreateUpdateUserEmployeeRequest
): Promise<UserEmployeeResponse> {
  const result = await api.put<UserEmployeeResponse>(
    `${EMPLOYEE_BASE_URL}/${payload.userId}`,
    payload
  );
  return result.data;
}

export async function removeUserEmployee(userId: string) {
  throw new Error('removeUserEmployee is not implemented');
}

export async function getEmployeeFromUserName(
  userName: string
): Promise<UserEmployeeResponse> {
  const result = await api.get<UserEmployeeResponse>(
    `${EMPLOYEE_BASE_URL}/CheckUserName/${userName}`
  );
  return result.data;
}

export async function removePassword(userId: string) {
  const result = await api.put<boolean>(
    `${EMPLOYEE_BASE_URL}/RemovePassword/${userId}`,
    {
      userId,
    }
  );
  return result.data;
}

export async function checkEmployeeEmployerId(
  employeeEmployerId: string
): Promise<UserEmployeeDetailsResponse> {
  const result = await api.get<UserEmployeeDetailsResponse>(
    `${EMPLOYEE_BASE_URL}/CheckEmployeeEmployerId/${employeeEmployerId}`
  );
  return result.data;
}

export async function checkDoctorNationalId(
  doctorNationalId: string
): Promise<UserEmployeeDetailsResponse> {
  const result = await api.get<UserEmployeeDetailsResponse>(
    `${EMPLOYEE_BASE_URL}/CheckDoctorNationalId/${doctorNationalId}`
  );
  return result.data;
}

export async function getLanguages() {
  const result = await api.get<string[]>(`${EMPLOYEE_BASE_URL}/Languages`);
  return result.data;
}

export async function reorderEmployee(payload: ReorderEmployeeRequest) {
  const result = await api.put(
    `${EMPLOYEE_BASE_URL}/Position/${payload.userName}`,
    payload
  );
  return result.data;
}
export async function searchEmployee(
  searchText: string
): Promise<EmployeeShortResponse> {
  const result = await api.get<EmployeeShortResponse>(
    `${EMPLOYEE_BASE_URL}/UserName/${searchText}`
  );
  return result.data;
}
export async function getEmployeeWorkplacesTable(
  payload: string
): Promise<EmployeeWorkplaceTableResponse> {
  const result = await api.get(`${EMPLOYEE_BASE_URL}/EmployeeWorkplaces`, {
    params: { searchString: payload },
  });
  return result.data;
}
export async function markEmployeeWorkplace(
  payload: EmployeeWorkplaceMarkRequest
): Promise<EmployeeWorkplaceTableResponse> {
  const result = await api.put(
    `${EMPLOYEE_BASE_URL}/SetEmployeeWorkplace`,
    payload
  );
  return result.data;
}

export async function availableEmployees(
  payload: AvailableEmployeesRequest
): Promise<AvailableEmployeesResponse[]> {
  const result = await api.get<AvailableEmployeesResponse[]>(
    `${EMPLOYEE_BASE_URL}/AvailableEmployees`,
    { params: payload }
  );
  return result.data;
}
