import { toast } from 'react-toastify';
import {
  all,
  call,
  put,
  select,
  throttle,
  race,
  take,
} from 'redux-saga/effects';
import mapValidationErrors from '../../../utilities/map-validation-errors';
import {
  setLanguages,
  setUser,
  setUserEmployees,
  setUserEmployeesIndicators,
  setAvailableEmployees,
} from './actions';
import {
  CREATE_USER,
  GET_LANGUAGES,
  GET_USER,
  GET_USER_EMPLOYEES,
  GET_USER_BY_DOCTOR_ID,
  GET_USER_BY_EMPLOYEE_ID,
  REMOVE_USER,
  REMOVE_USER_PASSWORD,
  REORDER_EMPLOYEE,
  UPDATE_USER,
  GET_USER_EMPLOYEES_INDICATORS,
  GET_USER_EMPLOYEES_OPERATIONS_INDICATORS,
  GET_AVAILABLE_EMPLOYEES,
} from './types';
import * as employeeApi from '../../../api/modules/employee';
import { RootState } from '../../index';
import { CANCEL_TASKS } from '../../common/types';
import generalErrorHandling from '../../../utilities/general-error-handling';

function* setUserEmployeesGenerator({ payload }: any) {
  try {
    const result = yield call(employeeApi.getEmployees, payload);
    yield put(setUserEmployees(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* createUserGenerator({ payload, meta }: any) {
  const { resetForm, setErrors, setSubmitting, closeModal } = meta;
  try {
    yield call(employeeApi.createUserEmployee, payload);
    yield call(resetForm);
    yield call(closeModal);
  } catch (err) {
    if (err && err.length) {
      const errors: any = mapValidationErrors(err);
      yield call(setErrors, errors);
    } else {
      yield call(toast.error, generalErrorHandling(err));
    }
  }
  yield call(setSubmitting, false);
}

function* updateUserGenerator({ payload, meta }: any) {
  const { setErrors, setSubmitting, closeModal } = meta;
  try {
    yield call(employeeApi.updateUserEmployee, payload);
    yield call(closeModal);
  } catch (err) {
    if (err && err.length) {
      const errors: any = mapValidationErrors(err);
      yield call(setErrors, errors);
    } else {
      yield call(toast.error, generalErrorHandling(err));
    }
  }
  yield call(setSubmitting, false);
}

function* removeUserGenerator({ payload }: any) {
  try {
    yield call(employeeApi.removeUserEmployee, payload);
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getUserGenerator({ payload }: any) {
  try {
    const result = yield call(employeeApi.getEmployeeFromUserName, payload);
    if (result) {
      const getUser = (state: RootState) =>
        state.admin.userManager.selectedUser;
      const user = yield select(getUser);
      yield put(setUser({ ...user, ...result }));
    }
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getUserByEmployeeIdGenerator({ payload }: any) {
  try {
    const result = yield call(employeeApi.checkEmployeeEmployerId, payload);
    if (result) {
      const getUser = (state: RootState) =>
        state.admin.userManager.selectedUser;
      const user = yield select(getUser);
      yield put(setUser({ ...user, ...result }));
    }
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getUserByDoctorIdGenerator({ payload }: any) {
  try {
    const result = yield call(employeeApi.checkDoctorNationalId, payload);
    if (result) {
      const getUser = (state: RootState) =>
        state.admin.userManager.selectedUser;
      const user = yield select(getUser);
      yield put(setUser({ ...user, ...result }));
    }
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getLanguageGenerator() {
  try {
    const result = yield call(employeeApi.getLanguages);
    yield put(setLanguages(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* removeUserPasswordGenerator({ payload }: any) {
  try {
    const result = yield call(employeeApi.removePassword, payload);
    if (result) yield call(toast.success, 'Password removed');
    else yield call(toast.error, `Password can't be removed`);
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* reorderEmployeeGenerator({ payload, meta }: any) {
  const { onSuccess } = meta;
  try {
    yield call(employeeApi.reorderEmployee, payload);
    yield call(onSuccess);
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getEmployeeIndicatorsGenerator({ payload }: any) {
  try {
    const indicators = yield call(
      employeeApi.getEmployeesWithIndicators,
      payload
    );
    yield put(setUserEmployeesIndicators(indicators));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getEmployeeOperationsIndicatorsGenerator({ payload }: any) {
  try {
    const indicators = yield call(
      employeeApi.getEmployeesOperationsWithIndicators,
      payload
    );
    yield put(setUserEmployeesIndicators(indicators));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getAvailableEmployeesGenerator({ payload }: any) {
  try {
    const availability = yield call(employeeApi.availableEmployees, payload);
    yield put(setAvailableEmployees(availability));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

export function* userManagerRootSagas() {
  return yield race([
    take(CANCEL_TASKS),
    all([
      throttle(100, GET_USER_EMPLOYEES, setUserEmployeesGenerator),
      throttle(100, CREATE_USER, createUserGenerator),
      throttle(100, UPDATE_USER, updateUserGenerator),
      throttle(100, REMOVE_USER, removeUserGenerator),
      throttle(100, GET_USER, getUserGenerator),
      throttle(100, GET_USER_BY_EMPLOYEE_ID, getUserByEmployeeIdGenerator),
      throttle(100, GET_USER_BY_DOCTOR_ID, getUserByDoctorIdGenerator),
      throttle(100, GET_LANGUAGES, getLanguageGenerator),
      throttle(100, REMOVE_USER_PASSWORD, removeUserPasswordGenerator),
      throttle(100, REORDER_EMPLOYEE, reorderEmployeeGenerator),
      throttle(
        100,
        GET_USER_EMPLOYEES_INDICATORS,
        getEmployeeIndicatorsGenerator
      ),
      throttle(
        100,
        GET_USER_EMPLOYEES_OPERATIONS_INDICATORS,
        getEmployeeOperationsIndicatorsGenerator
      ),
      throttle(100, GET_AVAILABLE_EMPLOYEES, getAvailableEmployeesGenerator),
    ]),
  ]);
}
