const COMMON_PREFIX = 'COMMON';

export const GET_HOLIDAYS = `${COMMON_PREFIX}/GET_HOLIDAYS`;
export const SET_HOLIDAYS = `${COMMON_PREFIX}/SET_HOLIDAYS`;
export const SET_LOADING = `${COMMON_PREFIX}/SET_LOADING`;
export const CANCEL_TASKS = `${COMMON_PREFIX}/CANCEL_TASKS`;

export interface CommonState {
  holidays: HolidayResponse[];
  isLoading: boolean;
}

interface GetHolidays {
  type: typeof GET_HOLIDAYS;
  payload: number;
}
interface SetHolidays {
  type: typeof SET_HOLIDAYS;
  payload: HolidayResponse[];
}

interface SetLoading {
  type: typeof SET_LOADING;
  payload: boolean;
}

interface CancelTasks {
  type: typeof CANCEL_TASKS;
  payload: null;
}

export type CommonActionTypes =
  | GetHolidays
  | SetHolidays
  | SetLoading
  | CancelTasks;
