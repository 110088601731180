import { all, call, put, throttle, race, take } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  GET_DOCTOR_QUALIFICATIONS,
  UPDATE_DOCTOR_QUALIFICATIONS,
} from './types';
import * as doctorQualificationsApi from '../../../api/modules/doctor-qualifications';
import { setDoctorQualifications } from './actions';
import { CANCEL_TASKS } from '../../common/types';
import generalErrorHandling from '../../../utilities/general-error-handling';

function* getDoctorQualificationsGenerator() {
  try {
    const result = yield call(doctorQualificationsApi.getDoctorQualifications);
    yield put(setDoctorQualifications(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* updateDoctorQualificationsGenerator({ payload }: any) {
  try {
    const result = yield call(
      doctorQualificationsApi.updateDoctorQualifications,
      payload
    );

    yield put(setDoctorQualifications(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

export function* doctorQualificationRootSagas() {
  return yield race([
    take(CANCEL_TASKS),
    all([
      throttle(
        100,
        GET_DOCTOR_QUALIFICATIONS,
        getDoctorQualificationsGenerator
      ),
      throttle(
        100,
        UPDATE_DOCTOR_QUALIFICATIONS,
        updateDoctorQualificationsGenerator
      ),
    ]),
  ]);
}
