const USER_PREFIX = 'USER';
export const SET_USER_TOKEN = `${USER_PREFIX}/SET_USER_TOKEN`;
export const GET_DEPARTMENTS = `${USER_PREFIX}/GET_DEPARTMENTS`;
export const SET_DEPARTMENTS = `${USER_PREFIX}/SET_DEPARTMENTS`;
export const SET_USER_DEPARTMENT = `${USER_PREFIX}/SET_USER_DEPARTMENT`;
export const SET_USER_LANGUAGE = `${USER_PREFIX}/SET_USER_LANGUAGE`;

export interface UserState {
  language: string;
  token: string;
  department: string;
  departments: DepartmentResponse[];
}

interface SetUserTokenAction {
  type: typeof SET_USER_TOKEN;
  payload: string;
}
interface GetDepartments {
  type: typeof GET_DEPARTMENTS;
  payload: null;
}
interface SetDepartments {
  type: typeof SET_DEPARTMENTS;
  payload: DepartmentResponse[];
}
interface SetUserDepartment {
  type: typeof SET_USER_DEPARTMENT;
  payload: string;
}
interface SetUserLanguage {
  type: typeof SET_USER_LANGUAGE;
  payload: string;
}

export type UserActionTypes =
  | GetDepartments
  | SetUserTokenAction
  | SetDepartments
  | SetUserDepartment
  | SetUserLanguage;
