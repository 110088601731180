import { call, all, put, throttle, race, take } from 'redux-saga/effects';
import * as attachmentApi from '../../api/modules/attachments';
import { toast } from 'react-toastify';
import { CANCEL_TASKS } from '../common/types';
import generalErrorHandling from '../../utilities/general-error-handling';
import { setAttachmentFiles, setAttachmentFile } from './actions';
import {
  GET_ATTACHMENT_FILES,
  UPLOAD_ATTACHMENT,
  REMOVE_ATTACHMENT,
} from './types';

function* getAttachmentFilesGenerator({ payload }: any) {
  try {
    const result = yield call(attachmentApi.getAttachmentFiles, payload);
    yield put(setAttachmentFiles(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* uploadAttachmentGenerator({ payload }: any) {
  try {
    const result = yield call(attachmentApi.uploadAttachment, payload);
    yield put(setAttachmentFile(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* removeAttachmentGenerator({ payload }: any) {
  try {
    const result = yield call(attachmentApi.deleteAttachment, payload);
    console.log(result);
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

export function* attachmentRootSagas() {
  return yield race([
    take(CANCEL_TASKS),
    all([
      throttle(100, GET_ATTACHMENT_FILES, getAttachmentFilesGenerator),
      throttle(100, UPLOAD_ATTACHMENT, uploadAttachmentGenerator),
      throttle(100, REMOVE_ATTACHMENT, removeAttachmentGenerator),
    ]),
  ]);
}
