import { applyMiddleware, createStore, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { loadUser } from 'redux-oidc';
import { composeWithDevTools } from 'redux-devtools-extension';

import { rootReducer, RootState } from '../store/index';
import { rootSagas } from '../store/index';
import userManager from './user-manager';

export default function configureStore(preloadedState?: RootState): Store {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    rootReducer,
    preloadedState,
    composeWithDevTools(applyMiddleware(sagaMiddleware))
  );
  sagaMiddleware.run(rootSagas);
  loadUser(store, userManager);
  return store;
}
