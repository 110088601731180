import api from '../index';

const DOCTOR_QUALIFICATIONS_BASE_URL = 'api/v1/Employee';

export async function getDoctorQualifications(): Promise<DoctorQualificationsResponse> {
  const result = await api.get<DoctorQualificationsResponse>(
    `${DOCTOR_QUALIFICATIONS_BASE_URL}/EmployeesQualifications`
  );
  return result.data;
}

export async function updateDoctorQualifications(
  payload: UpdateDoctorQualificationsRequest
): Promise<DoctorQualificationsResponse> {
  const result = await api.put<DoctorQualificationsResponse>(
    `${DOCTOR_QUALIFICATIONS_BASE_URL}/${payload.userName}/SetQualification/${payload.id}`,
    payload
  );
  return result.data;
}
