import api from '../index';

const ROSTER_BASE_URL = '/api/v1/Roster';

export async function getRosters(): Promise<BaseRosterTemplateResponse[]> {
  const result = await api.get<BaseRosterTemplateResponse[]>(
    `${ROSTER_BASE_URL}/Templates`
  );
  return result.data;
}

export async function getPlans(): Promise<PlanResponse[]> {
  const result = await api.get<PlanResponse[]>(`${ROSTER_BASE_URL}/Plans`);
  return result.data;
}

export async function getRoster(
  payload: RosterRequest
): Promise<TemplateTableResponse> {
  const url = `${ROSTER_BASE_URL}/Table/${payload.templateId}/${payload.startDate}/${payload.days}`;
  const result = await api.get<TemplateTableResponse>(url, { params: payload });
  return result.data;
}

export async function updateRosterCell(
  payload: UpdateRosterCellRequest
): Promise<TemplateTableResponse> {
  const result = await api.put<TemplateTableResponse>(
    `${ROSTER_BASE_URL}/Cell/${payload.templateId}`,
    payload
  );
  return result.data;
}

export async function updateRosterCells(
  payload: UpdateRosterCellsRequest
): Promise<TemplateTableResponse> {
  const result = await api.put<TemplateTableResponse>(
    `${ROSTER_BASE_URL}/Cells/${payload.templateId}`,
    payload
  );
  return result.data;
}

export async function getSignedRosterVersions(
  payload: VersionRequest
): Promise<RosterVersionResponse[]> {
  const result = await api.get<RosterVersionResponse[]>(
    `${ROSTER_BASE_URL}/Versions/${payload.templateId}/${payload.planId}`
  );
  return result.data;
}

export async function getSignedRosterVersion(
  payload: GetRosterVersionRequest
): Promise<TemplateTableResponse> {
  const result = await api.get<TemplateTableResponse>(
    `${ROSTER_BASE_URL}/Version/${payload.rosterVersion}`,
    { params: payload }
  );
  return result.data;
}

export async function signRosterVersion(
  payload: SignRosterTemplateRequest
): Promise<TemplateTableResponse> {
  const result = await api.put<TemplateTableResponse>(
    `${ROSTER_BASE_URL}/Sign/${payload.templateId}`,
    payload
  );
  return result.data;
}

export async function resetRoster(
  payload: ResetRosterTemplateRequest
): Promise<TemplateTableResponse> {
  const result = await api.put<TemplateTableResponse>(
    `${ROSTER_BASE_URL}/Reset/${payload.templateId}`,
    payload
  );
  return result.data;
}

export async function applyTemplateCells(
  payload: ApplyTemplateCellsRequest
): Promise<TemplateTableResponse> {
  const result = await api.put(
    `${ROSTER_BASE_URL}/ApplyTemplateCells/${payload.templateId}`,
    payload
  );
  return result.data;
}

export async function getRosterSummaryInfo(
  payload: RosterSummaryInfoRequest
): Promise<SummaryInfoTabResponse[]> {
  const result = await api.get<SummaryInfoTabResponse[]>(
    `${ROSTER_BASE_URL}/SummaryInfo/${payload.templateId}/${payload.startDate}/${payload.days}`,
    { params: payload }
  );
  return result.data;
}

export async function getSignReasons(): Promise<string[]> {
  const result = await api.get<string[]>(`${ROSTER_BASE_URL}/SignedReasons`);
  return result.data;
}

export async function getVersionNotes(
  payload: VersionNotesRequest
): Promise<string> {
  const result = await api.get<string>(
    `${ROSTER_BASE_URL}/VersionNotes/${payload.templatedId}/${payload.planId}`
  );
  return result.data;
}
