import {
  AvailabilityActionTypes,
  GET_AVAILABILITY_LIST,
  SET_AVAILABILITY_LIST,
  GET_PLAN_PARAMS,
  SET_PLAN_PARAMS,
  UPDATE_PLAN_PARAMS,
  GET_AVAILABILITY_SUMMARY_INFO,
  SET_AVAILABILITY_SUMMARY_INFO,
} from './types';

export function getAvailability(): AvailabilityActionTypes {
  return {
    type: GET_AVAILABILITY_LIST,
    payload: null,
  };
}

export function setAvailability(
  payload: AvailabilityResponse[]
): AvailabilityActionTypes {
  return {
    type: SET_AVAILABILITY_LIST,
    payload: payload,
  };
}
export function getPlanParams(payload: number): AvailabilityActionTypes {
  return {
    type: GET_PLAN_PARAMS,
    payload: payload,
  };
}
export function setPlanParams(
  payload: PlanParametersResponse
): AvailabilityActionTypes {
  return {
    type: SET_PLAN_PARAMS,
    payload: payload,
  };
}
export function updatePlanParams(
  payload: PlanParametersRequest,
  meta: any
): AvailabilityActionTypes {
  return { type: UPDATE_PLAN_PARAMS, payload, meta };
}
export function getAvailabilitySummaryInfo(
  payload: AvailabilitySummaryInfoRequest
): AvailabilityActionTypes {
  return {
    type: GET_AVAILABILITY_SUMMARY_INFO,
    payload,
  };
}
export function setAvailabilitySummaryInfo(
  payload: SummaryInfoTabResponse[]
): AvailabilityActionTypes {
  return {
    type: SET_AVAILABILITY_SUMMARY_INFO,
    payload,
  };
}
