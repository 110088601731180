const OPERATIONS_PREFIX = 'OPERATIONS';

export const GET_PATIENT_BY_ID = `${OPERATIONS_PREFIX}/GET_PATIENT_BY_ID`;
export const SET_PATIENT_BY_ID = `${OPERATIONS_PREFIX}/SET_PATIENT_BY_ID`;

export const GET_OPERATION_BY_CASE_ID = `${OPERATIONS_PREFIX}/GET_OPERATION_BY_CASE_ID`;
export const SET_OPERATION_BY_CASE_ID = `${OPERATIONS_PREFIX}/SET_OPERATION_BY_CASE_ID`;

export const GET_AUTOCOMPLETE_VALUES = `${OPERATIONS_PREFIX}/GET_AUTOCOMPLETE_VALUES`;
export const SET_AUTOCOMPLETE_VALUES = `${OPERATIONS_PREFIX}/SET_AUTOCOMPLETE_VALUES`;

export const CREATE_PATIENT = `${OPERATIONS_PREFIX}/CREATE_PATIENT`;
export const UPDATE_PATIENT = `${OPERATIONS_PREFIX}/UPDATE_PATIENT`;
export const REMOVE_PATIENT = `${OPERATIONS_PREFIX}/REMOVE_PATIENT`;

export const UPDATE_CASE = `${OPERATIONS_PREFIX}/UPDATE_CASE`;
export const INFORM_PATIENT = `${OPERATIONS_PREFIX}/INFORM_PATIENT`;

export const UPDATE_SOAP = `${OPERATIONS_PREFIX}/UPDATE_SOAP`;

export const UPDATE_OPERATION_REPORT = `${OPERATIONS_PREFIX}/UPDATE_OPERATION_REPORT`;

export const ADD_DIAGNOSE_TO_SURGERY = `${OPERATIONS_PREFIX}/ADD_DIAGNOSE_TO_SURGERY`;
export const UPDATE_DIAGNOSE_FROM_SURGERY = `${OPERATIONS_PREFIX}/UPDATE_DIAGNOSE_FROM_SURGERY`;
export const REMOVE_DIAGNOSE_FROM_SURGERY = `${OPERATIONS_PREFIX}/REMOVE_DIAGNOSE_FROM_SURGERY`;

export const ADD_PROCEDURE_TO_SURGERY = `${OPERATIONS_PREFIX}/ADD_PROCEDURE_TO_SURGERY`;
export const UPDATE_PROCEDURE_FROM_SURGERY = `${OPERATIONS_PREFIX}/UPDATE_PROCEDURE_FROM_SURGERY`;
export const REMOVE_PROCEDURE_FROM_SURGERY = `${OPERATIONS_PREFIX}/REMOVE_PROCEDURE_FROM_SURGERY`;

export const SET_WIZARD_STEP = `${OPERATIONS_PREFIX}/SET_WIZARD_STEP`;

export const GET_OPERATION_READY_PATIENTS = `${OPERATIONS_PREFIX}/GET_OPERATION_READY_PATIENTS`;
export const SET_OPERATION_READY_PATIENTS = `${OPERATIONS_PREFIX}/SET_OPERATION_READY_PATIENTS`;

export const SET_OPERATION_TEAM = `${OPERATIONS_PREFIX}/SET_OPERATION_TEAM`;
export const REORDER_APPOINTMENTS_FROM_START = `${OPERATIONS_PREFIX}/REORDER_APPOINTMENTS_FROM_START`;
export const REORDER_APPOINTMENTS_SEQUENTIALLY = `${OPERATIONS_PREFIX}/REORDER_APPOINTMENTS_SEQUENTIALLY`;
export const SET_APPOINTMENT_STATUS = `${OPERATIONS_PREFIX}/SET_APPOINTMENT_STATUS`;

export const GET_CASE_EMPLOYEE = `${OPERATIONS_PREFIX}/GET_CASE_EMPLOYEE`;
export const SET_CASE_EMPLOYEE = `${OPERATIONS_PREFIX}/SET_CASE_EMPLOYEE`;

export const GET_OPERATION_SUMMARY_INFO = `${OPERATIONS_PREFIX}/GET_OPERATION_SUMMARY_INFO`;
export const SET_OPERATION_SUMMARY_INFO = `${OPERATIONS_PREFIX}/SET_OPERATION_SUMMARY_INFO`;

export interface OperationsState {
  operation: OperationResponse | null;
  autoCompleteValue: OperationAutocompleteResponse | null;
  step: number;
  operationReadyPatients: OperationReadyPatientResponse[];
  summaryInfo: SummaryInfoTabResponse[];
}

interface GetPatient {
  type: typeof GET_PATIENT_BY_ID;
  payload: string;
}
interface SetPatient {
  type: typeof SET_PATIENT_BY_ID;
  payload: PatientResponse;
}
interface GetOperation {
  type: typeof GET_OPERATION_BY_CASE_ID;
  payload: number;
}
interface SetOperation {
  type: typeof GET_OPERATION_BY_CASE_ID;
  payload: OperationResponse;
}
interface GetAutocompleteValue {
  type: typeof GET_AUTOCOMPLETE_VALUES;
  payload: null;
}
interface SetAutocompleteValue {
  type: typeof GET_AUTOCOMPLETE_VALUES;
  payload: OperationAutocompleteResponse;
}
interface CreatePatient {
  type: typeof CREATE_PATIENT;
  payload: CreateUpdatePatientRequest;
  meta: any;
}
interface UpdatePatient {
  type: typeof UPDATE_PATIENT;
  payload: CreateUpdatePatientRequest;
  meta: any;
}
interface RemovePatient {
  type: typeof REMOVE_PATIENT;
  payload: number;
}
interface UpdateCase {
  type: typeof UPDATE_CASE;
  payload: UpdateCaseRequest;
  meta: any;
}
interface InformPatient {
  type: typeof INFORM_PATIENT;
  payload: number;
}
interface UpdateSOAP {
  type: typeof UPDATE_SOAP;
  payload: UpdateSOAPRequest;
  meta: any;
}
interface UpdateOperationReport {
  type: typeof UPDATE_OPERATION_REPORT;
  payload: UpdateOperationReportRequest;
  meta: any;
}
interface AddDiagnoseToSurgery {
  type: typeof ADD_DIAGNOSE_TO_SURGERY;
  payload: AddDiagnoseToOperationRequest;
}
interface UpdateDiagnoseFromSurgery {
  type: typeof UPDATE_DIAGNOSE_FROM_SURGERY;
  payload: UpdateDiagnoseFromOperationRequest;
}
interface RemoveDiagnoseFromSurgery {
  type: typeof UPDATE_DIAGNOSE_FROM_SURGERY;
  payload: RemoveDiagnoseFromOperationRequest;
}
interface AddProcedureToSurgery {
  type: typeof ADD_PROCEDURE_TO_SURGERY;
  payload: AddProcedureToOperationRequest;
}
interface UpdateProcedureFromSurgery {
  type: typeof UPDATE_PROCEDURE_FROM_SURGERY;
  payload: UpdateProcedureFromOperationRequest;
}
interface RemoveProcedureFromSurgery {
  type: typeof UPDATE_DIAGNOSE_FROM_SURGERY;
  payload: RemoveProcedureFromOperationRequest;
}
interface SetWizardStep {
  type: typeof SET_WIZARD_STEP;
  payload: number;
}
interface GetOperationReadyPatients {
  type: typeof GET_OPERATION_READY_PATIENTS;
  payload: OperationReadyPatientsRequest;
}
interface SetOperationReadyPatients {
  type: typeof SET_OPERATION_READY_PATIENTS;
  payload: OperationReadyPatientResponse[];
}
interface SetOperationTeam {
  type: typeof SET_OPERATION_TEAM;
  payload: SetOperationTeamRequest;
  meta: any;
}
interface ReorderAppointmentFromStart {
  type: typeof REORDER_APPOINTMENTS_FROM_START;
  payload: ReorderRequest;
  meta: any;
}
interface ReorderAppointmentSequentially {
  type: typeof REORDER_APPOINTMENTS_SEQUENTIALLY;
  payload: ReorderRequest;
  meta: any;
}
interface SetAppointmentStatus {
  type: typeof SET_APPOINTMENT_STATUS;
  payload: SetStatusRequest;
  meta: any;
}
interface GetCaseEmployee {
  type: typeof GET_CASE_EMPLOYEE;
  payload: string;
}
interface SetCaseEmployee {
  type: typeof SET_CASE_EMPLOYEE;
  payload: EmployeeShortResponse;
}

interface GetOperationSummaryInfo {
  type: typeof GET_OPERATION_SUMMARY_INFO;
  payload: OperationSummaryInfoRequest;
}
interface SetOperatjonSummaryInfo {
  type: typeof SET_OPERATION_SUMMARY_INFO;
  payload: SummaryInfoTabResponse[];
}

export type OperationsActionTypes =
  | GetPatient
  | SetPatient
  | GetOperation
  | SetOperation
  | GetAutocompleteValue
  | SetAutocompleteValue
  | CreatePatient
  | UpdatePatient
  | RemovePatient
  | UpdateCase
  | InformPatient
  | UpdateSOAP
  | UpdateOperationReport
  | AddDiagnoseToSurgery
  | UpdateDiagnoseFromSurgery
  | RemoveDiagnoseFromSurgery
  | AddProcedureToSurgery
  | UpdateProcedureFromSurgery
  | RemoveProcedureFromSurgery
  | SetWizardStep
  | GetOperationReadyPatients
  | SetOperationReadyPatients
  | SetOperationTeam
  | ReorderAppointmentFromStart
  | ReorderAppointmentSequentially
  | SetAppointmentStatus
  | GetCaseEmployee
  | SetCaseEmployee
  | GetOperationSummaryInfo
  | SetOperatjonSummaryInfo;
