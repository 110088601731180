const USER_MANAGER_PREFIX = 'USER_MANAGER';

export const GET_USER_EMPLOYEES = `${USER_MANAGER_PREFIX}/GET_USER_EMPLOYEES`;
export const SET_USER_EMPLOYEES = `${USER_MANAGER_PREFIX}/SET_USER_EMPLOYEES`;
export const CREATE_USER = `${USER_MANAGER_PREFIX}/CREATE_USER`;
export const UPDATE_USER = `${USER_MANAGER_PREFIX}/UPDATE_USER`;
export const REMOVE_USER = `${USER_MANAGER_PREFIX}/REMOVE_USER`;
export const GET_USER = `${USER_MANAGER_PREFIX}/GET_USER`;
export const SET_USER = `${USER_MANAGER_PREFIX}/SET_USER`;
export const GET_USER_BY_EMPLOYEE_ID = `${USER_MANAGER_PREFIX}/GET_USER_BY_EMPLOYEE_ID`;
export const GET_USER_BY_DOCTOR_ID = `${USER_MANAGER_PREFIX}/GET_USER_BY_DOCTOR_ID`;
export const GET_LANGUAGES = `${USER_MANAGER_PREFIX}/GET_LANGUAGES`;
export const SET_LANGUAGES = `${USER_MANAGER_PREFIX}/SET_LANGUAGES`;
export const REMOVE_USER_PASSWORD = `${USER_MANAGER_PREFIX}/REMOVE_USER_PASSWORD`;
export const REORDER_EMPLOYEE = `${USER_MANAGER_PREFIX}/REORDER_EMPLOYEE`;
export const GET_USER_EMPLOYEES_INDICATORS = `${USER_MANAGER_PREFIX}/GET_USER_EMPLOYEES_INDICATORS`;
export const SET_USER_EMPLOYEES_INDICATORS = `${USER_MANAGER_PREFIX}/SET_USER_EMPLOYEES_INDICATORS`;
export const GET_USER_EMPLOYEES_OPERATIONS_INDICATORS = `${USER_MANAGER_PREFIX}/GET_USER_EMPLOYEES_OPERATIONS_INDICATORS`;
export const GET_AVAILABLE_EMPLOYEES = `${USER_MANAGER_PREFIX}/GET_AVAILABLE_EMPLOYEES`;
export const SET_AVAILABLE_EMPLOYEES = `${USER_MANAGER_PREFIX}/SET_AVAILABLE_EMPLOYEES`;

export interface UserManagerState {
  users: UserEmployeeResponse[];
  selectedUser: UserEmployeeResponse | null;
  languages: string[];
  indicators: EmployeeWithIndicatorResponse[];
  availableEmployees: AvailableEmployeesResponse[];
}

interface GetUserEmployees {
  type: typeof GET_USER_EMPLOYEES;
  payload: EmployeeRequest;
}

interface SetUserEmployees {
  type: typeof SET_USER_EMPLOYEES;
  payload: UserEmployeeResponse[];
}
interface CreateUser {
  type: typeof CREATE_USER;
  payload: CreateUpdateUserEmployeeRequest;
  meta: any;
}
interface UpdateUser {
  type: typeof CREATE_USER;
  payload: CreateUpdateUserEmployeeRequest;
  meta: any;
}
interface RemoveUser {
  type: typeof CREATE_USER;
  payload: string;
}
interface GetUser {
  type: typeof GET_USER;
  payload: string;
}
interface GetUserByEmployeeId {
  type: typeof GET_USER_BY_EMPLOYEE_ID;
  payload: string;
}
interface GetUserByDoctorId {
  type: typeof GET_USER_BY_EMPLOYEE_ID;
  payload: string;
}
interface SetUser {
  type: typeof SET_USER;
  payload: UserEmployeeResponse | null;
}
interface GetLanguages {
  type: typeof GET_LANGUAGES;
  payload: null;
}
interface SetLanguages {
  type: typeof SET_LANGUAGES;
  payload: string[];
}
interface RemoveUserPassword {
  type: typeof REMOVE_USER_PASSWORD;
  payload: string;
}
interface ReorderEmployee {
  type: typeof REORDER_EMPLOYEE;
  payload: ReorderEmployeeRequest;
  meta: { onSuccess: () => void };
}
interface GetEmployeesIndicators {
  type: typeof GET_USER_EMPLOYEES_INDICATORS;
  payload: EmployeeIndicatorRequest;
}
interface SetEmployeesIndicators {
  type: typeof SET_USER_EMPLOYEES_INDICATORS;
  payload: EmployeeWithIndicatorResponse[];
}
interface GetEmployeesOperationsIndicators {
  type: typeof GET_USER_EMPLOYEES_OPERATIONS_INDICATORS;
  payload: EmployeeIndicatorOperationsRequest;
}
interface GetAvailableEmployees {
  type: typeof GET_AVAILABLE_EMPLOYEES;
  payload: AvailableEmployeesRequest;
}
interface SetAvailableEmployees {
  type: typeof SET_AVAILABLE_EMPLOYEES;
  payload: AvailableEmployeesResponse[];
}

export type UserManagerActionTypes =
  | GetUserEmployees
  | SetUserEmployees
  | CreateUser
  | UpdateUser
  | RemoveUser
  | GetUser
  | GetUserByEmployeeId
  | GetUserByDoctorId
  | SetUser
  | GetLanguages
  | SetLanguages
  | RemoveUserPassword
  | ReorderEmployee
  | GetEmployeesIndicators
  | SetEmployeesIndicators
  | GetEmployeesOperationsIndicators
  | GetAvailableEmployees
  | SetAvailableEmployees;
