const PATHOLOGY_GROUP_PREFIX = 'PATHOLOGY_GROUP';

export const SET_PATHOLOGY_GROUP_LIST = `${PATHOLOGY_GROUP_PREFIX}/SET_PATHOLOGY_GROUP_LIST`;
export const GET_PATHOLOGY_GROUP_LIST = `${PATHOLOGY_GROUP_PREFIX}/GET_PATHOLOGY_GROUP_LIST`;
export const CREATE_PATHOLOGY_GROUP = `${PATHOLOGY_GROUP_PREFIX}/CREATE_PATHOLOGY_GROUP`;
export const UPDATE_PATHOLOGY_GROUP = `${PATHOLOGY_GROUP_PREFIX}/UPDATE_PATHOLOGY_GROUP`;
export const REMOVE_PATHOLOGY_GROUP = `${PATHOLOGY_GROUP_PREFIX}/REMOVE_PATHOLOGY_GROUP`;
export const REORDER_PATHOLOGY_GROUP_LIST = `${PATHOLOGY_GROUP_PREFIX}/REORDER_PATHOLOGY_GROUP_LIST`;
export const TOGGLE_PATHOLOGY_GROUP = `${PATHOLOGY_GROUP_PREFIX}/TOGGLE_PATHOLOGY_GROUP`;
export const GET_PATHOLOGY_GROUP = `${PATHOLOGY_GROUP_PREFIX}/GET_PATHOLOGY_GROUP`;
export const SET_PATHOLOGY_GROUP = `${PATHOLOGY_GROUP_PREFIX}/SET_PATHOLOGY_GROUP`;

export interface PathologyGroupState {
  pathologyGroupList: PathologyGroupResponse[];
  pathologyGroup: PathologyGroupDetailResponse | null;
}

interface SetPathologyGroupList {
  type: typeof SET_PATHOLOGY_GROUP_LIST;
  payload: PathologyGroupResponse[];
}

interface GetPathologyGroup {
  type: typeof GET_PATHOLOGY_GROUP;
  payload: number;
}

interface SetPathologyGroup {
  type: typeof SET_PATHOLOGY_GROUP;
  payload: PathologyGroupDetailResponse | null;
}

interface CreatePathologyGroup {
  type: typeof CREATE_PATHOLOGY_GROUP;
  payload: CreateUpdatePathologyGroupRequest;
  meta: any;
}

interface UpdatePathologyGroup {
  type: typeof UPDATE_PATHOLOGY_GROUP;
  payload: CreateUpdatePathologyGroupRequest;
  meta: any;
}

interface RemovePathologyGroup {
  type: typeof REMOVE_PATHOLOGY_GROUP;
  payload: number;
}

interface ReorderPathologyGroups {
  type: typeof REORDER_PATHOLOGY_GROUP_LIST;
  payload: UpdatePathologyGroupsParentPositionRequest;
}

interface TogglePathologyGroup {
  type: typeof TOGGLE_PATHOLOGY_GROUP;
  payload: CheckPathologyGroupRequest;
  meta: any;
}

export type PathologyGroupActionTypes =
  | SetPathologyGroupList
  | GetPathologyGroup
  | SetPathologyGroup
  | CreatePathologyGroup
  | UpdatePathologyGroup
  | RemovePathologyGroup
  | ReorderPathologyGroups
  | TogglePathologyGroup;
