import {
  SET_LANGUAGES,
  SET_USER,
  SET_USER_EMPLOYEES,
  UserManagerActionTypes,
  UserManagerState,
  SET_USER_EMPLOYEES_INDICATORS,
  SET_AVAILABLE_EMPLOYEES,
} from './types';

const initialState: UserManagerState = {
  users: [],
  selectedUser: {
    address: '',
    doctorNationalId: '',
    email: '',
    employeeActivatedFrom: null,
    employeeActivatedTo: null,
    employeeEmployerId: '',
    firstName: '',
    fullName: '',
    lastName: '',
    isEmployeeActive: false,
    language: '',
    loginActivatedFrom: null,
    loginActivatedTo: null,
    medicalSpecialty: '',
    phoneNumber: '',
    position: 0,
    primaryEmployer: '',
    roles: [],
    shortName: '',
    tenant: '',
    userName: '',
    expanded: false,
  },
  languages: [],
  indicators: [],
  availableEmployees: [],
};

export function userManagerReducer(
  state = initialState,
  action: UserManagerActionTypes
): UserManagerState {
  switch (action.type) {
    case SET_USER_EMPLOYEES:
      return {
        ...state,
        users: (action.payload as UserEmployeeResponse[]).map((u) => {
          u.expanded = false;
          return u;
        }),
      };
    case SET_USER:
      return {
        ...state,
        selectedUser: action.payload as UserEmployeeResponse,
      };
    case SET_LANGUAGES:
      return {
        ...state,
        languages: action.payload as string[],
      };
    case SET_USER_EMPLOYEES_INDICATORS:
      return {
        ...state,
        indicators: action.payload as EmployeeWithIndicatorResponse[],
      };
    case SET_AVAILABLE_EMPLOYEES:
      return {
        ...state,
        availableEmployees: action.payload as AvailableEmployeesResponse[],
      };
    default:
      return state;
  }
}
