import * as operationsApi from './../../api/modules/operations';
import * as employeeApi from './../../api/modules/employee';
import { call, put, all, throttle, race, take } from 'redux-saga/effects';
import {
  setPatient,
  setOperationByCaseId,
  setAutocompleteValues,
  setOperationReadyPatients,
  setCaseEmployee,
  setOperationSummaryInfo,
} from './actions';
import { toast } from 'react-toastify';
import {
  GET_PATIENT_BY_ID,
  GET_OPERATION_BY_CASE_ID,
  CREATE_PATIENT,
  GET_AUTOCOMPLETE_VALUES,
  UPDATE_PATIENT,
  UPDATE_CASE,
  UPDATE_SOAP,
  INFORM_PATIENT,
  ADD_DIAGNOSE_TO_SURGERY,
  UPDATE_DIAGNOSE_FROM_SURGERY,
  REMOVE_DIAGNOSE_FROM_SURGERY,
  ADD_PROCEDURE_TO_SURGERY,
  UPDATE_PROCEDURE_FROM_SURGERY,
  REMOVE_PROCEDURE_FROM_SURGERY,
  GET_OPERATION_READY_PATIENTS,
  SET_OPERATION_TEAM,
  REORDER_APPOINTMENTS_FROM_START,
  REORDER_APPOINTMENTS_SEQUENTIALLY,
  SET_APPOINTMENT_STATUS,
  GET_CASE_EMPLOYEE,
  GET_OPERATION_SUMMARY_INFO,
  UPDATE_OPERATION_REPORT,
} from './types';
import mapValidationErrors from '../../utilities/map-validation-errors';
import operationMock from '../../api/mockups/operations-mock.json';
import { CANCEL_TASKS } from '../common/types';
import generalErrorHandling from '../../utilities/general-error-handling';

function* getPatientGenerator({ payload }: any) {
  try {
    const result = yield call(operationsApi.getPatient, payload);
    if (result) yield put(setPatient(result));
    else {
      const data = {
        ...operationMock.patient,
        patientId: payload,
      } as PatientResponse;
      yield put(setPatient(data));
    }
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getCaseGenerator({ payload }: any) {
  try {
    const result = yield call(operationsApi.getCase, payload);
    yield put(setOperationByCaseId(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getAutocompleteOperationValues() {
  try {
    const result = yield call(operationsApi.getAutocompleteOperationValues);
    yield put(setAutocompleteValues(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* createPatientGenerator({ payload, meta }: any) {
  const { setErrors, setSubmitting } = meta;
  try {
    const result = yield call(operationsApi.createPatient, payload);
    yield put(setOperationByCaseId(result));
  } catch (err) {
    if (err && err.length) {
      const errors: any = mapValidationErrors(err, 'patient.');
      yield call(setErrors, errors);
    } else {
      yield call(toast.error, generalErrorHandling(err));
    }
  }
  yield call(setSubmitting, false);
}

function* updatePatientGenerator({ payload, meta }: any) {
  const { setErrors, setSubmitting } = meta;
  try {
    const result = yield call(operationsApi.updatePatient, payload);
    yield put(setOperationByCaseId(result));
  } catch (err) {
    if (err && err.length) {
      const errors: any = mapValidationErrors(err, 'patient.');
      yield call(setErrors, errors);
    } else {
      yield call(toast.error, generalErrorHandling(err));
    }
  }
  yield call(setSubmitting, false);
}

function* updateCaseGenerator({ payload, meta }: any) {
  const { setErrors, setSubmitting } = meta;
  try {
    const result = yield call(operationsApi.updateCase, payload);
    yield put(setOperationByCaseId(result));
  } catch (err) {
    if (err && err.length) {
      const errors: any = mapValidationErrors(err, 'case.');
      yield call(setErrors, errors);
    } else {
      yield call(toast.error, generalErrorHandling(err));
    }
  }
  yield call(setSubmitting, false);
}
function* informPatientGenerator({ payload }: any) {
  try {
    const result = yield call(operationsApi.informPatient, payload);
    yield put(setOperationByCaseId(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}
function* updateSOAPGenerator({ payload, meta }: any) {
  const { setErrors, setSubmitting } = meta;
  try {
    const result = yield call(operationsApi.updateSOAP, payload);
    yield put(setOperationByCaseId(result));
  } catch (err) {
    if (err && err.length) {
      const errors: any = mapValidationErrors(err, 'soap.');
      yield call(setErrors, errors);
    } else {
      yield call(toast.error, generalErrorHandling(err));
    }
  }
  yield call(setSubmitting, false);
}
function* updateOperationReportGenerator({ payload, meta }: any) {
  const { setErrors, setSubmitting } = meta;
  try {
    const result = yield call(operationsApi.updateOperationReport, payload);
    yield put(setOperationByCaseId(result));
  } catch (err) {
    if (err && err.length) {
      const errors: any = mapValidationErrors(err, 'report.');
      yield call(setErrors, errors);
    } else {
      yield call(toast.error, generalErrorHandling(err));
    }
  }
  yield call(setSubmitting, false);
}
function* addDiagnosisToSurgeryGenerator({ payload }: any) {
  try {
    const result = yield call(operationsApi.addDiagnoseToOperations, payload);
    yield put(setOperationByCaseId(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}
function* updateDiagnosisToSurgeryGenerator({ payload, meta }: any) {
  // TODO: Add formik validation
  try {
    const result = yield call(
      operationsApi.updateDiagnoseToOperations,
      payload
    );
    yield put(setOperationByCaseId(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}
function* removeDiagnosisToSurgeryGenerator({ payload }: any) {
  try {
    const result = yield call(
      operationsApi.removeDiagnoseToOperations,
      payload
    );
    yield put(setOperationByCaseId(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}
function* addProcedureToSurgeryGenerator({ payload }: any) {
  try {
    const result = yield call(operationsApi.addProcedureToOperations, payload);
    yield put(setOperationByCaseId(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}
function* updateProcedureToSurgeryGenerator({ payload, meta }: any) {
  // TODO: Add formik validation
  try {
    const result = yield call(
      operationsApi.updateProcedureToOperations,
      payload
    );
    yield put(setOperationByCaseId(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}
function* removeProcedureToSurgeryGenerator({ payload }: any) {
  try {
    const result = yield call(
      operationsApi.removeProcedureToOperations,
      payload
    );
    yield put(setOperationByCaseId(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}
function* getOperationReadyPatientsGenerator({ payload }: any) {
  try {
    const result = yield call(operationsApi.getOperationReadyPatients, payload);
    yield put(setOperationReadyPatients(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}
function* setOperationTeamGenerator({ payload, meta }: any) {
  try {
    yield call(operationsApi.setOperationTeam, payload);
    yield call(meta);
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}
function* reorderAppointmentsFromStartGenerator({ payload, meta }: any) {
  try {
    yield call(operationsApi.reorderFromStart, payload);
    yield call(meta);
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}
function* reorderAppointmentsFromSequentially({ payload, meta }: any) {
  try {
    yield call(operationsApi.reorderSequentially, payload);
    yield call(meta);
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}
function* setAppointmentStatusGenerator({ payload, meta }: any) {
  try {
    yield call(operationsApi.setStatus, payload);
    yield call(meta);
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}
function* getCaseEmployeeGenerator({ payload }: any) {
  try {
    const result: EmployeeShortResponse = yield call(
      employeeApi.searchEmployee,
      payload
    );
    yield put(setCaseEmployee(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getOperationsSummaryInfoGenerator({ payload }: any) {
  try {
    const result = yield call(operationsApi.getSummaryInfo, payload);
    yield put(setOperationSummaryInfo(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

export function* operationsRootSagas() {
  return yield race([
    take(CANCEL_TASKS),
    all([
      throttle(100, GET_PATIENT_BY_ID, getPatientGenerator),
      throttle(100, GET_OPERATION_BY_CASE_ID, getCaseGenerator),
      throttle(100, GET_AUTOCOMPLETE_VALUES, getAutocompleteOperationValues),
      throttle(100, CREATE_PATIENT, createPatientGenerator),
      throttle(100, UPDATE_PATIENT, updatePatientGenerator),
      throttle(100, UPDATE_CASE, updateCaseGenerator),
      throttle(100, INFORM_PATIENT, informPatientGenerator),
      throttle(100, UPDATE_SOAP, updateSOAPGenerator),
      throttle(100, UPDATE_OPERATION_REPORT, updateOperationReportGenerator),
      throttle(100, ADD_DIAGNOSE_TO_SURGERY, addDiagnosisToSurgeryGenerator),
      throttle(
        100,
        GET_OPERATION_SUMMARY_INFO,
        getOperationsSummaryInfoGenerator
      ),
      throttle(
        100,
        UPDATE_DIAGNOSE_FROM_SURGERY,
        updateDiagnosisToSurgeryGenerator
      ),
      throttle(
        100,
        REMOVE_DIAGNOSE_FROM_SURGERY,
        removeDiagnosisToSurgeryGenerator
      ),
      throttle(100, ADD_PROCEDURE_TO_SURGERY, addProcedureToSurgeryGenerator),
      throttle(
        100,
        UPDATE_PROCEDURE_FROM_SURGERY,
        updateProcedureToSurgeryGenerator
      ),
      throttle(
        100,
        REMOVE_PROCEDURE_FROM_SURGERY,
        removeProcedureToSurgeryGenerator
      ),
      throttle(
        100,
        GET_OPERATION_READY_PATIENTS,
        getOperationReadyPatientsGenerator
      ),
      throttle(100, SET_OPERATION_TEAM, setOperationTeamGenerator),
      throttle(
        100,
        REORDER_APPOINTMENTS_FROM_START,
        reorderAppointmentsFromStartGenerator
      ),
      throttle(
        100,
        REORDER_APPOINTMENTS_SEQUENTIALLY,
        reorderAppointmentsFromSequentially
      ),
      throttle(100, SET_APPOINTMENT_STATUS, setAppointmentStatusGenerator),
      throttle(100, GET_CASE_EMPLOYEE, getCaseEmployeeGenerator),
    ]),
  ]);
}
