import {
  AttachmentState,
  AttachmentActionTypes,
  SET_ATTACHMENT_FILES,
  SET_ATTACHMENT_FILE,
  REMOVE_ATTACHMENT,
} from './types';

const initialState: AttachmentState = {
  attachments: [],
};

export function attachmentReducer(
  state = initialState,
  action: AttachmentActionTypes
): AttachmentState {
  switch (action.type) {
    case SET_ATTACHMENT_FILES:
      return {
        ...state,
        attachments: action.payload as AttachmentFileResponse[],
      };
    case SET_ATTACHMENT_FILE:
      return {
        ...state,
        attachments: [
          ...state.attachments,
          action.payload as AttachmentFileResponse,
        ],
      };
    case REMOVE_ATTACHMENT:
      return {
        ...state,
        attachments: state.attachments.filter((a) => a.id != action.payload),
      };
    default:
      return state;
  }
}
