import { UserManagerSettings } from 'oidc-client';
import { createUserManager } from 'redux-oidc';

// TODO: Refactor on deploy
const userManagerConfig: UserManagerSettings = {
  authority: process.env.REACT_APP_IS,
  client_id: process.env.REACT_APP_CLIENT_ID,
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }/callback`,
  response_type: 'code',
  scope: 'openid profile sop_api',
  post_logout_redirect_uri: `${window.location.protocol}//${
    window.location.hostname
  }${window.location.port ? `:${window.location.port}` : ''}`,
  silent_redirect_uri: `${window.location.protocol}//${
    window.location.hostname
  }${window.location.port ? `:${window.location.port}` : ''}/silent_renew`,
  automaticSilentRenew: true,
  loadUserInfo: true,
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
