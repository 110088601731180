const SCHEDULERS_PREFIX = 'SCHEDULERS';

export const GET_ROSTERS = `${SCHEDULERS_PREFIX}/GET_ROSTERS`;
export const SET_ROSTERS = `${SCHEDULERS_PREFIX}/SET_ROSTERS`;
export const GET_ROSTER = `${SCHEDULERS_PREFIX}/GET_ROSTER`;
export const SET_ROSTER = `${SCHEDULERS_PREFIX}/SET_ROSTER`;
export const GET_ROSTER_PLANS = `${SCHEDULERS_PREFIX}/GET_ROSTER_PLANS`;
export const SET_ROSTER_PLANS = `${SCHEDULERS_PREFIX}/SET_ROSTER_PLANS`;
export const UPDATE_ROSTER_CELL = `${SCHEDULERS_PREFIX}/UPDATE_ROSTER_CELL`;
export const UPDATE_ROSTER_CELLS = `${SCHEDULERS_PREFIX}/UPDATE_ROSTER_CELLS`;
export const GET_ROSTER_VERSIONS = `${SCHEDULERS_PREFIX}/GET_ROSTER_VERSIONS`;
export const SET_ROSTER_VERSIONS = `${SCHEDULERS_PREFIX}/SET_ROSTER_VERSIONS`;
export const GET_ROSTER_VERSION = `${SCHEDULERS_PREFIX}/GET_ROSTER_VERSION`;
export const SIGN_ROSTER_VERSION = `${SCHEDULERS_PREFIX}/SIGN_ROSTER_VERSION`;
export const RESET_ROSTER = `${SCHEDULERS_PREFIX}/RESET_ROSTER`;
export const APPLY_ROSTER_TEMPLATE_CELLS = `${SCHEDULERS_PREFIX}/APPLY_ROSTER_TEMPLATE_CELLS`;
export const GET_SIGN_REASONS = `${SCHEDULERS_PREFIX}/GET_SIGN_REASON`;
export const SET_SIGN_REASONS = `${SCHEDULERS_PREFIX}/SET_SIGN_REASON`;
export const GET_VERSION_NOTES = `${SCHEDULERS_PREFIX}/GET_VERSION_NOTES`;
export const SET_VERSION_NOTES = `${SCHEDULERS_PREFIX}/SET_VERSION_NOTES`;

export interface SchedulersState {
  rosters: BaseRosterTemplateResponse[];
  selectedRoster: TemplateTableResponse | null;
  plans: PlanResponse[];
  rosterVersions: RosterVersionResponse[];
  signReasons: string[];
  versionNotes: string;
}
interface GetRosters {
  type: typeof GET_ROSTERS;
  payload: null;
}
interface SetRosters {
  type: typeof SET_ROSTERS;
  payload: BaseRosterTemplateResponse[];
}
interface GetRoster {
  type: typeof SET_ROSTER;
  payload: RosterRequest;
}
interface SetRoster {
  type: typeof SET_ROSTER;
  payload: TemplateTableResponse;
}
interface GetRosterPlans {
  type: typeof GET_ROSTER_PLANS;
  payload: null;
}
interface SetRosterPlans {
  type: typeof SET_ROSTER_PLANS;
  payload: PlanResponse[];
}
interface UpdateRosterCell {
  type: typeof UPDATE_ROSTER_CELL;
  payload: UpdateRosterCellRequest;
  meta: any;
}
interface UpdateRosterCells {
  type: typeof UPDATE_ROSTER_CELLS;
  payload: UpdateRosterCellsRequest;
  meta: any;
}
interface GetRosterVersions {
  type: typeof GET_ROSTER_VERSIONS;
  payload: VersionRequest;
}
interface SetRosterVersions {
  type: typeof SET_ROSTER_VERSIONS;
  payload: RosterVersionResponse[];
}
interface GetRosterVersion {
  type: typeof GET_ROSTER_VERSION;
  payload: GetRosterVersionRequest;
}
interface SignRosterVersion {
  type: typeof SIGN_ROSTER_VERSION;
  payload: SignRosterTemplateRequest;
  meta: any;
}
interface ResetRoster {
  type: typeof RESET_ROSTER;
  payload: ResetRosterTemplateRequest;
}
interface ApplyRosterTemplateCells {
  type: typeof APPLY_ROSTER_TEMPLATE_CELLS;
  payload: ApplyTemplateCellsRequest;
}
interface GetSignReasons {
  type: typeof GET_SIGN_REASONS;
  payload: null;
}
interface SetSignReasons {
  type: typeof SET_SIGN_REASONS;
  payload: string[];
}
interface GetVersionNotes {
  type: typeof GET_VERSION_NOTES;
  payload: VersionNotesRequest;
}
interface SetVersionNotes {
  type: typeof SET_VERSION_NOTES;
  payload: string;
}

export type SchedulersActionTypes =
  | GetRosters
  | SetRosters
  | GetRoster
  | SetRoster
  | GetRosterPlans
  | SetRosterPlans
  | UpdateRosterCell
  | UpdateRosterCells
  | GetRosterVersions
  | GetRosterVersion
  | SetRosterVersions
  | SignRosterVersion
  | ResetRoster
  | ApplyRosterTemplateCells
  | GetSignReasons
  | SetSignReasons
  | GetVersionNotes
  | SetVersionNotes;
