import api from './../index';

const OVERTIME_BASE_URL = '/api/v1/Overtime';

export async function getCase(caseId: number): Promise<PatientResponse> {
  const result = await api.get<PatientResponse>(
    `${OVERTIME_BASE_URL}/${caseId}`
  );
  return result.data;
}

export async function getPatient(
  patientId: string
): Promise<OperationResponse> {
  const result = await api.get<OperationResponse>(
    `${OVERTIME_BASE_URL}/Patient/${patientId}`
  );
  return result.data;
}

export async function getOvertime(caseId: number): Promise<OperationResponse> {
  const result = await api.get<OperationResponse>(
    `${OVERTIME_BASE_URL}/${caseId}`
  );
  return result.data;
}

export async function getAutocompleteOperationValues(): Promise<OperationAutocompleteResponse> {
  const result = await api.get<OperationAutocompleteResponse>(
    `${OVERTIME_BASE_URL}/Autocomplete`
  );
  return result.data;
}

export async function getOvertimeTeams(
  date: string
): Promise<OvertimeTeamsResponse> {
  const result = await api.get<OvertimeTeamsResponse>(
    `${OVERTIME_BASE_URL}/GetOvertimeTeams/${date}`
  );
  return result.data;
}

// Wizard step 1
export async function createPatient(
  payload: CreateUpdatePatientRequest
): Promise<OperationResponse> {
  const result = await api.post<OperationResponse>(
    `${OVERTIME_BASE_URL}`,
    payload
  );
  return result.data;
}
export async function updatePatient(
  payload: CreateUpdatePatientRequest
): Promise<OperationResponse> {
  const result = await api.put<OperationResponse>(
    `${OVERTIME_BASE_URL}/Patient/${payload.patientCaseId}`,
    payload
  );
  return result.data;
}
// wizard step 2
export async function updateNZV(
  payload: UpdateNZVRequest
): Promise<OperationResponse> {
  const result = await api.put<OperationResponse>(
    `${OVERTIME_BASE_URL}/Case/${payload.patientCaseId}`,
    payload
  );
  return result.data;
}
// wizard step 3
export async function updateSOAP(
  payload: UpdateSOAPRequest
): Promise<OperationResponse> {
  const result = await api.put<OperationResponse>(
    `${OVERTIME_BASE_URL}/Soap/${payload.patientCaseId}`,
    payload
  );
  return result.data;
}
//wizard step 4
export async function addDiagnoseToOperations(
  payload: AddDiagnoseToOperationRequest
): Promise<OperationResponse> {
  const result = await api.post<OperationResponse>(
    `${OVERTIME_BASE_URL}/Diagnosis/${payload.patientCaseId}`,
    payload
  );
  return result.data;
}
export async function updateDiagnoseToOperations(
  payload: UpdateDiagnoseFromOperationRequest
): Promise<OperationResponse> {
  const result = await api.put<OperationResponse>(
    `${OVERTIME_BASE_URL}/Diagnosis/${payload.patientCaseId}`,
    payload
  );
  return result.data;
}
export async function partialUpdateDiagnoseToOperations(
  payload: PartialUpdateDiagnoseToOperationRequest
): Promise<OperationResponse> {
  const result = await api.patch<OperationResponse>(
    `${OVERTIME_BASE_URL}/Diagnosis/${payload.patientCaseId}`,
    payload
  );
  return result.data;
}
export async function removeDiagnoseToOperations(
  payload: RemoveDiagnoseFromOperationRequest
): Promise<OperationResponse> {
  const result = await api.delete<OperationResponse>(
    `${OVERTIME_BASE_URL}/Diagnosis/${
      payload.patientCaseId
    }/${encodeURIComponent(payload.code.replace('.', ''))}`
  );
  return result.data;
}
// wizard step 5
export async function addProcedureToOperations(
  payload: AddDiagnoseToOperationRequest
): Promise<OperationResponse> {
  const result = await api.post<OperationResponse>(
    `${OVERTIME_BASE_URL}/Procedure/${payload.patientCaseId}`,
    payload
  );
  return result.data;
}
export async function updateProcedureToOperations(
  payload: UpdateDiagnoseFromOperationRequest
): Promise<OperationResponse> {
  const result = await api.put<OperationResponse>(
    `${OVERTIME_BASE_URL}/Procedure/${payload.patientCaseId}`,
    payload
  );
  return result.data;
}
export async function partialUpdateProcedureToOperations(
  payload: PartialUpdateDiagnoseToOperationRequest
): Promise<OperationResponse> {
  const result = await api.patch<OperationResponse>(
    `${OVERTIME_BASE_URL}/Procedure/${payload.patientCaseId}`,
    payload
  );
  return result.data;
}
export async function removeProcedureToOperations(
  payload: RemoveDiagnoseFromOperationRequest
): Promise<OperationResponse> {
  const result = await api.delete<OperationResponse>(
    `${OVERTIME_BASE_URL}/Procedure/${payload.patientCaseId}/${payload.code}`
  );
  return result.data;
}
// scheduler
export async function getOperationReadyPatients(
  payload: OperationReadyPatientsRequest
): Promise<OperationReadyPatientResponse> {
  const result = await api.get(
    `${OVERTIME_BASE_URL}/Patients/${payload.start}/${payload.days}/${payload.filter}`,
    { params: payload }
  );
  return result.data;
}
export async function setOperationTeam(
  payload: SetOperationTeamRequest
): Promise<any> {
  const result = await api.post(`${OVERTIME_BASE_URL}/SetTeam`, payload);
  return result.data;
}
export async function reorderFromStart(
  payload: ReorderRequest
): Promise<OperationReadyPatientResponse[]> {
  const result = await api.put<OperationReadyPatientResponse[]>(
    `${OVERTIME_BASE_URL}/ReorderFromStart`,
    payload
  );
  return result.data;
}
export async function reorderSequentially(
  payload: ReorderRequest
): Promise<OperationReadyPatientResponse[]> {
  const result = await api.put<OperationReadyPatientResponse[]>(
    `${OVERTIME_BASE_URL}/ReorderSequentially`,
    payload
  );
  return result.data;
}
export async function setStatus(
  payload: SetStatusRequest
): Promise<OperationReadyPatientSchedulerResponse> {
  const result = await api.post<OperationReadyPatientSchedulerResponse>(
    `${OVERTIME_BASE_URL}/SetStatus`,
    payload
  );
  return result.data;
}
