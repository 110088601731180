import api from '../index';

const WORKPLACE_ACTIVITY_BASE_URL = '/api/v1/Workplace/Activity';

export async function getWorkPlaceActivity(): Promise<
  WorkplaceActivityResponse[]
> {
  const result = await api.get<WorkplaceActivityResponse[]>(
    `${WORKPLACE_ACTIVITY_BASE_URL}`
  );
  return result.data;
}

export async function createWorkPlaceActivity(
  payload: CreateUpdateWorkplaceActivityRequest
) {
  const result = await api.post<WorkplaceActivityResponse>(
    `${WORKPLACE_ACTIVITY_BASE_URL}`,
    payload
  );
  return result.data;
}

export async function updateWorkPlaceActivity(
  payload: CreateUpdateWorkplaceActivityRequest
) {
  const result = await api.put<WorkplaceActivityResponse>(
    `${WORKPLACE_ACTIVITY_BASE_URL}/${payload.id}`,
    payload
  );
  return result.data;
}

export async function removeWorkPlaceActivity(workplaceId: string) {
  await api.delete(`${WORKPLACE_ACTIVITY_BASE_URL}/${workplaceId}`);
}

export async function cancelWorkplaceActivity(
  payload: CancelWorkplaceActivityRequest
) {
  const result = await api.put(
    `${WORKPLACE_ACTIVITY_BASE_URL}/Cancel/${payload.id}`,
    payload
  );
  return result.data;
}

export async function cancelOccurrenceWorkplaceActivity(
  payload: CancelOccurrenceWorkplaceActivityRequest
) {
  const result = await api.put(
    `${WORKPLACE_ACTIVITY_BASE_URL}/CancelOccurrence/${payload.id}`,
    payload
  );
  return result.data;
}

export async function getCancelReasons(): Promise<string[]> {
  const result = await api.get(
    `${WORKPLACE_ACTIVITY_BASE_URL}/CanceledReasons`
  );
  return result.data;
}

export async function getSummaryInfoTab(
  payload: WorkplaceActivitySummaryInfoRequest
): Promise<SummaryInfoTabResponse> {
  const result = await api.get<SummaryInfoTabResponse>(
    `${WORKPLACE_ACTIVITY_BASE_URL}/${payload.startDate}/${payload.endDate}/SummaryInfo`
  );
  return result.data;
}
