import { SkillState, SkillsActionTypes, SET_SKILL_LIST } from './types';

const initialState: SkillState = {
  skillList: [],
};

export function skillReducer(
  state = initialState,
  action: SkillsActionTypes
): SkillState {
  switch (action.type) {
    case SET_SKILL_LIST:
      return { ...state, skillList: action.payload as SkillResponse[] };
    default:
      return state;
  }
}
