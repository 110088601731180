import api from '../index';

const AVAILABILITY_BASE_URL = '/api/v1/Register/Availability';
const EMPLOYEE_AVAILABILITY_BASE_URL = '/api/v1/Employee/Availability';

export async function getAvailabilityList(): Promise<AvailabilityResponse[]> {
  const result = await api.get<AvailabilityResponse[]>(AVAILABILITY_BASE_URL);
  return result.data;
}
export async function getPlanParameters(
  planId: number
): Promise<PlanParametersResponse> {
  const result = await api.get<PlanParametersResponse>(
    `${EMPLOYEE_AVAILABILITY_BASE_URL}/Parameters/${planId}`
  );
  return result.data;
}
export async function updatePlanParameters(
  payload: PlanParametersRequest
): Promise<PlanParametersResponse> {
  const result = await api.put<PlanParametersResponse>(
    `${EMPLOYEE_AVAILABILITY_BASE_URL}/Parameters/${payload.planId}`,
    payload
  );
  return result.data;
}
export async function setApprovalStatusUpdate(payload: {
  id: string;
  approvalStatus: string;
}): Promise<any> {
  const result = await api.put(
    `${EMPLOYEE_AVAILABILITY_BASE_URL}/SetApprovalStatus/${payload.id}`,
    payload
  );
  return result.data;
}
export async function getAvailabilitySummaryInfo(
  payload: AvailabilitySummaryInfoRequest
): Promise<SummaryInfoTabResponse[]> {
  const result = await api.get<SummaryInfoTabResponse[]>(
    `${EMPLOYEE_AVAILABILITY_BASE_URL}/${payload.startDate}/${payload.endDate}/SummaryInfo`
  );
  return result.data;
}
