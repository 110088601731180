import { call, all, throttle, put, race, take } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as qualificationsApi from '../../../api/modules/qualifications';
import {
  REORDER_QUALIFICATIONS,
  GET_QUALIFICATION_LIST,
  TOGGLE_QUALIFICATION_STATE,
} from './types';
import { setQualifications } from './actions';
import { CANCEL_TASKS } from '../../common/types';
import generalErrorHandling from '../../../utilities/general-error-handling';

function* reorderQualificationsGenerator({ payload, meta }: any) {
  const { onSuccess } = meta;
  try {
    yield call(qualificationsApi.reorderQualifications, payload);
    yield call(onSuccess);
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}
function* getQualificationsGenerator() {
  try {
    const result = yield call(qualificationsApi.getQualifications);
    yield put(setQualifications(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* toggleQualificationStateGenerator({ payload, meta }: any) {
  try {
    yield call(
      qualificationsApi.toggleQualificationSelection,
      payload.id,
      payload.selected
    );
    yield getQualificationsGenerator();
    yield call(meta);
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

export function* qualificationsRootSagas() {
  return yield race([
    take(CANCEL_TASKS),
    all([
      throttle(100, REORDER_QUALIFICATIONS, reorderQualificationsGenerator),
      throttle(100, GET_QUALIFICATION_LIST, getQualificationsGenerator),
      throttle(
        100,
        TOGGLE_QUALIFICATION_STATE,
        toggleQualificationStateGenerator
      ),
    ]),
  ]);
}
