import {
  OvertimeActionTypes,
  GET_OVERTIME_TEAMS,
  SET_OVERTIME_TEAMS,
  CREATE_OVERTIME_PATIENT,
  UPDATE_OVERTIME_PATIENT,
  SET_OVERTIME_OPERATION_BY_CASE_ID,
  GET_OVERTIME_PATIENT_BY_ID,
  UPDATE_OVERTIME_NZV,
  UPDATE_OVERTIME_SOAP,
  ADD_DIAGNOSE_TO_OVERTIME_SURGERY,
  UPDATE_DIAGNOSE_FROM_OVERTIME_SURGERY,
  REMOVE_DIAGNOSE_FROM_OVERTIME_SURGERY,
  ADD_PROCEDURE_TO_OVERTIME_SURGERY,
  UPDATE_PROCEDURE_FROM_OVERTIME_SURGERY,
  REMOVE_PROCEDURE_FROM_OVERTIME_SURGERY,
  SET_OVERTIME_PATIENT_BY_ID,
  GET_OVERTIME_OPERATION_BY_CASE_ID,
  GET_NZV_EMPLOYEE,
  SET_NZV_EMPLOYEE,
} from './types';

export function getOvertimeTeams(payload: string): OvertimeActionTypes {
  return {
    type: GET_OVERTIME_TEAMS,
    payload: payload,
  };
}
export function setOvertimeTeams(payload: string): OvertimeActionTypes {
  return {
    type: SET_OVERTIME_TEAMS,
    payload: payload,
  };
}
export function getOvertimePatient(payload: string): OvertimeActionTypes {
  return {
    type: GET_OVERTIME_PATIENT_BY_ID,
    payload: payload,
  };
}
export function setOvertimePatient(
  payload: PatientResponse
): OvertimeActionTypes {
  return {
    type: SET_OVERTIME_PATIENT_BY_ID,
    payload: payload,
  };
}
export function setOvertimeOperationByCaseId(
  payload: OperationResponse
): OvertimeActionTypes {
  return {
    type: SET_OVERTIME_OPERATION_BY_CASE_ID,
    payload: payload,
  };
}
export function getOvertimeOperationByCaseId(
  payload: number
): OvertimeActionTypes {
  return {
    type: GET_OVERTIME_OPERATION_BY_CASE_ID,
    payload: payload,
  };
}
export function createOvertimePatient(
  payload: CreateUpdatePatientRequest,
  meta: any
): OvertimeActionTypes {
  return {
    type: CREATE_OVERTIME_PATIENT,
    payload: payload,
    meta: meta,
  };
}
export function updateOvertimePatient(
  payload: CreateUpdatePatientRequest,
  meta: any
): OvertimeActionTypes {
  return {
    type: UPDATE_OVERTIME_PATIENT,
    payload: payload,
    meta: meta,
  };
}
export function updateOvertimeNZV(
  payload: UpdateNZVRequest,
  meta: any
): OvertimeActionTypes {
  return {
    type: UPDATE_OVERTIME_NZV,
    payload: payload,
    meta: meta,
  };
}
export function updateOvertimeSOAP(
  payload: UpdateSOAPRequest,
  meta: any
): OvertimeActionTypes {
  return {
    type: UPDATE_OVERTIME_SOAP,
    payload: payload,
    meta: meta,
  };
}
export function addDiagnoseToOvertimeSurgery(
  payload: AddDiagnoseToOperationRequest
): OvertimeActionTypes {
  return {
    type: ADD_DIAGNOSE_TO_OVERTIME_SURGERY,
    payload: payload,
  };
}
export function updateDiagnoseToOvertimeSurgery(
  payload: UpdateDiagnoseFromOperationRequest
): OvertimeActionTypes {
  return {
    type: UPDATE_DIAGNOSE_FROM_OVERTIME_SURGERY,
    payload: payload,
  };
}
export function removeDiagnoseToOvertimeSurgery(
  payload: RemoveDiagnoseFromOperationRequest
): OvertimeActionTypes {
  return {
    type: REMOVE_DIAGNOSE_FROM_OVERTIME_SURGERY,
    payload: payload,
  };
}
export function addProcedureToOvertimeSurgery(
  payload: AddProcedureToOperationRequest
): OvertimeActionTypes {
  return {
    type: ADD_PROCEDURE_TO_OVERTIME_SURGERY,
    payload: payload,
  };
}
export function updateProcedureToOvertimeSurgery(
  payload: UpdateProcedureFromOperationRequest
): OvertimeActionTypes {
  return {
    type: UPDATE_PROCEDURE_FROM_OVERTIME_SURGERY,
    payload: payload,
  };
}
export function removeProcedureToOvertimeSurgery(
  payload: RemoveProcedureFromOperationRequest
): OvertimeActionTypes {
  return {
    type: REMOVE_PROCEDURE_FROM_OVERTIME_SURGERY,
    payload: payload,
  };
}
export function getNZVEmployee(payload: string): OvertimeActionTypes {
  return {
    type: GET_NZV_EMPLOYEE,
    payload: payload,
  };
}
export function setNZVEmployee(
  payload: EmployeeShortResponse
): OvertimeActionTypes {
  return {
    type: SET_NZV_EMPLOYEE,
    payload: payload,
  };
}
