import api from '../index';

const DEPARTMENT_BASE_URL = '/api/v1/Departments';

export async function getDepartments(): Promise<DepartmentResponse[]> {
  const response = await api.get<DepartmentResponse[]>(
    `${DEPARTMENT_BASE_URL}`
  );
  return response.data;
}
