import {
  UserState,
  SET_USER_TOKEN,
  UserActionTypes,
  SET_USER_DEPARTMENT,
  SET_DEPARTMENTS,
  SET_USER_LANGUAGE,
} from './types';

const initialState: UserState = {
  token: '',
  department: localStorage.getItem('department') ?? '',
  departments: [],
  language:'en'
};

export function userReducer(
  state = initialState,
  action: UserActionTypes
): UserState {
  switch (action.type) {
    case SET_USER_TOKEN:
      return { ...state, token: action.payload as string };
    case SET_USER_DEPARTMENT:
      return {
        ...state,
        department: action.payload as string,
      };
    case SET_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload as DepartmentResponse[],
      };
    case SET_USER_LANGUAGE:
      return {
        ...state,
        language:action.payload as string
      }
    default:
      return state;
  }
}
