import api from '../index';

const AVAILABILITIES_BASE_URL = 'api/v1/register/availability';

export async function reorderAvailabilities(
  payload: ReorderAvailabilitiesRequest
) {
  const result = await api.put(
    `${AVAILABILITIES_BASE_URL}/Position/${payload.id}`,
    payload
  );
  return result.data;
}
