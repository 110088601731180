const OVERTIME_PREFIX = 'OVERTIME';

export const GET_OVERTIME_PATIENT_BY_ID = `${OVERTIME_PREFIX}/GET_OVERTIME_PATIENT_BY_ID`;
export const SET_OVERTIME_PATIENT_BY_ID = `${OVERTIME_PREFIX}/SET_OVERTIME_PATIENT_BY_ID`;

export const GET_OVERTIME_OPERATION_BY_CASE_ID = `${OVERTIME_PREFIX}/GET_OVERTIME_OPERATION_BY_CASE_ID`;
export const SET_OVERTIME_OPERATION_BY_CASE_ID = `${OVERTIME_PREFIX}/SET_OVERTIME_OPERATION_BY_CASE_ID`;

export const CREATE_OVERTIME_PATIENT = `${OVERTIME_PREFIX}/CREATE_OVERTIME_PATIENT`;
export const UPDATE_OVERTIME_PATIENT = `${OVERTIME_PREFIX}/UPDATE_OVERTIME_PATIENT`;
export const REMOVE_OVERTIME_PATIENT = `${OVERTIME_PREFIX}/REMOVE_OVERTIME_PATIENT`;

export const UPDATE_OVERTIME_NZV = `${OVERTIME_PREFIX}/UPDATE_OVERTIME_NZV`;

export const UPDATE_OVERTIME_SOAP = `${OVERTIME_PREFIX}/UPDATE_OVERTIME_SOAP`;

export const ADD_DIAGNOSE_TO_OVERTIME_SURGERY = `${OVERTIME_PREFIX}/ADD_DIAGNOSE_TO_OVERTIME_SURGERY`;
export const UPDATE_DIAGNOSE_FROM_OVERTIME_SURGERY = `${OVERTIME_PREFIX}/UPDATE_DIAGNOSE_FROM_OVERTIME_SURGERY`;
export const REMOVE_DIAGNOSE_FROM_OVERTIME_SURGERY = `${OVERTIME_PREFIX}/REMOVE_DIAGNOSE_FROM_OVERTIME_SURGERY`;

export const ADD_PROCEDURE_TO_OVERTIME_SURGERY = `${OVERTIME_PREFIX}/ADD_PROCEDURE_TO_OVERTIME_SURGERY`;
export const UPDATE_PROCEDURE_FROM_OVERTIME_SURGERY = `${OVERTIME_PREFIX}/UPDATE_PROCEDURE_FROM_OVERTIME_SURGERY`;
export const REMOVE_PROCEDURE_FROM_OVERTIME_SURGERY = `${OVERTIME_PREFIX}/REMOVE_PROCEDURE_FROM_OVERTIME_SURGERY`;

export const SET_WIZARD_STEP = `${OVERTIME_PREFIX}/SET_WIZARD_STEP`;

export const GET_OVERTIME_TEAMS = `${OVERTIME_PREFIX}/GET_OVERTIME_TEAMS`;
export const SET_OVERTIME_TEAMS = `${OVERTIME_PREFIX}/SET_OVERTIME_TEAMS`;

export const GET_NZV_EMPLOYEE = `${OVERTIME_PREFIX}/GET_NZV_EMPLOYEE`;
export const SET_NZV_EMPLOYEE = `${OVERTIME_PREFIX}/SET_NZV_EMPLOYEE`;

export interface OvertimeState {
  operation: OperationResponse | null;
  teams: OvertimeTeamsResponse[];
  step: number;
}

interface GetOvertimeTeams {
  type: typeof GET_OVERTIME_TEAMS;
  payload: string;
}
interface SetOvertimeTeams {
  type: typeof SET_OVERTIME_TEAMS;
  payload: OvertimeTeamsResponse[];
}
interface GetOvertimePatient {
  type: typeof GET_OVERTIME_PATIENT_BY_ID;
  payload: string;
}
interface SetOvertimePatient {
  type: typeof SET_OVERTIME_PATIENT_BY_ID;
  payload: PatientResponse;
}
interface GetOvertimeOperationByCase {
  type: typeof GET_OVERTIME_OPERATION_BY_CASE_ID;
  payload: number;
}
interface SetOvertimeOperationByCase {
  type: typeof SET_OVERTIME_OPERATION_BY_CASE_ID;
  payload: OperationResponse;
}
interface CreateOvertimePatient {
  type: typeof CREATE_OVERTIME_PATIENT;
  payload: CreateUpdatePatientRequest;
  meta: any;
}
interface UpdateOvertimePatient {
  type: typeof UPDATE_OVERTIME_PATIENT;
  payload: CreateUpdatePatientRequest;
  meta: any;
}
interface UpdateOvertimeNZV {
  type: typeof UPDATE_OVERTIME_NZV;
  payload: UpdateNZVRequest;
  meta: any;
}
interface UpdateOvertimeSOAP {
  type: typeof UPDATE_OVERTIME_SOAP;
  payload: UpdateSOAPRequest;
  meta: any;
}
interface AddDiagnoseToOvertimeSurgery {
  type: typeof ADD_DIAGNOSE_TO_OVERTIME_SURGERY;
  payload: AddDiagnoseToOperationRequest;
}
interface UpdateDiagnoseFromOvertimeSurgery {
  type: typeof UPDATE_DIAGNOSE_FROM_OVERTIME_SURGERY;
  payload: UpdateDiagnoseFromOperationRequest;
}
interface RemoveDiagnoseFromOvertimeSurgery {
  type: typeof UPDATE_DIAGNOSE_FROM_OVERTIME_SURGERY;
  payload: RemoveDiagnoseFromOperationRequest;
}
interface AddProcedureToOvertimeSurgery {
  type: typeof ADD_PROCEDURE_TO_OVERTIME_SURGERY;
  payload: AddProcedureToOperationRequest;
}
interface UpdateProcedureFromOvertimeSurgery {
  type: typeof UPDATE_PROCEDURE_FROM_OVERTIME_SURGERY;
  payload: UpdateProcedureFromOperationRequest;
}
interface RemoveProcedureFromOvertimeSurgery {
  type: typeof UPDATE_DIAGNOSE_FROM_OVERTIME_SURGERY;
  payload: RemoveProcedureFromOperationRequest;
}
interface GetNZVEmployee {
  type: typeof GET_NZV_EMPLOYEE;
  payload: string;
}
interface SetNZVEmployee {
  type: typeof SET_NZV_EMPLOYEE;
  payload: EmployeeShortResponse;
}

export type OvertimeActionTypes =
  | GetOvertimePatient
  | SetOvertimePatient
  | GetOvertimeTeams
  | SetOvertimeTeams
  | GetOvertimeOperationByCase
  | SetOvertimeOperationByCase
  | CreateOvertimePatient
  | UpdateOvertimePatient
  | UpdateOvertimeNZV
  | UpdateOvertimeSOAP
  | AddDiagnoseToOvertimeSurgery
  | UpdateDiagnoseFromOvertimeSurgery
  | RemoveDiagnoseFromOvertimeSurgery
  | AddProcedureToOvertimeSurgery
  | UpdateProcedureFromOvertimeSurgery
  | RemoveProcedureFromOvertimeSurgery
  | GetNZVEmployee
  | SetNZVEmployee;
