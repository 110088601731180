import { isArray, isEmpty } from 'lodash';

export function isSuperAdmin(userRoles: string[] | string) {
  if (isArray(userRoles))
    return !isEmpty(userRoles.find((u) => u.includes('SuperAdmin')));
  return userRoles.includes('SuperAdmin');
}
export function checkRole(
  pathRole: UserRole,
  userRoles: string[] | string,
  department: string
): boolean {
  if (isSuperAdmin(userRoles)) return true;
  else if (Array.isArray(userRoles)) {
    return userRoles.findIndex((r) => r === `${department}_${pathRole}`) !== -1;
  } else {
    return userRoles.includes(`${department}_${pathRole}`);
  }
}
export function checkRoles(
  pathRoles: UserRole[],
  userRoles: string[] | string,
  department: string
): boolean {
  if (isSuperAdmin(userRoles)) return true;
  return pathRoles
    .map((r) => checkRole(r, userRoles, department))
    .includes(true);
}
