import api from '../index';

const ROSTER_PATHOLOGY_GROUP_BASE_URL = '/api/v1/Patient/PathologyGroup';

export async function getPathologyGroupList(): Promise<
  PathologyGroupResponse[]
> {
  const result = await api.get<PathologyGroupResponse[]>(
    `${ROSTER_PATHOLOGY_GROUP_BASE_URL}`
  );
  return result.data;
}

export async function getPathologyGroup(
  id: number
): Promise<PathologyGroupDetailResponse> {
  const result = await api.get<PathologyGroupDetailResponse>(
    `${ROSTER_PATHOLOGY_GROUP_BASE_URL}/${id}`
  );
  return result.data;
}

export async function createPathologyGroup(
  payload: CreateUpdatePathologyGroupRequest
): Promise<PathologyGroupResponse> {
  const result = await api.post<PathologyGroupResponse>(
    `${ROSTER_PATHOLOGY_GROUP_BASE_URL}`,
    payload
  );
  return result.data;
}

export async function updatePathologyGroup(
  payload: CreateUpdatePathologyGroupRequest
): Promise<PathologyGroupResponse> {
  const result = await api.put<PathologyGroupResponse>(
    `${ROSTER_PATHOLOGY_GROUP_BASE_URL}/${payload.pathologyGroupId}`,
    payload
  );
  return result.data;
}

export async function removePathologyGroup(
  pathologyGroupId: number
): Promise<boolean> {
  const result = await api.delete<boolean>(
    `${ROSTER_PATHOLOGY_GROUP_BASE_URL}/${pathologyGroupId}`
  );
  return result.data;
}

export async function reorderPathologyGroups(
  payload: UpdatePathologyGroupsParentPositionRequest
): Promise<PathologyGroupResponse[]> {
  const result = await api.put<PathologyGroupResponse[]>(
    `${ROSTER_PATHOLOGY_GROUP_BASE_URL}/Positions`,
    payload
  );
  return result.data;
}

export async function checkPathologyGroup(
  payload: CheckPathologyGroupRequest
): Promise<PathologyGroupResponse[]> {
  const result = await api.put<PathologyGroupResponse[]>(
    `${ROSTER_PATHOLOGY_GROUP_BASE_URL}/Check/${payload.pathologyGroupId}`,
    payload
  );
  return result.data;
}
