const WORKPLACES_PREFIX = 'WORKPLACES';

export const SET_WORKPLACE_LIST = `${WORKPLACES_PREFIX}/SET_WORKPLACE_LIST`;
export const GET_WORKPLACE_LIST = `${WORKPLACES_PREFIX}/GET_WORKPLACE_LIST`;
export const CREATE_WORKPLACE = `${WORKPLACES_PREFIX}/CREATE_WORKPLACE`;
export const UPDATE_WORKPLACE = `${WORKPLACES_PREFIX}/UPDATE_WORKPLACE`;
export const REMOVE_WORKPLACE = `${WORKPLACES_PREFIX}/REMOVE_WORKPLACE`;
export const REORDER_WORKPLACE_LIST = `${WORKPLACES_PREFIX}/REORDER_WORKPLACE_LIST`;
export const TOGGLE_WORKPLACE_ACTIVITY_WORKPLACE = `${WORKPLACES_PREFIX}/TOGGLE_WORKPLACE_ACTIVITY_WORKPLACE`;
export const GET_WORKPLACE_DETAIL = `${WORKPLACES_PREFIX}/GET_WORKPLACE_DETAIL`;
export const SET_WORKPLACE_DETAIL = `${WORKPLACES_PREFIX}/SET_WORKPLACE_DETAIL`;
export const GET_WORKPLACE_OPERATING_THEATRES = `${WORKPLACES_PREFIX}/GET_WORKPLACE_OPERATING_THEATRES`;
export const SET_WORKPLACE_OPERATING_THEATRES = `${WORKPLACES_PREFIX}/SET_WORKPLACE_OPERATING_THEATRES`;
export const GET_WORKPLACE_ROTATION_WORKPLACES = `${WORKPLACES_PREFIX}/GET_WORKPLACE_ROTATION_WORKPLACES`;
export const SET_WORKPLACE_ROTATION_WORKPLACES = `${WORKPLACES_PREFIX}/SET_WORKPLACE_ROTATION_WORKPLACES`;

export interface WorkplaceState {
  workplaceList: WorkplaceResponse[];
  workplace: WorkplaceDetailResponse | null;
  workplaceOperatingTheatres: WorkplaceResponse[];
  workplaceRotationWorkplaces: WorkplaceResponse[];
}
interface GetWorkplaceList {
  type: typeof GET_WORKPLACE_LIST;
  payload: any;
}
interface SetWorkplaceList {
  type: typeof SET_WORKPLACE_LIST;
  payload: WorkplaceResponse[];
}
interface GetWorkplace {
  type: typeof GET_WORKPLACE_DETAIL;
  payload: number;
}
interface SetWorkplace {
  type: typeof SET_WORKPLACE_DETAIL;
  payload: WorkplaceDetailResponse | null;
}
interface CreateWorkplace {
  type: typeof CREATE_WORKPLACE;
  payload: CreateUpdateWorkplaceRequest;
  meta: any;
}
interface UpdateWorkplace {
  type: typeof UPDATE_WORKPLACE;
  payload: CreateUpdateWorkplaceRequest;
  meta: any;
}
interface RemoveWorkplace {
  type: typeof REMOVE_WORKPLACE;
  payload: number;
}
interface ReorderWorkplaces {
  type: typeof REORDER_WORKPLACE_LIST;
  payload: UpdateWorkplacesParentPositionRequest;
}

interface ToggleWorkplaceActivityWorkplace {
  type: typeof TOGGLE_WORKPLACE_ACTIVITY_WORKPLACE;
  payload: CheckWorkplaceRequest;
  meta: any;
}

interface GetWorkplaceOperatingTheatres {
  type: typeof GET_WORKPLACE_OPERATING_THEATRES;
  payload: null;
}
interface SetWorkplaceOperatingTheatres {
  type: typeof SET_WORKPLACE_OPERATING_THEATRES;
  payload: WorkplaceResponse[];
}
interface GetWorkplaceRotationWorkplaces {
  type: typeof GET_WORKPLACE_ROTATION_WORKPLACES;
  payload: null;
}
interface SetWorkplaceRotationWorkplaces {
  type: typeof SET_WORKPLACE_ROTATION_WORKPLACES;
  payload: WorkplaceResponse[];
}

export type WorkplaceActionTypes =
  | GetWorkplaceList
  | SetWorkplaceList
  | GetWorkplace
  | SetWorkplace
  | CreateWorkplace
  | UpdateWorkplace
  | RemoveWorkplace
  | ReorderWorkplaces
  | ToggleWorkplaceActivityWorkplace
  | GetWorkplaceOperatingTheatres
  | SetWorkplaceOperatingTheatres
  | GetWorkplaceRotationWorkplaces
  | SetWorkplaceRotationWorkplaces;
