const REPORTS_PREFIX = 'REPORTS';

export const GET_REPORT_VERSIONS = `${REPORTS_PREFIX}/GET_REPORT_VERSIONS`;
export const SET_REPORT_VERSIONS = `${REPORTS_PREFIX}/SET_REPORT_VERSIONS`;
export const GET_REPORT = `${REPORTS_PREFIX}/GET_REPORT`;
export const SET_REPORT = `${REPORTS_PREFIX}/SET_REPORT`;

export const GET_CUSTOM_REPORT_LIST = `${REPORTS_PREFIX}/GET_CUSTOM_REPORT_LIST`;
export const SET_CUSTOM_REPORT_LIST = `${REPORTS_PREFIX}/SET_CUSTOM_REPORT_LIST`;
export const GET_CUSTOM_REPORTS_BY_TEMPLATE = `${REPORTS_PREFIX}/GET_CUSTOM_REPORTS_BY_TEMPLATE`;
export const SET_CUSTOM_REPORTS_BY_TEMPLATE = `${REPORTS_PREFIX}/SET_CUSTOM_REPORTS_BY_TEMPLATE`;
export const GET_CUSTOM_REPORT_VERSIONS = `${REPORTS_PREFIX}/GET_CUSTOM_REPORT_VERSIONS`;
export const SET_CUSTOM_REPORT_VERSIONS = `${REPORTS_PREFIX}/SET_CUSTOM_REPORT_VERSIONS`;
export const GET_CUSTOM_REPORT_VERSION = `${REPORTS_PREFIX}/GET_CUSTOM_REPORT_VERSION`;
export const SET_CUSTOM_REPORT_VERSION = `${REPORTS_PREFIX}/SET_CUSTOM_REPORT_VERSION`;

export interface ReportsState {
  reportVersions: RosterDateVersionResponse[];
  report: string;
  versionNotesReport: string;
  customReport: string;
  customReports: CustomReportResponse[];
  customReportsByTemplate: CustomReportResponse[];
  customReportVersions: CustomReportVersionResponse[];
}

interface GetReportVersions {
  type: typeof GET_REPORT_VERSIONS;
  payload: VersionRequest;
}

interface SetReportVersions {
  type: typeof SET_REPORT_VERSIONS;
  payload: GroupedRosterVersionResponse;
}

interface GetReport {
  type: typeof GET_REPORT;
  payload: ReportRequest;
}

interface SetReport {
  type: typeof SET_REPORT;
  payload: string;
}
interface GetCustomReportList {
  type: typeof GET_CUSTOM_REPORT_LIST;
  payload: null;
}
interface SetCustomReportList {
  type: typeof SET_CUSTOM_REPORT_LIST;
  payload: CustomReportResponse[];
}
interface GetCustomReportsByTemplate {
  type: typeof GET_CUSTOM_REPORTS_BY_TEMPLATE;
  payload: number;
}
interface SetCustomReportsByTemplate {
  type: typeof SET_CUSTOM_REPORTS_BY_TEMPLATE;
  payload: CustomReportResponse[];
}
interface GetCustomReportVersions {
  type: typeof GET_CUSTOM_REPORT_VERSIONS;
  payload: CustomReportVersionsRequest;
  meta: any;
}
interface SetCustomReportVersions {
  type: typeof SET_CUSTOM_REPORT_VERSIONS;
  payload: CustomReportVersionResponse[];
}
interface GetCustomReportVersion {
  type: typeof GET_CUSTOM_REPORT_VERSION;
  payload: CustomReportVersionRequest;
}
interface SetCustomReportVersion {
  type: typeof SET_CUSTOM_REPORT_VERSION;
  payload: string;
}

export type ReportsActionTypes =
  | GetReportVersions
  | SetReportVersions
  | GetReport
  | SetReport
  | GetCustomReportList
  | SetCustomReportList
  | GetCustomReportsByTemplate
  | SetCustomReportsByTemplate
  | GetCustomReportVersions
  | SetCustomReportVersions
  | GetCustomReportVersion
  | SetCustomReportVersion;
