import { toast } from 'react-toastify';
import { all, call, put, throttle, race, take } from 'redux-saga/effects';

import * as workplaceActivityApi from '../../../api/modules/workplace-activity';
import {
  setWorkplaceActivity,
  setCancelReasons,
  setSummaryInfoTab,
} from './actions';
import {
  CREATE_WORKPLACE_ACTIVITY,
  GET_WORKPLACE_ACTIVITY,
  REMOVE_WORKPLACE_ACTIVITY,
  UPDATE_WORKPLACE_ACTIVITY,
  CANCEL_WORKPLACE_ACTIVITY,
  CANCEL_OCCURRENCE_WORKPLACE_ACTIVITY,
  GET_CANCEL_REASONS,
  GET_SUMMARY_INFO_TAB,
} from './types';
import { CANCEL_TASKS } from '../../common/types';
import generalErrorHandling from '../../../utilities/general-error-handling';

function* getWorkplaceActivityGenerator() {
  try {
    const result = yield call(workplaceActivityApi.getWorkPlaceActivity);
    const formatted = formatWorkplaceActivityResponse(result);
    yield put(setWorkplaceActivity(formatted));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* createWorkplaceActivityGenerator({ payload }: any) {
  try {
    yield call(workplaceActivityApi.createWorkPlaceActivity, payload);
    yield getWorkplaceActivityGenerator();
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* updateWorkplaceActivityGenerator({ payload }: any) {
  try {
    yield call(workplaceActivityApi.updateWorkPlaceActivity, payload);
    yield getWorkplaceActivityGenerator();
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* removeWorkplaceActivityGenerator({ payload }: any) {
  try {
    yield call(workplaceActivityApi.removeWorkPlaceActivity, payload);
    yield getWorkplaceActivityGenerator();
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getCancelReasonsGenerator() {
  try {
    const result = yield call(workplaceActivityApi.getCancelReasons);
    yield put(setCancelReasons(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* cancelWorkplaceActivityGenerator({ payload, meta }: any) {
  try {
    yield call(workplaceActivityApi.cancelWorkplaceActivity, payload);
    yield getCancelReasonsGenerator();
    yield call(meta);
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* cancelOccurrenceWorkplaceActivity({ payload, meta }: any) {
  try {
    yield call(workplaceActivityApi.cancelOccurrenceWorkplaceActivity, payload);
    yield getCancelReasonsGenerator();
    yield call(meta);
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

export function* getSummaryInfoTabsGenerator({ payload }: any) {
  try {
    const result = yield call(workplaceActivityApi.getSummaryInfoTab, payload);
    yield put(setSummaryInfoTab(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

export function* workplaceActivityRootSagas() {
  return yield race([
    take(CANCEL_TASKS),
    all([
      throttle(100, GET_WORKPLACE_ACTIVITY, getWorkplaceActivityGenerator),
      throttle(
        100,
        CREATE_WORKPLACE_ACTIVITY,
        createWorkplaceActivityGenerator
      ),
      throttle(
        100,
        UPDATE_WORKPLACE_ACTIVITY,
        updateWorkplaceActivityGenerator
      ),
      throttle(
        100,
        REMOVE_WORKPLACE_ACTIVITY,
        removeWorkplaceActivityGenerator
      ),
      throttle(
        100,
        CANCEL_WORKPLACE_ACTIVITY,
        cancelWorkplaceActivityGenerator
      ),
      throttle(
        500,
        CANCEL_OCCURRENCE_WORKPLACE_ACTIVITY,
        cancelOccurrenceWorkplaceActivity
      ),
      throttle(100, GET_CANCEL_REASONS, getCancelReasonsGenerator),
      throttle(100, GET_SUMMARY_INFO_TAB, getSummaryInfoTabsGenerator),
    ]),
  ]);
}

// helpers
function formatWorkplaceActivityResponse(result: any) {
  return result.map((wa: WorkplaceActivityResponse) => {
    return {
      id: wa.id,
      subject: wa.subject,
      startTime: new Date(wa.startTime),
      endTime: new Date(wa.endTime),
      isAllDay: wa.isAllDay ?? false,
      description: wa.description ?? '',
      startTimezone: wa.startTimezone ?? undefined,
      endTimezone: wa.endTimezone ?? undefined,
      recurrenceExceptions:
        wa.recurrenceExceptions && wa.recurrenceExceptions.length
          ? (wa.recurrenceExceptions as string[]).map((re) => new Date(re))
          : null,
      recurrenceId: wa.recurrenceId ?? null,
      recurrenceRule: wa.recurrenceRule ? wa.recurrenceRule : null,

      workplaceId: wa.workplaceId,
    } as WorkplaceActivityResponse;
  });
}
