import { all, call, put, throttle, race, take } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { GET_DOCTOR_SKILLS, UPDATE_DOCTOR_SKILLS } from './types';

import * as doctorSkillsApi from '../../../api/modules/doctor-skills';
import { setDoctorSkills } from './actions';
import { CANCEL_TASKS } from '../../common/types';
import generalErrorHandling from '../../../utilities/general-error-handling';

function* getDoctorSkillsGenerator() {
  try {
    const result = yield call(doctorSkillsApi.getDoctorSkills);
    yield put(setDoctorSkills(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* updateDoctorSkillsGenerator({ payload }: any) {
  try {
    const result = yield call(doctorSkillsApi.updateDoctorSkills, payload);

    yield put(setDoctorSkills(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

export function* doctorSkillRootSagas() {
  return yield race([
    take(CANCEL_TASKS),
    all([
      throttle(100, GET_DOCTOR_SKILLS, getDoctorSkillsGenerator),
      throttle(100, UPDATE_DOCTOR_SKILLS, updateDoctorSkillsGenerator),
    ]),
  ]);
}
