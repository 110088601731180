const SKILLS_PREFIX = 'SKILLS';

export const REORDER_SKILLS = `${SKILLS_PREFIX}/REORDER_SKILLS`;
export const GET_SKILL_LIST = `${SKILLS_PREFIX}/GET_SKILL_LIST`;
export const SET_SKILL_LIST = `${SKILLS_PREFIX}/SET_SKILL_LIST`;
export const TOGGLE_SKILL_STATE = `${SKILLS_PREFIX}/TOGGLE_SKILL_STATE`;

export interface SkillState {
  skillList: SkillResponse[];
}

interface ReorderSkills {
  type: typeof REORDER_SKILLS;
  payload: ReorderSkillsRequest;
  meta: { onSuccess: () => void };
}
interface GetSkills {
  type: typeof GET_SKILL_LIST;
  payload: null;
}
interface SetSkills {
  type: typeof SET_SKILL_LIST;
  payload: SkillResponse[];
}
interface ToggleSkillState {
  type: typeof TOGGLE_SKILL_STATE;
  payload: ToggleSkillStateRequest;
  meta(): void;
}

export type SkillsActionTypes =
  | ReorderSkills
  | GetSkills
  | SetSkills
  | ToggleSkillState;
