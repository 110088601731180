import * as React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useStore } from 'react-redux';
import { RootState } from '../store/index';
import { checkRoles } from './check-role';
import Unauthorized from '../views/unauthorized/Unauthorized';
import { isEmpty } from 'lodash';

interface IPrivateRouteProps extends RouteProps {
  roles?: UserRole[];
}

const PrivateRoute: React.FunctionComponent<IPrivateRouteProps> = ({
  children,
  roles,
  ...rest
}) => {
  const { oidc, user } = useStore<RootState>().getState();
  if (oidc.user && !oidc.user.expired) {
    const userRoles = oidc.user.profile['role'] as string[];
    const emptyPathRoles = isEmpty(roles);
    const allowAccess =
      emptyPathRoles || checkRoles(roles ?? [], userRoles, user.department);
    if (allowAccess) return <Route {...rest}>{children}</Route>;
    else return <Route component={Unauthorized}></Route>;
  }

  return (
    <Route
      render={({ location }) => (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      )}
    />
  );
};

export default PrivateRoute;
