import { call, throttle, all, put, race, take } from 'redux-saga/effects';
import { setAccessToken } from '../../api';
import { userFound } from 'redux-oidc';
import { setUserLanguage } from '../user/actions';
import { CANCEL_TASKS } from '../common/types';

function* setAccessTokenGenerator({ payload }: ReturnType<typeof userFound>) {
  if (payload) {
    yield call(setAccessToken, payload.access_token);
    yield put(setUserLanguage(payload.profile.language));
  }
}

export function* oidcRootSagas() {
  return yield race([
    take(CANCEL_TASKS),
    all([throttle(100, 'redux-oidc/USER_FOUND', setAccessTokenGenerator)]),
  ]);
}
