import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { CallbackComponent } from 'redux-oidc';
import userManager from '../../services/user-manager';
import { RouteComponentProps } from 'react-router-dom';
import { User } from 'oidc-client';
import { toast } from 'react-toastify';
import Loading from '../../components/loading/Loading';

type Props = RouteComponentProps;

class CallbackPage extends React.Component<PropsWithChildren<Props>> {
  redirect = async (user: User) => {
    // save user to store
    await userManager.storeUser(user);
    // check if state is empty
    const state = this.props.location.state as { from: Location } | null;
    if (!state) this.props.history.replace('/select-department');
    else {
      this.props.history.replace(state.from);
    }
  };
  error = (error: Error) => {
    toast.error(error.message);
  };
  render() {
    // just redirect to '/' in both cases
    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={this.redirect}
        errorCallback={this.error}
      >
        <Loading ignoreStore />
      </CallbackComponent>
    );
  }
}

export default connect()(CallbackPage);
