import {
  all,
  call,
  delay,
  put,
  throttle,
  race,
  take,
} from 'redux-saga/effects';
import * as pathologyGroupApi from '../../../api/modules/pathology-group';
import { toast } from 'react-toastify';
import mapValidationErrors from '../../../utilities/map-validation-errors';
import { setPathologyGroupList, setPathologyGroup } from './actions';
import {
  GET_PATHOLOGY_GROUP_LIST,
  CREATE_PATHOLOGY_GROUP,
  UPDATE_PATHOLOGY_GROUP,
  REMOVE_PATHOLOGY_GROUP,
  REORDER_PATHOLOGY_GROUP_LIST,
  TOGGLE_PATHOLOGY_GROUP,
  GET_PATHOLOGY_GROUP,
} from './types';
import { CANCEL_TASKS } from '../../common/types';
import generalErrorHandling from '../../../utilities/general-error-handling';

function* setPathologyGroupListGenerator() {
  try {
    const list = yield call(pathologyGroupApi.getPathologyGroupList);
    yield put(setPathologyGroupList(list));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* createPathologyGroupGenerator({ payload, meta }: any) {
  yield delay(300);
  const { resetForm, setErrors, setSubmitting } = meta;
  try {
    yield call(pathologyGroupApi.createPathologyGroup, payload);
    yield setPathologyGroupListGenerator();
    yield call(resetForm);
  } catch (err) {
    if (err && err.length) {
      const errors: any = mapValidationErrors(err);
      yield call(setErrors, errors);
    } else {
      yield call(toast.error, generalErrorHandling(err));
    }
  }
  yield call(setSubmitting, false);
}

function* updatePathologyGroupGenerator({ payload, meta }: any) {
  const { setErrors, setSubmitting } = meta;
  try {
    yield call(pathologyGroupApi.updatePathologyGroup, payload);
    yield setPathologyGroupListGenerator();
  } catch (err) {
    if (err && err.length) {
      const errors: any = mapValidationErrors(err);
      yield call(setErrors, errors);
    } else {
      yield call(toast.error, generalErrorHandling(err));
    }
  }
  yield call(setSubmitting, false);
}

function* removePathologyGroupGenerator({ payload }: any) {
  try {
    yield call(pathologyGroupApi.removePathologyGroup, payload);
    yield setPathologyGroupListGenerator();
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* reorderPathologyGroupsGenerator({ payload }: any) {
  try {
    const response = yield call(
      pathologyGroupApi.reorderPathologyGroups,
      payload
    );
    yield put(setPathologyGroupList(response));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* togglePathologyGroupGenerator({ payload, meta }: any) {
  try {
    yield call(pathologyGroupApi.checkPathologyGroup, payload);
    yield setPathologyGroupListGenerator();
    yield call(meta);
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getPathologyGroupGenerator({ payload }: any) {
  try {
    const result = yield call(pathologyGroupApi.getPathologyGroup, payload);
    yield put(setPathologyGroup(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

export function* pathologyGroupRootSagas() {
  return yield race([
    take(CANCEL_TASKS),
    all([
      throttle(100, GET_PATHOLOGY_GROUP_LIST, setPathologyGroupListGenerator),
      throttle(100, CREATE_PATHOLOGY_GROUP, createPathologyGroupGenerator),
      throttle(100, UPDATE_PATHOLOGY_GROUP, updatePathologyGroupGenerator),
      throttle(100, REMOVE_PATHOLOGY_GROUP, removePathologyGroupGenerator),
      throttle(
        100,
        REORDER_PATHOLOGY_GROUP_LIST,
        reorderPathologyGroupsGenerator
      ),
      throttle(100, TOGGLE_PATHOLOGY_GROUP, togglePathologyGroupGenerator),
      throttle(100, GET_PATHOLOGY_GROUP, getPathologyGroupGenerator),
    ]),
  ]);
}
