import { call, all, throttle, put, race, take } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as skillsApi from '../../../api/modules/skills';
import { REORDER_SKILLS, GET_SKILL_LIST, TOGGLE_SKILL_STATE } from './types';
import { setSkills } from './actions';
import { CANCEL_TASKS } from '../../common/types';
import generalErrorHandling from '../../../utilities/general-error-handling';

function* reorderSkillsGenerator({ payload, meta }: any) {
  const { onSuccess } = meta;
  try {
    yield call(skillsApi.reorderSkills, payload);
    yield call(onSuccess);
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getSkillsGenerator() {
  try {
    const result = yield call(skillsApi.getSkills);
    yield put(setSkills(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* toggleSkillStateGenerator({ payload, meta }: any) {
  try {
    yield call(skillsApi.toggleSkillSelection, payload.id, payload.selected);
    yield getSkillsGenerator();
    yield call(meta);
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

export function* skillsRootSagas() {
  return yield race([
    take(CANCEL_TASKS),
    all([
      throttle(100, REORDER_SKILLS, reorderSkillsGenerator),
      throttle(100, GET_SKILL_LIST, getSkillsGenerator),
      throttle(100, TOGGLE_SKILL_STATE, toggleSkillStateGenerator),
    ]),
  ]);
}
