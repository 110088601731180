import {
  OperationsState,
  OperationsActionTypes,
  SET_PATIENT_BY_ID,
  SET_OPERATION_BY_CASE_ID,
  SET_AUTOCOMPLETE_VALUES,
  SET_WIZARD_STEP,
  SET_OPERATION_READY_PATIENTS,
  SET_CASE_EMPLOYEE,
  SET_OPERATION_SUMMARY_INFO,
} from './types';
import operation from '../../api/mockups/operations-mock.json';

const initialState: OperationsState = {
  operation: operation,
  autoCompleteValue: null,
  step: 1,
  operationReadyPatients: [],
  summaryInfo: [],
};

export function operationsReducer(
  state = initialState,
  action: OperationsActionTypes
): OperationsState {
  switch (action.type) {
    case SET_PATIENT_BY_ID:
      return {
        ...state,
        operation: {
          ...state.operation,
          patient: action.payload as PatientResponse,
        } as OperationResponse,
      };
    case SET_OPERATION_BY_CASE_ID:
      return {
        ...state,
        operation: action.payload as OperationResponse,
      };
    case SET_AUTOCOMPLETE_VALUES:
      return {
        ...state,
        autoCompleteValue: action.payload as OperationAutocompleteResponse,
      };
    case SET_WIZARD_STEP:
      return {
        ...state,
        step: action.payload as number,
      };
    case SET_OPERATION_READY_PATIENTS:
      return {
        ...state,
        operationReadyPatients:
          action.payload as OperationReadyPatientResponse[],
      };
    case SET_CASE_EMPLOYEE:
      if (state.operation?.case && action.payload) {
        return {
          ...state,
          operation: {
            ...state.operation,
            case: {
              ...state.operation?.case,
              employee: action.payload as EmployeeShortResponse,
            },
          },
        };
      }
      return state;
    case SET_OPERATION_SUMMARY_INFO:
      return {
        ...state,
        summaryInfo: action.payload as SummaryInfoTabResponse[],
      };
    default:
      return state;
  }
}
