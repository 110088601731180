import {
  REORDER_SKILLS,
  SkillsActionTypes,
  GET_SKILL_LIST,
  SET_SKILL_LIST,
  TOGGLE_SKILL_STATE,
} from './types';

export function reorderSkills(
  payload: ReorderSkillsRequest,
  meta: { onSuccess: () => void }
): SkillsActionTypes {
  return {
    type: REORDER_SKILLS,
    payload: payload,
    meta: meta,
  };
}

export function getSkills(): SkillsActionTypes {
  return {
    type: GET_SKILL_LIST,
    payload: null,
  };
}

export function setSkills(payload: SkillResponse[]): SkillsActionTypes {
  return {
    type: SET_SKILL_LIST,
    payload: payload,
  };
}

export function toggleSkillState(
  payload: ToggleSkillStateRequest,
  meta: () => void
): SkillsActionTypes {
  return {
    type: TOGGLE_SKILL_STATE,
    payload: payload,
    meta: meta,
  };
}
