import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router';
import { Switch, Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';

//store
import { Provider } from 'react-redux';
import configureStore from './services/store';

//views
const App = React.lazy(() => import('./views/app/App'));
const NotFound = React.lazy(() => import('./views/not-found/NotFound'));
const Login = React.lazy(() => import('./views/login/Login'));
const DepartmentSelection = React.lazy(
  () => import('./views/department-selection/DepartmentSelection')
);
const Unauthorized = React.lazy(
  () => import('./views/unauthorized/Unauthorized')
);

// styles
import './index.scss';

// js
import 'bootstrap';

// utils
import PrivateRoute from './utilities/PrivateRoute';

// Oidc
import CallBack from './views/callback/CallBack';
import userManager from './services/user-manager';
import { OidcProvider } from 'redux-oidc';
import { SilentRenewComponent } from './views/silent-renew-component/SilentRenewComponent';

// Translations
import './localization/i18n';

// Syncfusion localization
import { L10n, setCurrencyCode } from '@syncfusion/ej2-base';
import slSy from './localization/languages/sl-sy.json';
import Loading from './components/loading/Loading';
import { useTranslation } from 'react-i18next';
import { injectStore } from './api';
import { registerLicense } from '@syncfusion/ej2-base';
// import reportWebVitals from './reportWebVitals';
import { Log } from 'oidc-client';

registerLicense(process.env.REACT_APP_SYNCFUSION_LICENCE ?? '');

Log.logger = console;

L10n.load({
  sl: slSy,
});
setCurrencyCode('EUR');

const store = configureStore();
injectStore(store);

const history = createBrowserHistory();

function RootView() {
  const { t } = useTranslation();
  const [offline, setOffline] = React.useState(false);

  const offlineListener = () => {
    setOffline(true);
  };
  const onlineListener = () => {
    setOffline(false);
    window.location.reload();
  };

  React.useEffect(() => {
    window.addEventListener('offline', offlineListener);
    window.addEventListener('online', onlineListener);
    return () => {
      window.removeEventListener('offline', offlineListener);
      window.addEventListener('online', onlineListener);
    };
  }, []);

  if (offline) {
    return (
      <div
        style={{ width: '100vw', height: '100vh' }}
        className="d-flex align-items-center justify-content-center"
      >
        <h1>{t('GENERAL.APPLICATION_CAN_BE_USED_ONLY_WITH_INTERNET')}</h1>
      </div>
    );
  }
  return (
    <Provider store={store}>
      <OidcProvider store={store} userManager={userManager}>
        <Suspense fallback={<Loading ignoreStore />}>
          <Router history={history}>
            <Switch>
              <Route path="/" exact>
                <Redirect to="/login" />
              </Route>
              <Route path="/callback" component={CallBack} />
              <Route path="/login" component={Login} />
              <PrivateRoute
                path="/select-department"
                component={DepartmentSelection}
              />
              <Route path="/app" component={App} />
              <Route path="/unauthorized" component={Unauthorized} />
              <Route
                component={SilentRenewComponent}
                path="/silent_renew"
                exact
              />
              <Route path="*" component={NotFound} />
            </Switch>
          </Router>
        </Suspense>
      </OidcProvider>
    </Provider>
  );
}

ReactDOM.render(
  // <React.StrictMode>
  <RootView />,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

// reportWebVitals(console.log);
