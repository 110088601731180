import {
  RosterTemplateActionTypes,
  SET_ROSTER_TEMPLATE_LIST,
  CREATE_ROSTER_TEMPLATE,
  UPDATE_ROSTER_TEMPLATE,
  REMOVE_ROSTER_TEMPLATE,
  GET_ROSTER_TEMPLATE,
  SET_ROSTER_TEMPLATE,
  ADD_ROSTER_WORKPLACE,
  UPDATE_ROSTER_WORKPLACE,
  REMOVE_ROSTER_WORKPLACE,
  DUPLICATE_ROSTER_TEMPLATE,
  REORDER_ROSTER_TEMPLATE_WORKPLACE_POSITIONS,
  REORDER_ROSTER_TEMPLATE_POSITIONS,
  GET_TEMPLATE_TABLE,
  SET_TEMPLATE_TABLE,
  UPDATE_TEMPLATE_TABLE,
  GET_ROSTER_SUMMARY_INFO,
  SET_ROSTER_SUMMARY_INFO,
  GET_ROSTER_TEMPLATE_SUMMARY_INFO,
  SET_ROSTER_TEMPLATE_SUMMARY_INFO,
  ADD_ROSTER_WORKPLACES,
  REMOVE_ROSTER_WORKPLACES,
} from './types';

export function setRosterTemplates(
  payload: BaseRosterTemplateResponse[]
): RosterTemplateActionTypes {
  return {
    type: SET_ROSTER_TEMPLATE_LIST,
    payload: payload,
  };
}

export function setRosterTemplate(
  payload: CreateUpdateRosterTemplate
): RosterTemplateActionTypes {
  return {
    type: SET_ROSTER_TEMPLATE,
    payload: payload,
  };
}

export function getRosterTemplate(payload: number): RosterTemplateActionTypes {
  return {
    type: GET_ROSTER_TEMPLATE,
    payload: payload,
  };
}

export function createRosterTemplate(
  payload: CreateUpdateRosterTemplate,
  meta: any
): RosterTemplateActionTypes {
  return {
    type: CREATE_ROSTER_TEMPLATE,
    payload: payload,
    meta: meta,
  };
}

export function duplicateRosterTemplate(
  payload: number
): RosterTemplateActionTypes {
  return {
    type: DUPLICATE_ROSTER_TEMPLATE,
    payload: payload,
  };
}

export function updateRosterTemplate(
  payload: CreateUpdateRosterTemplate,
  meta: any
): RosterTemplateActionTypes {
  return {
    type: UPDATE_ROSTER_TEMPLATE,
    payload: payload,
    meta: meta,
  };
}

export function removeRosterTemplate(
  payload: number
): RosterTemplateActionTypes {
  return {
    type: REMOVE_ROSTER_TEMPLATE,
    payload: payload,
  };
}

export function addWorkplaceToRoster(
  payload: AddUpdateWorkplaceToRosterTemplateRequest
): RosterTemplateActionTypes {
  return {
    type: ADD_ROSTER_WORKPLACE,
    payload: payload,
  };
}
export function addWorkplacesToRoster(
  payload: AddUpdateWorkplacesToRosterTemplateRequest
): RosterTemplateActionTypes {
  return {
    type: ADD_ROSTER_WORKPLACES,
    payload: payload,
  };
}

export function updateRosterWorkplace(
  payload: AddUpdateWorkplaceToRosterTemplateRequest,
  meta: any
): RosterTemplateActionTypes {
  return {
    type: UPDATE_ROSTER_WORKPLACE,
    payload: payload,
    meta: meta,
  };
}

export function removeRosterWorkplace(
  payload: RemoveRosterTemplateWorkplacePositionsRequest
): RosterTemplateActionTypes {
  return {
    type: REMOVE_ROSTER_WORKPLACE,
    payload: payload,
  };
}

export function removeRosterWorkplaces(
  payload: RemoveRosterTemplateWorkplacesPositionsRequest
): RosterTemplateActionTypes {
  return {
    type: REMOVE_ROSTER_WORKPLACES,
    payload: payload,
  };
}

export function reorderRosterTemplateWorkplacePositions(
  payload: ReorderRosterTemplateWorkplacePositionsRequest
): RosterTemplateActionTypes {
  return {
    type: REORDER_ROSTER_TEMPLATE_WORKPLACE_POSITIONS,
    payload: payload,
  };
}

export function reorderRosterTemplatePositions(
  payload: ReorderRosterTemplatePositionsRequest
): RosterTemplateActionTypes {
  return {
    type: REORDER_ROSTER_TEMPLATE_POSITIONS,
    payload: payload,
  };
}

export function getTemplateTable(
  payload: RosterTemplateRequest
): RosterTemplateActionTypes {
  return {
    type: GET_TEMPLATE_TABLE,
    payload: payload,
  };
}

export function updateTemplateTableCell(
  payload: UpdateTemplateTableRequest
): RosterTemplateActionTypes {
  return {
    type: UPDATE_TEMPLATE_TABLE,
    payload: payload,
  };
}

export function setTemplateTable(
  payload: TemplateTableResponse | null
): RosterTemplateActionTypes {
  return {
    type: SET_TEMPLATE_TABLE,
    payload: payload,
  };
}
export function getRosterSummaryInfo(
  payload: RosterSummaryInfoRequest
): RosterTemplateActionTypes {
  return {
    type: GET_ROSTER_SUMMARY_INFO,
    payload,
  };
}
export function setRosterSummaryInfo(
  payload: SummaryInfoTabResponse[]
): RosterTemplateActionTypes {
  return {
    type: SET_ROSTER_SUMMARY_INFO,
    payload,
  };
}
export function getRosterTemplateSummaryInfo(
  payload: RosterTemplateSummaryInfoRequest
): RosterTemplateActionTypes {
  return {
    type: GET_ROSTER_TEMPLATE_SUMMARY_INFO,
    payload,
  };
}
export function setRosterTemplateSummaryInfo(
  payload: SummaryInfoTabResponse[]
): RosterTemplateActionTypes {
  return {
    type: SET_ROSTER_TEMPLATE_SUMMARY_INFO,
    payload,
  };
}
