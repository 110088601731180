import {
  SchedulersActionTypes,
  GET_ROSTERS,
  SET_ROSTERS,
  GET_ROSTER,
  SET_ROSTER,
  GET_ROSTER_PLANS,
  SET_ROSTER_PLANS,
  UPDATE_ROSTER_CELL,
  GET_ROSTER_VERSIONS,
  SET_ROSTER_VERSIONS,
  GET_ROSTER_VERSION,
  SIGN_ROSTER_VERSION,
  RESET_ROSTER,
  GET_SIGN_REASONS,
  SET_SIGN_REASONS,
  GET_VERSION_NOTES,
  SET_VERSION_NOTES,
  UPDATE_ROSTER_CELLS,
  APPLY_ROSTER_TEMPLATE_CELLS,
} from './types';

export function getRosters(): SchedulersActionTypes {
  return {
    type: GET_ROSTERS,
    payload: null,
  };
}

export function setRosters(
  payload: BaseRosterTemplateResponse[]
): SchedulersActionTypes {
  return {
    type: SET_ROSTERS,
    payload: payload,
  };
}

export function getRoster(payload: RosterRequest): SchedulersActionTypes {
  return {
    type: GET_ROSTER,
    payload: payload,
  };
}

export function setRoster(
  payload: TemplateTableResponse
): SchedulersActionTypes {
  return {
    type: SET_ROSTER,
    payload: payload,
  };
}

export function getRosterPlans(): SchedulersActionTypes {
  return {
    type: GET_ROSTER_PLANS,
    payload: null,
  };
}

export function setRosterPlans(payload: PlanResponse[]): SchedulersActionTypes {
  return {
    type: SET_ROSTER_PLANS,
    payload: payload,
  };
}

export function updateRosterCell(
  payload: UpdateRosterCellRequest,
  meta: any
): SchedulersActionTypes {
  return {
    type: UPDATE_ROSTER_CELL,
    payload: payload,
    meta,
  };
}

export function updateRosterCells(
  payload: UpdateRosterCellsRequest,
  meta: any
): SchedulersActionTypes {
  return {
    type: UPDATE_ROSTER_CELLS,
    payload: payload,
    meta,
  };
}

export function getRosterVersions(
  payload: VersionRequest
): SchedulersActionTypes {
  return {
    type: GET_ROSTER_VERSIONS,
    payload: payload,
  };
}

export function setRosterVersions(
  payload: RosterVersionResponse[]
): SchedulersActionTypes {
  return {
    type: SET_ROSTER_VERSIONS,
    payload: payload,
  };
}

export function getRosterVersion(
  payload: GetRosterVersionRequest
): SchedulersActionTypes {
  return {
    type: GET_ROSTER_VERSION,
    payload: payload,
  };
}

export function signRosterVersion(
  payload: SignRosterTemplateRequest,
  meta: any
): SchedulersActionTypes {
  return {
    type: SIGN_ROSTER_VERSION,
    payload: payload,
    meta,
  };
}

export function resetRoster(
  payload: ResetRosterTemplateRequest
): SchedulersActionTypes {
  return {
    type: RESET_ROSTER,
    payload: payload,
  };
}

export function applyTemplateCells(
  payload: ApplyTemplateCellsRequest
): SchedulersActionTypes {
  return {
    type: APPLY_ROSTER_TEMPLATE_CELLS,
    payload: payload,
  };
}

export function getSignReasons(): SchedulersActionTypes {
  return {
    type: GET_SIGN_REASONS,
    payload: null,
  };
}

export function setSignReasons(payload: string[]): SchedulersActionTypes {
  return {
    type: SET_SIGN_REASONS,
    payload: payload,
  };
}

export function getVersionNotes(
  payload: VersionNotesRequest
): SchedulersActionTypes {
  return {
    type: GET_VERSION_NOTES,
    payload: payload,
  };
}

export function setVersionNotes(payload: string): SchedulersActionTypes {
  return {
    type: SET_VERSION_NOTES,
    payload: payload,
  };
}
