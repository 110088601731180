import { call, all, throttle, put, race, take } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as availabilitiesApi from '../../../api/modules/availabilities';
import { REORDER_AVAILABILITIES } from './types';
import { CANCEL_TASKS } from '../../common/types';
import generalErrorHandling from '../../../utilities/general-error-handling';

function* reorderAvailabilitiesGenerator({ payload, meta }: any) {
  const { onSuccess } = meta;
  try {
    yield call(availabilitiesApi.reorderAvailabilities, payload);
    yield call(onSuccess);
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

export function* availabilitiesRootSagas() {
  return yield race([
    take(CANCEL_TASKS),
    all([
      throttle(100, REORDER_AVAILABILITIES, reorderAvailabilitiesGenerator),
    ]),
  ]);
}
