import {
  GET_DOCTOR_QUALIFICATIONS,
  SET_DOCTOR_QUALIFICATIONS,
  UPDATE_DOCTOR_QUALIFICATIONS,
  DoctorQualificationActionTypes,
} from './types';

export function getDoctorQualifications(): DoctorQualificationActionTypes {
  return {
    type: GET_DOCTOR_QUALIFICATIONS,
    payload: null,
  };
}

export function updateDoctorQualifications(
  payload: UpdateDoctorQualificationsRequest
): DoctorQualificationActionTypes {
  return {
    type: UPDATE_DOCTOR_QUALIFICATIONS,
    payload: payload,
  };
}

export function setDoctorQualifications(
    payload: DoctorQualificationsResponse
  ): DoctorQualificationActionTypes {
    return {
      type: SET_DOCTOR_QUALIFICATIONS,
      payload: payload,
    };
  }
  
