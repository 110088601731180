import * as React from 'react';
import './Loading.scss';
import { RootState } from '../../store/index';
import { connect, ConnectedProps } from 'react-redux';

const mapState2Props = (state: RootState) => ({
  isLoading: state.common.isLoading,
});

const connector = connect(mapState2Props);

interface ILoadingProps extends ConnectedProps<typeof connector> {
  ignoreStore?: boolean;
  navBar?: boolean;
}

class Loading extends React.Component<ILoadingProps> {
  rootCss = this.props.navBar ? 'loading-nav' : 'loading';
  spinnerCss = this.props.navBar
    ? 'spinner-border spinner-border-sm text-primary'
    : 'spinner-border text-primary';

  public render() {
    if (this.props.isLoading || this.props.ignoreStore)
      return (
        <div className={this.rootCss}>
          <div className={this.spinnerCss} role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      );
    return null;
  }
}

export default connector(Loading);
