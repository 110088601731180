import api from '../index';

const QUALIFICATIONS_BASE_URL = 'api/v1/register/qualification';

export async function reorderQualifications(
  payload: ReorderQualificationsRequest
) {
  const result = await api.put(
    `${QUALIFICATIONS_BASE_URL}/Position/${payload.id}`,
    payload
  );
  return result.data;
}

export async function getQualifications(): Promise<QualificationResponse> {
  const result = await api.get<QualificationResponse>(QUALIFICATIONS_BASE_URL);
  return result.data;
}

export async function toggleQualificationSelection(
  id: number,
  selected: boolean
) {
  const result = await api.put(`${QUALIFICATIONS_BASE_URL}/Select/${id}`, {
    id,
    selected,
  });
  return result.data;
}
