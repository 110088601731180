const DOCTOR_SKILLS_PREFIX = 'DOCTOR_SKILLS';

export const GET_DOCTOR_SKILLS = `${DOCTOR_SKILLS_PREFIX}/GET_DOCTOR_SKILLS`;
export const SET_DOCTOR_SKILLS = `${DOCTOR_SKILLS_PREFIX}/SET_DOCTOR_SKILLS`;
export const UPDATE_DOCTOR_SKILLS = `${DOCTOR_SKILLS_PREFIX}/UPDATE_DOCTOR_SKILLS`;

export interface DoctorSkillState {
  doctorSkills: DoctorSkillsReponse | null;
}

interface GetDoctorSkills {
  type: typeof GET_DOCTOR_SKILLS;
  payload: null;
}

interface UpdateDoctorSkills {
  type: typeof UPDATE_DOCTOR_SKILLS;
  payload: UpdateDoctorSkillsRequest;
}

interface SetDoctorSkills {
  type: typeof SET_DOCTOR_SKILLS;
  payload: DoctorSkillsReponse;
}

export type DoctorSkillActionTypes =
  | GetDoctorSkills
  | UpdateDoctorSkills
  | SetDoctorSkills;
