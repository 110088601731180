import * as React from 'react';
import Security from '../../assets/images/undraw_security_o890.svg';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export default function Unauthorized(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();

  const goToFirstPage = () => {
    history.replace('/app/about');
  };

  return (
    <div id="unauthorized">
      <div className="row justify-content-center mt-5 no-gutters">
        <img src={Security} className="col col-md-8 col-lg-6" alt="not-found" />
      </div>
      <div className="row justify-content-center mt-5 no-gutters">
        <a href="#" className="btn btn-primary" onClick={goToFirstPage}>
          {t('GENERAL.BUTTON.BACK_TO_FIRST_PAGE')}
        </a>
      </div>
    </div>
  );
}
