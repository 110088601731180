import {
  OvertimeState,
  OvertimeActionTypes,
  SET_OVERTIME_TEAMS,
  SET_OVERTIME_OPERATION_BY_CASE_ID,
  SET_OVERTIME_PATIENT_BY_ID,
  SET_NZV_EMPLOYEE,
} from './types';

const initialState: OvertimeState = {
  operation: null,
  step: 0,
  teams: [],
};

export function overtimeReducer(
  state = initialState,
  action: OvertimeActionTypes
): OvertimeState {
  switch (action.type) {
    case SET_OVERTIME_TEAMS:
      return { ...state, teams: action.payload as OvertimeTeamsResponse[] };
    case SET_OVERTIME_OPERATION_BY_CASE_ID:
      return { ...state, operation: action.payload as OperationResponse };
    case SET_OVERTIME_PATIENT_BY_ID:
      return {
        ...state,
        operation: {
          ...state.operation,
          patient: action.payload as PatientResponse,
        } as OperationResponse,
      };
    case SET_NZV_EMPLOYEE:
      if (state.operation?.case && action.payload) {
        return {
          ...state,
          operation: {
            ...state.operation,
            case: {
              ...state.operation?.case,
              employee: action.payload as EmployeeShortResponse,
            },
          },
        };
      }
      return state;
    default:
      return state;
  }
}
