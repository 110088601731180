import * as rosterApi from '../../api/modules/roster';
import { all, throttle, call, put, race, take } from 'redux-saga/effects';
import {
  GET_ROSTERS,
  GET_ROSTER,
  GET_ROSTER_PLANS,
  UPDATE_ROSTER_CELL,
  GET_ROSTER_VERSIONS,
  GET_ROSTER_VERSION,
  SIGN_ROSTER_VERSION,
  RESET_ROSTER,
  GET_SIGN_REASONS,
  GET_VERSION_NOTES,
  UPDATE_ROSTER_CELLS,
  APPLY_ROSTER_TEMPLATE_CELLS,
} from './types';
import {
  setRosters,
  setRoster,
  setRosterPlans,
  setRosterVersions,
  setSignReasons,
  setVersionNotes,
} from './actions';
import { toast } from 'react-toastify';
import mapValidationErrors from '../../utilities/map-validation-errors';
import { CANCEL_TASKS } from '../common/types';
import generalErrorHandling from '../../utilities/general-error-handling';

function* getRostersGenerator() {
  try {
    const result = yield call(rosterApi.getRosters);
    yield put(setRosters(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getRosterGenerator({ payload }: any) {
  try {
    const result = yield call(rosterApi.getRoster, payload);
    yield put(setRoster(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getRosterPlansGenerator() {
  try {
    const result = yield call(rosterApi.getPlans);
    yield put(setRosterPlans(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* updateRosterCellGenerator({ payload, meta }: any) {
  try {
    const result = yield call(rosterApi.updateRosterCell, payload);
    yield put(setRoster(result));
    yield call(meta);
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* updateRosterCellsGenerator({ payload, meta }: any) {
  try {
    const result = yield call(rosterApi.updateRosterCells, payload);
    yield put(setRoster(result));
    yield call(meta);
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getRosterVersionsGenerator({ payload }: any) {
  try {
    const result = yield call(rosterApi.getSignedRosterVersions, payload);
    yield put(setRosterVersions(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getRosterVersionGenerator({ payload }: any) {
  try {
    const result = yield call(rosterApi.getSignedRosterVersion, payload);
    yield put(setRoster(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* signRosterVersionGenerator({ payload, meta }: any) {
  const { setErrors, setSubmitting, closeModal } = meta;
  try {
    const result = yield call(rosterApi.signRosterVersion, payload);
    yield put(setRoster(result));
    yield getRosterVersionsGenerator({ payload });
    yield getSignReasons();
    yield call(closeModal);
  } catch (err) {
    if (err && err.length) {
      const errors: any = mapValidationErrors(err);
      yield call(setErrors, errors);
    } else {
      yield call(toast.error, generalErrorHandling(err));
    }
  }
  yield call(setSubmitting, false);
}

function* resetRosterGenerator({ payload }: any) {
  try {
    const result = yield call(rosterApi.resetRoster, payload);
    yield put(setRoster(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* applyRosterTemplateCellsGenerator({ payload }: any) {
  try {
    const result = yield call(rosterApi.applyTemplateCells, payload);
    yield put(setRoster(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getSignReasons() {
  try {
    const result = yield call(rosterApi.getSignReasons);
    yield put(setSignReasons(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getVersionNotes({ payload }: any) {
  try {
    const result = yield call(rosterApi.getVersionNotes, payload);
    yield put(setVersionNotes(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

export function* schedulersRootSagas() {
  return yield race([
    take(CANCEL_TASKS),
    all([
      throttle(100, GET_ROSTERS, getRostersGenerator),
      throttle(100, GET_ROSTER, getRosterGenerator),
      throttle(100, GET_ROSTER_PLANS, getRosterPlansGenerator),
      throttle(100, UPDATE_ROSTER_CELL, updateRosterCellGenerator),
      throttle(100, UPDATE_ROSTER_CELLS, updateRosterCellsGenerator),
      throttle(100, GET_ROSTER_VERSIONS, getRosterVersionsGenerator),
      throttle(100, GET_ROSTER_VERSION, getRosterVersionGenerator),
      throttle(100, SIGN_ROSTER_VERSION, signRosterVersionGenerator),
      throttle(100, RESET_ROSTER, resetRosterGenerator),
      throttle(
        100,
        APPLY_ROSTER_TEMPLATE_CELLS,
        applyRosterTemplateCellsGenerator
      ),
      throttle(100, GET_SIGN_REASONS, getSignReasons),
      throttle(100, GET_VERSION_NOTES, getVersionNotes),
    ]),
  ]);
}
