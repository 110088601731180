const PLANNING_PREFIX = 'PLANNING';

export const GET_USER_WORKPLACE_ROTATION_TABLE = `${PLANNING_PREFIX}/GET_WORKPLACE_ROTATION_TABLE`;
export const SET_USER_WORKPLACE_ROTATION_TABLE = `${PLANNING_PREFIX}/SET_WORKPLACE_ROTATION_TABLE`;
export const CREATE_USER_WORKPLACE_ROTATION_CELL = `${PLANNING_PREFIX}/CREATE_USER_WORKPLACE_ROTATION_CELL`;
export const UPDATE_USER_WORKPLACE_ROTATION_CELL = `${PLANNING_PREFIX}/UPDATE_WORKPLACE_ROTATION_CELL`;
export const DELETE_USER_WORKPLACE_ROTATION_CELL = `${PLANNING_PREFIX}/DELETE_USER_WORKPLACE_ROTATION_CELL`;

export const GET_ADMIN_WORKPLACE_ROTATION_TABLE = `${PLANNING_PREFIX}/GET_ADMIN_WORKPLACE_ROTATION_TABLE`;
export const SET_ADMIN_WORKPLACE_ROTATION_TABLE = `${PLANNING_PREFIX}/SET_ADMIN_WORKPLACE_ROTATION_TABLE`;

export const GET_EMPLOYEE_WORKPLACES_TABLE = `${PLANNING_PREFIX}/GET_EMPLOYEE_WORKPLACES_TABLE`;
export const SET_EMPLOYEE_WORKPLACES_TABLE = `${PLANNING_PREFIX}/SET_EMPLOYEE_WORKPLACES_TABLE`;
export const MARK_EMPLOYEE_WORKPLACE = `${PLANNING_PREFIX}/MARK_EMPLOYEE_WORKPLACE`;

export interface PlanningState {
  userWorkplaceRotationTable: UserWorkplaceRotationResponse | null;
  adminWorkplaceRotationTable: AdminWorkplaceRotationResponse | null;
  employeeWorkplaceTable: EmployeeWorkplaceTableResponse | null;
}

interface GetUserWorkplaceRotationTable {
  type: typeof GET_USER_WORKPLACE_ROTATION_TABLE;
  payload: GetUserWorkplaceRotationTableRequest;
}
interface SetUserWorkplaceRotationTable {
  type: typeof SET_USER_WORKPLACE_ROTATION_TABLE;
  payload: UserWorkplaceRotationResponse;
}
interface CreateUserWorkplaceRotationCell {
  type: typeof CREATE_USER_WORKPLACE_ROTATION_CELL;
  payload: CreateUserWorkplaceRotationCellRequest;
}
interface UpdateUserWorkplaceRotationCell {
  type: typeof UPDATE_USER_WORKPLACE_ROTATION_CELL;
  payload: UpdateUserWorkplaceRotationCellRequest;
}
interface DeleteUserWorkplaceRotationCell {
  type: typeof DELETE_USER_WORKPLACE_ROTATION_CELL;
  payload: number;
  meta: any;
}
interface GetAdminWorkplaceRotationTable {
  type: typeof GET_ADMIN_WORKPLACE_ROTATION_TABLE;
  payload: GetAdminWorkplaceRotationTableRequest;
}
interface SetAdminWorkplaceRotationTable {
  type: typeof SET_ADMIN_WORKPLACE_ROTATION_TABLE;
  payload: AdminWorkplaceRotationResponse;
}
interface GetEmployeeWorkplaceTable {
  type: typeof GET_EMPLOYEE_WORKPLACES_TABLE;
  payload: string;
}
interface SetEmployeeWorkplaceTable {
  type: typeof SET_EMPLOYEE_WORKPLACES_TABLE;
  payload: EmployeeWorkplaceTableResponse;
}
interface MarkEmployeeWorkplace {
  type: typeof MARK_EMPLOYEE_WORKPLACE;
  payload: EmployeeWorkplaceMarkRequest;
}

export type PlanningActionTypes =
  | GetUserWorkplaceRotationTable
  | SetUserWorkplaceRotationTable
  | CreateUserWorkplaceRotationCell
  | UpdateUserWorkplaceRotationCell
  | DeleteUserWorkplaceRotationCell
  | GetAdminWorkplaceRotationTable
  | SetAdminWorkplaceRotationTable
  | GetEmployeeWorkplaceTable
  | SetEmployeeWorkplaceTable
  | MarkEmployeeWorkplace;
