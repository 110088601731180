import api from '../index';

const ROSTER_WORKPLACE_BASE_URL = '/api/v1/Workplace';

export async function getWorkplaceList(): Promise<WorkplaceResponse[]> {
  const result = await api.get<WorkplaceResponse[]>(
    `${ROSTER_WORKPLACE_BASE_URL}`
  );
  return result.data;
}

export async function getWorkplaceDetail(
  id: number
): Promise<WorkplaceDetailResponse> {
  const result = await api.get<WorkplaceDetailResponse>(
    `${ROSTER_WORKPLACE_BASE_URL}/${id}`
  );
  return result.data;
}

export async function createWorkplace(
  payload: CreateUpdateWorkplaceRequest
): Promise<WorkplaceResponse> {
  const result = await api.post<WorkplaceResponse>(
    `${ROSTER_WORKPLACE_BASE_URL}`,
    payload
  );
  return result.data;
}

export async function updateWorkplace(
  payload: CreateUpdateWorkplaceRequest
): Promise<WorkplaceResponse> {
  const result = await api.put<WorkplaceResponse>(
    `${ROSTER_WORKPLACE_BASE_URL}/${payload.workplaceId}`,
    payload
  );
  return result.data;
}

export async function removeWorkplace(workplaceId: number): Promise<boolean> {
  const result = await api.delete<boolean>(
    `${ROSTER_WORKPLACE_BASE_URL}/${workplaceId}`
  );
  return result.data;
}

export async function reorderWorkplaces(
  payload: UpdateWorkplacesParentPositionRequest
): Promise<WorkplaceResponse[]> {
  const result = await api.put<WorkplaceResponse[]>(
    `${ROSTER_WORKPLACE_BASE_URL}/Positions`,
    payload
  );
  return result.data;
}

export async function checkWorkplace(
  payload: CheckWorkplaceRequest
): Promise<WorkplaceActivityResponse[]> {
  const result = await api.put<WorkplaceActivityResponse[]>(
    `${ROSTER_WORKPLACE_BASE_URL}/Check/${payload.workplaceId}`,
    payload
  );
  return result.data;
}

export async function getWorkplaceOperatingTheatres(): Promise<
  WorkplaceResponse[]
> {
  const result = await api.get<WorkplaceResponse[]>(
    `${ROSTER_WORKPLACE_BASE_URL}/OperatingTheatres`
  );
  return result.data;
}

export async function getRotationWorkplaces(): Promise<WorkplaceResponse[]> {
  const result = await api.get<WorkplaceResponse[]>(
    `${ROSTER_WORKPLACE_BASE_URL}/RotationWorkplaces`
  );
  return result.data;
}

export async function getUserWorkRotationCell(
  payload: GetUserWorkplaceRotationTableRequest
): Promise<UserWorkplaceRotationResponse> {
  const result = await api.get<UserWorkplaceRotationResponse>(
    `${ROSTER_WORKPLACE_BASE_URL}/Rotation/WorkplaceRotations`,
    { params: payload }
  );
  return result.data;
}

export async function getAdminWorkRotationCell(
  payload: GetAdminWorkplaceRotationTableRequest
): Promise<AdminWorkplaceRotationResponse> {
  const result = await api.get<AdminWorkplaceRotationResponse>(
    `${ROSTER_WORKPLACE_BASE_URL}/Rotation/AdminWorkplaceRotations`,
    { params: payload }
  );
  return result.data;
}

export async function createUserWorkRotationCell(
  payload: CreateUserWorkplaceRotationCellRequest
): Promise<UserWorkplaceRotationResponse> {
  const result = await api.post<UserWorkplaceRotationResponse>(
    `${ROSTER_WORKPLACE_BASE_URL}/Rotation/CreateWorkplaceRotations`,
    payload
  );
  return result.data;
}

export async function updateUserWorkRotationCell(
  payload: UpdateUserWorkplaceRotationCellRequest
): Promise<UserWorkplaceRotationResponse> {
  const result = await api.put<UserWorkplaceRotationResponse>(
    `${ROSTER_WORKPLACE_BASE_URL}/Rotation/UpdateWorkplaceRotations/${payload.workplaceRotationId}`,
    payload
  );
  return result.data;
}
export async function deleteUserWorkRotationCell(
  workplaceRotationId: number
): Promise<void> {
  await api.delete(
    `${ROSTER_WORKPLACE_BASE_URL}/Rotation/DeleteWorkplaceRotations/${workplaceRotationId}`
  );
}
