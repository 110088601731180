import {
  CommonState,
  CommonActionTypes,
  SET_HOLIDAYS,
  SET_LOADING,
} from './types';

const initialState: CommonState = {
  holidays: [],
  isLoading: false,
};

export function commonReducer(
  state = initialState,
  action: CommonActionTypes
): CommonState {
  switch (action.type) {
    case SET_HOLIDAYS:
      return { ...state, holidays: action.payload as HolidayResponse[] };
    case SET_LOADING:
      return { ...state, isLoading: action.payload as boolean };
    default:
      return state;
  }
}
