import {
  DoctorSkillState,
  DoctorSkillActionTypes,
  SET_DOCTOR_SKILLS,
} from './types';

const initialState: DoctorSkillState = {
  doctorSkills: null,
};

export function doctorSkillsReducer(
  state = initialState,
  action: DoctorSkillActionTypes
): DoctorSkillState {
  switch (action.type) {
    case SET_DOCTOR_SKILLS:
      return {
        ...state,
        doctorSkills: action.payload as DoctorSkillsReponse,
      };
    default:
      return state;
  }
}
