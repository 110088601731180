import {
  CANCEL_OCCURRENCE_WORKPLACE_ACTIVITY,
  CANCEL_WORKPLACE_ACTIVITY,
  CREATE_WORKPLACE_ACTIVITY,
  GET_WORKPLACE_ACTIVITY,
  REMOVE_WORKPLACE_ACTIVITY,
  SET_WORKPLACE_ACTIVITY,
  UPDATE_WORKPLACE_ACTIVITY,
  WorkplaceActivityTypes,
  GET_CANCEL_REASONS,
  SET_CANCEL_REASONS,
  GET_SUMMARY_INFO_TAB,
  SET_SUMMARY_INFO_TAB,
} from './types';

export function getWorkplaceActivity(): WorkplaceActivityTypes {
  return {
    type: GET_WORKPLACE_ACTIVITY,
    payload: null,
  };
}

export function setWorkplaceActivity(
  payload: WorkplaceActivityResponse[]
): WorkplaceActivityTypes {
  return {
    type: SET_WORKPLACE_ACTIVITY,
    payload: payload,
  };
}

export function createWorkplaceActivity(
  payload: CreateUpdateWorkplaceActivityRequest
): WorkplaceActivityTypes {
  return {
    type: CREATE_WORKPLACE_ACTIVITY,
    payload: payload,
  };
}

export function updateWorkplaceActivity(
  payload: CreateUpdateWorkplaceActivityRequest
): WorkplaceActivityTypes {
  return {
    type: UPDATE_WORKPLACE_ACTIVITY,
    payload: payload,
  };
}

export function removeWorkplaceActivity(
  payload: string
): WorkplaceActivityTypes {
  return {
    type: REMOVE_WORKPLACE_ACTIVITY,
    payload: payload,
  };
}

export function cancelWorkplaceActivity(
  payload: CancelWorkplaceActivityRequest,
  meta: any
): WorkplaceActivityTypes {
  return {
    type: CANCEL_WORKPLACE_ACTIVITY,
    payload: payload,
    meta: meta,
  };
}

export function cancelOccurrenceWorkplaceActivity(
  payload: CancelOccurrenceWorkplaceActivityRequest,
  meta: any
): WorkplaceActivityTypes {
  return {
    type: CANCEL_OCCURRENCE_WORKPLACE_ACTIVITY,
    payload: payload,
    meta: meta,
  };
}

export function getCancelReasons(): WorkplaceActivityTypes {
  return {
    type: GET_CANCEL_REASONS,
    payload: null,
  };
}

export function setCancelReasons(payload: string[]): WorkplaceActivityTypes {
  return {
    type: SET_CANCEL_REASONS,
    payload: payload,
  };
}

export function getSummaryInfoTab(
  payload: WorkplaceActivitySummaryInfoRequest
): WorkplaceActivityTypes {
  return {
    type: GET_SUMMARY_INFO_TAB,
    payload: payload,
  };
}

export function setSummaryInfoTab(
  payload: SummaryInfoTabResponse[]
): WorkplaceActivityTypes {
  return {
    type: SET_SUMMARY_INFO_TAB,
    payload: payload,
  };
}
