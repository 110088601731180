import {
  GET_DEPARTMENTS,
  SET_DEPARTMENTS,
  SET_USER_DEPARTMENT,
  SET_USER_TOKEN,
  UserActionTypes,
  SET_USER_LANGUAGE,
} from './types';

export function setUserToken(token: string): UserActionTypes {
  return {
    type: SET_USER_TOKEN,
    payload: token,
  };
}
export function getDepartments(): UserActionTypes {
  return {
    type: GET_DEPARTMENTS,
    payload: null,
  };
}

export function setDepartments(
  departments: DepartmentResponse[]
): UserActionTypes {
  return {
    type: SET_DEPARTMENTS,
    payload: departments,
  };
}

export function setUserDepartment(department: string): UserActionTypes {
  return {
    type: SET_USER_DEPARTMENT,
    payload: department,
  };
}

export function setUserLanguage(payload: string): UserActionTypes {
  return {
    type: SET_USER_LANGUAGE,
    payload,
  };
}
