import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './languages/en.json';
import sl from './languages/sl.json';

// the translations
const resources = {
  en: {
    translation: en,
  },
  sl: {
    translation: sl,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    debug: process.env.NODE_ENV === 'development',
  });

export default i18n;
