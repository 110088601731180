import {
  SchedulersState,
  SchedulersActionTypes,
  SET_ROSTERS,
  SET_ROSTER,
  SET_ROSTER_PLANS,
  SET_ROSTER_VERSIONS,
  SET_SIGN_REASONS,
  SET_VERSION_NOTES,
} from './types';

const initialState: SchedulersState = {
  rosters: [],
  selectedRoster: null,
  plans: [],
  rosterVersions: [],
  signReasons: [],
  versionNotes: '',
};

export function schedulerReducer(
  state = initialState,
  action: SchedulersActionTypes
): SchedulersState {
  switch (action.type) {
    case SET_ROSTERS:
      return {
        ...state,
        rosters: action.payload as BaseRosterTemplateResponse[],
      };
    case SET_ROSTER:
      return {
        ...state,
        selectedRoster: action.payload as TemplateTableResponse,
      };
    case SET_ROSTER_PLANS:
      return { ...state, plans: action.payload as PlanResponse[] };
    case SET_ROSTER_VERSIONS:
      return {
        ...state,
        rosterVersions: action.payload as RosterVersionResponse[],
      };
    case SET_SIGN_REASONS:
      return {
        ...state,
        signReasons: action.payload as string[],
      };
    case SET_VERSION_NOTES:
      return {
        ...state,
        versionNotes: action.payload as string,
      };
  }
  return state;
}
