import {
  CommonActionTypes,
  GET_HOLIDAYS,
  SET_HOLIDAYS,
  SET_LOADING,
  CANCEL_TASKS,
} from './types';

export function getHolidays(payload: number): CommonActionTypes {
  return {
    type: GET_HOLIDAYS,
    payload: payload,
  };
}

export function setHolidays(payload: HolidayResponse[]): CommonActionTypes {
  return {
    type: SET_HOLIDAYS,
    payload: payload,
  };
}

export function setLoading(payload: boolean): CommonActionTypes {
  return {
    type: SET_LOADING,
    payload: payload,
  };
}

export function cancelTasks(): CommonActionTypes {
  return {
    type: CANCEL_TASKS,
    payload: null,
  };
}
