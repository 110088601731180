import { call, all, put, throttle, race, take } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { GET_HOLIDAYS, CANCEL_TASKS } from './types';
import * as apiCommon from '../../api/modules/common';
import { setHolidays } from './actions';
import generalErrorHandling from '../../utilities/general-error-handling';

function* getHolidaysGenerator({ payload }: any) {
  try {
    const result = yield call(apiCommon.getHolidays, payload);
    yield put(setHolidays(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

export function* commonRootSagas() {
  return yield race([
    take(CANCEL_TASKS),
    all([throttle(100, GET_HOLIDAYS, getHolidaysGenerator)]),
  ]);
}
