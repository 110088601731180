import {
  OperationsActionTypes,
  GET_PATIENT_BY_ID,
  SET_PATIENT_BY_ID,
  GET_OPERATION_BY_CASE_ID,
  SET_OPERATION_BY_CASE_ID,
  CREATE_PATIENT,
  UPDATE_PATIENT,
  REMOVE_PATIENT,
  GET_AUTOCOMPLETE_VALUES,
  SET_AUTOCOMPLETE_VALUES,
  UPDATE_CASE,
  UPDATE_SOAP,
  INFORM_PATIENT,
  ADD_DIAGNOSE_TO_SURGERY,
  UPDATE_DIAGNOSE_FROM_SURGERY,
  REMOVE_DIAGNOSE_FROM_SURGERY,
  ADD_PROCEDURE_TO_SURGERY,
  UPDATE_PROCEDURE_FROM_SURGERY,
  REMOVE_PROCEDURE_FROM_SURGERY,
  SET_WIZARD_STEP,
  GET_OPERATION_READY_PATIENTS,
  SET_OPERATION_READY_PATIENTS,
  SET_OPERATION_TEAM,
  REORDER_APPOINTMENTS_FROM_START,
  REORDER_APPOINTMENTS_SEQUENTIALLY,
  SET_APPOINTMENT_STATUS,
  GET_CASE_EMPLOYEE,
  SET_CASE_EMPLOYEE,
  GET_OPERATION_SUMMARY_INFO,
  SET_OPERATION_SUMMARY_INFO,
  UPDATE_OPERATION_REPORT,
} from './types';

export function getPatient(payload: string): OperationsActionTypes {
  return {
    type: GET_PATIENT_BY_ID,
    payload,
  };
}
export function setPatient(payload: PatientResponse): OperationsActionTypes {
  return {
    type: SET_PATIENT_BY_ID,
    payload,
  };
}
export function getOperationByCaseId(payload: number): OperationsActionTypes {
  return {
    type: GET_OPERATION_BY_CASE_ID,
    payload,
  };
}
export function setOperationByCaseId(
  payload: OperationResponse | null
): OperationsActionTypes {
  return {
    type: SET_OPERATION_BY_CASE_ID,
    payload,
  };
}
export function getAutocompleteValues(): OperationsActionTypes {
  return {
    type: GET_AUTOCOMPLETE_VALUES,
    payload: null,
  };
}
export function setAutocompleteValues(
  payload: OperationAutocompleteResponse
): OperationsActionTypes {
  return {
    type: SET_AUTOCOMPLETE_VALUES,
    payload: payload,
  };
}
export function createPatient(
  payload: CreateUpdatePatientRequest,
  meta: any
): OperationsActionTypes {
  return {
    type: CREATE_PATIENT,
    payload,
    meta,
  };
}
export function updatePatient(
  payload: CreateUpdatePatientRequest,
  meta: any
): OperationsActionTypes {
  return {
    type: UPDATE_PATIENT,
    payload,
    meta,
  };
}
export function removePatient(payload: number): OperationsActionTypes {
  return {
    type: REMOVE_PATIENT,
    payload,
  };
}
export function updateCase(
  payload: UpdateCaseRequest,
  meta: any
): OperationsActionTypes {
  return {
    type: UPDATE_CASE,
    payload,
    meta,
  };
}
export function informPatient(payload: number): OperationsActionTypes {
  return {
    type: INFORM_PATIENT,
    payload,
  };
}
export function updateSOAP(
  payload: UpdateSOAPRequest,
  meta: any
): OperationsActionTypes {
  return {
    type: UPDATE_SOAP,
    payload,
    meta,
  };
}
export function updateOperationReport(
  payload: UpdateOperationReportRequest,
  meta: any
): OperationsActionTypes {
  return {
    type: UPDATE_OPERATION_REPORT,
    payload,
    meta,
  };
}
export function addDiagnoseToSurgery(
  payload: AddDiagnoseToOperationRequest
): OperationsActionTypes {
  return {
    type: ADD_DIAGNOSE_TO_SURGERY,
    payload,
  };
}
export function updateDiagnoseFromSurgery(
  payload: UpdateDiagnoseFromOperationRequest
): OperationsActionTypes {
  return {
    type: UPDATE_DIAGNOSE_FROM_SURGERY,
    payload,
  };
}
export function removeDiagnoseFromSurgery(
  payload: RemoveDiagnoseFromOperationRequest
): OperationsActionTypes {
  return {
    type: REMOVE_DIAGNOSE_FROM_SURGERY,
    payload,
  };
}
export function addProcedureToSurgery(
  payload: AddProcedureToOperationRequest
): OperationsActionTypes {
  return {
    type: ADD_PROCEDURE_TO_SURGERY,
    payload,
  };
}
export function updateProcedureFromSurgery(
  payload: UpdateProcedureFromOperationRequest
): OperationsActionTypes {
  return {
    type: UPDATE_PROCEDURE_FROM_SURGERY,
    payload,
  };
}
export function removeProcedureFromSurgery(
  payload: RemoveProcedureFromOperationRequest
): OperationsActionTypes {
  return {
    type: REMOVE_PROCEDURE_FROM_SURGERY,
    payload,
  };
}
export function setWizardStep(payload: number): OperationsActionTypes {
  return {
    type: SET_WIZARD_STEP,
    payload: payload,
  };
}
export function getOperationReadyPatients(
  payload: OperationReadyPatientsRequest
): OperationsActionTypes {
  return {
    type: GET_OPERATION_READY_PATIENTS,
    payload: payload,
  };
}
export function setOperationReadyPatients(
  payload: OperationReadyPatientResponse[]
): OperationsActionTypes {
  return {
    type: SET_OPERATION_READY_PATIENTS,
    payload: payload,
  };
}
export function setOperationTeam(
  payload: SetOperationTeamRequest,
  meta: any
): OperationsActionTypes {
  return {
    type: SET_OPERATION_TEAM,
    payload: payload,
    meta: meta,
  };
}
export function reorderAppointmentsFromStart(
  payload: ReorderRequest,
  meta: any
): OperationsActionTypes {
  return {
    type: REORDER_APPOINTMENTS_FROM_START,
    payload: payload,
    meta: meta,
  };
}
export function reorderAppointmentsSequentially(
  payload: ReorderRequest,
  meta: any
): OperationsActionTypes {
  return {
    type: REORDER_APPOINTMENTS_SEQUENTIALLY,
    payload: payload,
    meta: meta,
  };
}
export function setAppointmentStatus(
  payload: SetStatusRequest,
  meta: any
): OperationsActionTypes {
  return {
    type: SET_APPOINTMENT_STATUS,
    payload: payload,
    meta: meta,
  };
}
export function getCaseEmployee(payload: string): OperationsActionTypes {
  return {
    type: GET_CASE_EMPLOYEE,
    payload: payload,
  };
}
export function setCaseEmployee(
  payload: EmployeeShortResponse
): OperationsActionTypes {
  return {
    type: SET_CASE_EMPLOYEE,
    payload: payload,
  };
}

export function getOperationSummaryInfo(
  payload: OperationSummaryInfoRequest
): OperationsActionTypes {
  return {
    type: GET_OPERATION_SUMMARY_INFO,
    payload,
  };
}

export function setOperationSummaryInfo(
  payload: SummaryInfoTabResponse[]
): OperationsActionTypes {
  return {
    type: SET_OPERATION_SUMMARY_INFO,
    payload,
  };
}
