import * as reportsApi from '../../api/modules/reports';
import { all, throttle, call, put, race, take } from 'redux-saga/effects';
import {
  GET_REPORT_VERSIONS,
  GET_REPORT,
  GET_CUSTOM_REPORT_LIST,
  GET_CUSTOM_REPORTS_BY_TEMPLATE,
  GET_CUSTOM_REPORT_VERSIONS,
  GET_CUSTOM_REPORT_VERSION,
} from './types';
import {
  setReportVersions,
  setReport,
  setCustomReportList,
  setCustomReportVersions,
  setCustomReportVersion,
  setCustomReportsByTemplate,
} from './actions';
import { toast } from 'react-toastify';
import { CANCEL_TASKS } from '../common/types';
import generalErrorHandling from '../../utilities/general-error-handling';

function* getGroupedReportVersionsGenerator({ payload }: any) {
  try {
    const result = yield call(reportsApi.getGroupedRosterVersions, payload);
    yield put(setReportVersions(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getReportGenerator({ payload }: any) {
  try {
    const result = yield call(reportsApi.getReport, payload);
    yield put(setReport(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getCustomReportListGenerator() {
  try {
    const result = yield call(reportsApi.getCustomReportList);
    yield put(setCustomReportList(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getCustomReportsByTemplateGenerator({ payload }: any) {
  try {
    const result = yield call(reportsApi.getCustomReportsByTemplate, payload);
    yield put(setCustomReportsByTemplate(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getCustomReportVersionsGenerator({ payload, meta }: any) {
  try {
    const result = yield call(reportsApi.getCustomReportVersions, payload);
    yield put(setCustomReportVersions(result));
    yield call(meta);
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

function* getCustomReportVersionGenerator({ payload }: any) {
  try {
    const result = yield call(reportsApi.getCustomReportVersion, payload);
    yield put(setCustomReportVersion(result));
  } catch (err) {
    yield call(toast.error, generalErrorHandling(err));
  }
}

export function* reportsRootSagas(): Generator {
  return yield race([
    take(CANCEL_TASKS),
    all([
      throttle(100, GET_REPORT_VERSIONS, getGroupedReportVersionsGenerator),
      throttle(100, GET_REPORT, getReportGenerator),
      throttle(100, GET_CUSTOM_REPORT_LIST, getCustomReportListGenerator),
      throttle(
        100,
        GET_CUSTOM_REPORTS_BY_TEMPLATE,
        getCustomReportsByTemplateGenerator
      ),
      throttle(
        100,
        GET_CUSTOM_REPORT_VERSIONS,
        getCustomReportVersionsGenerator
      ),
      throttle(100, GET_CUSTOM_REPORT_VERSION, getCustomReportVersionGenerator),
    ]),
  ]);
}
