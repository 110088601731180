const ATTACHMENT_PREFIX = 'ATTACHMENT';

export const GET_ATTACHMENT_FILES = `${ATTACHMENT_PREFIX}/GET_ATTACHMENT_FILES`;
export const SET_ATTACHMENT_FILES = `${ATTACHMENT_PREFIX}/SET_ATTACHMENT_FILES`;
export const GET_ATTACHMENT_FILE = `${ATTACHMENT_PREFIX}/GET_ATTACHMENT_FILE`;
export const SET_ATTACHMENT_FILE = `${ATTACHMENT_PREFIX}/SET_ATTACHMENT_FILE`;
export const UPLOAD_ATTACHMENT = `${ATTACHMENT_PREFIX}/UPLOAD_ATTACHMENT`;
export const REMOVE_ATTACHMENT = `${ATTACHMENT_PREFIX}/REMOVE_ATTACHMENT`;

export interface AttachmentState {
  attachments: AttachmentFileResponse[];
}

interface GetAttachmentFiles {
  type: typeof GET_ATTACHMENT_FILES;
  payload: AttachmentFileRequest;
}

interface SetAttachmentFiles {
  type: typeof SET_ATTACHMENT_FILES;
  payload: AttachmentFileResponse[];
}

interface SetAttachmentFile {
  type: typeof SET_ATTACHMENT_FILES;
  payload: AttachmentFileResponse;
}

interface UploadAttachment {
  type: typeof UPLOAD_ATTACHMENT;
  payload: UploadAttachmentRequest;
}

interface RemoveAttachment {
  type: typeof REMOVE_ATTACHMENT;
  payload: string;
}

export type AttachmentActionTypes =
  | GetAttachmentFiles
  | SetAttachmentFiles
  | SetAttachmentFile
  | UploadAttachment
  | RemoveAttachment;
