const DOCTOR_QUALIFICATIONS_PREFIX = 'DOCTOR_QUALIFICATIONS';

export const GET_DOCTOR_QUALIFICATIONS = `${DOCTOR_QUALIFICATIONS_PREFIX}/GET_DOCTOR_QUALIFICATIONS`;
export const SET_DOCTOR_QUALIFICATIONS = `${DOCTOR_QUALIFICATIONS_PREFIX}/SET_DOCTOR_QUALIFICATIONS`;
export const UPDATE_DOCTOR_QUALIFICATIONS = `${DOCTOR_QUALIFICATIONS_PREFIX}/UPDATE_DOCTOR_QUALIFICATIONS`;

export interface DoctorQualificationState {
  doctorQualifications: DoctorQualificationsResponse | null;
}

interface GetDoctorQualifications {
  type: typeof GET_DOCTOR_QUALIFICATIONS;
  payload: null;
}

interface UpdateDoctorQualifications {
  type: typeof UPDATE_DOCTOR_QUALIFICATIONS;
  payload: UpdateDoctorQualificationsRequest;
}

interface SetDoctorQualifications {
  type: typeof GET_DOCTOR_QUALIFICATIONS;
  payload: DoctorQualificationsResponse;
}

export type DoctorQualificationActionTypes =
  | GetDoctorQualifications
  | UpdateDoctorQualifications
  | SetDoctorQualifications;
