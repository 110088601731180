import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import { rosterTemplateReducer } from './roster-template/reducers';
import { rosterTemplateRootSagas } from './roster-template/sagas';
import { userManagerReducer } from './user-manager/reducers';
import { userManagerRootSagas } from './user-manager/sagas';
import { workplaceActivityReducer } from './workplace-activity/reduces';
import { workplaceActivityRootSagas } from './workplace-activity/sagas';
import { workplaceReducer } from './workplace/reducers';
import { doctorQualificationsReducer } from './doctor-qualifications/reducers';
import { doctorSkillsReducer } from './doctor-skills/reducers';
import { pathologyGroupReducer } from './pathology-group/reducers';
import { workplaceRootSagas } from './workplace/sagas';
import { availabilityReducer } from './availability/reducers';
import { availabilityRootSagas } from './availability/sagas';
import { qualificationsRootSagas } from './qualifications/sagas';
import { skillsRootSagas } from './skills/sagas';
import { skillReducer } from './skills/reducers';
import { qualificationReducer } from './qualifications/reducers';
import { doctorQualificationRootSagas } from './doctor-qualifications/sagas';
import { doctorSkillRootSagas } from './doctor-skills/sagas';
import { pathologyGroupRootSagas } from './pathology-group/sagas';
import { availabilitiesRootSagas } from './availabilities/sagas';

export function* adminRootSagas() {
  yield all([
    workplaceRootSagas(),
    rosterTemplateRootSagas(),
    userManagerRootSagas(),
    workplaceActivityRootSagas(),
    availabilityRootSagas(),
    qualificationsRootSagas(),
    skillsRootSagas(),
    doctorQualificationRootSagas(),
    doctorSkillRootSagas(),
    pathologyGroupRootSagas(),
    availabilitiesRootSagas(),
  ]);
}

export const adminReducer = combineReducers({
  workplace: workplaceReducer,
  rosterTemplate: rosterTemplateReducer,
  userManager: userManagerReducer,
  workplaceActivity: workplaceActivityReducer,
  availability: availabilityReducer,
  doctorQualifications: doctorQualificationsReducer,
  doctorSkills: doctorSkillsReducer,
  skill: skillReducer,
  qualification: qualificationReducer,
  pathologyGroup: pathologyGroupReducer,
});
